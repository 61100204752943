<template>
	<transition name="modal">
		<div class="modal-backdrop">
			<div class="modal-dialog">
				<div class="modal-content">
					<slot></slot>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
    export default{
		created(){
            document.body.classList.add('modal-open')
		},
		mounted(){
            //console.log('mounted modal')
		},
		destroyed(){
            document.body.classList.remove('modal-open')
		}
    }
</script>