<template>
    <div id="app">
        <app-header></app-header>
        <main role="main">
            <slot></slot>
        </main>
        <app-footer></app-footer>
    </div>
</template>

<script>
    import AppHeader from '@/components/AppHeader'
    import AppFooter from '@/components/AppFooter'
    export default{
        components: {AppHeader, AppFooter}
    }
</script>