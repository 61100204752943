<template>
    <modal>
        <form v-on:submit="submit">
            <div class="modal-header">
                <h4 class="modal-title">{{ modalTitle }}</h4>
            </div>
            <div class="modal-body">
                <div class="flex-native">
                    <div class="span12-xs span8-md">
                        <text-input label="Nombre" v-model="form.name" :error="errors.name"></text-input>
                    </div>
                    <div class="span12-xs span4-md">
                        <text-input label="NIF/CIF" v-model="form.vat" :error="errors.vat"></text-input>
                    </div>
                    <div class="span12-xs">
                        <text-input label="Dirección" v-model="form.address" :error="errors.address"></text-input>
                    </div>
                    <div class="span12-xs span4-md">
                        <text-input label="Código postal" v-model="form.zip" :error="errors.zip"></text-input>
                    </div>
                    <div class="span12-xs span4-md">
                        <text-input label="Ciudad" v-model="form.city" :error="errors.city"></text-input>
                    </div>
                    <div class="span12-xs span4-md">
                        <text-input label="Provincia" v-model="form.state" :error="errors.state"></text-input>
                    </div>
                    <div class="span12-xs span8-md">
                        <text-input label="Email para facturas" v-model="form.email" :error="errors.email"></text-input>
                    </div>
                    <div class="span12-xs span4-md">
                        <text-input label="Teléfono" v-model="form.phone" :error="errors.phone"></text-input>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn" v-on:click="closeModal('data', false)">Cancelar</button>
                <button type="submit" class="btn btn-primary">Guardar</button>
            </div>
        </form>
    </modal>
</template>

<script>
    export default{
        props: {
            client: {type: Object, default: null, required: false}
        },
        data(){
            return {
                form: null,
                errors: null
            }
        },
        created(){
            this.fillData()
        },
        computed: {
            modalTitle(){
                if(this.client){
                    return 'Editar cliente'
                }

                return 'Crear cliente'
            }
        },
        methods: {
            submit(e){
                e.preventDefault()

                const handleResponse = (response) => {
                    if(response.data.success){
                        this.$emit('close', true)
                    }else{
                        this.errors = response.data.errors
                    }
                }

                const handleError = (error) => {
                    console.error(error)
                }

                if(this.client == null){
                    Api.createClient(this.form).then(handleResponse).catch(handleError)
                }else{
                    Api.updateClient(this.client.id, this.form).then(handleResponse).catch(handleError)
                }
            },
            closeModal(modal, reload){
                this.$emit('close', reload)
            },
            fillData(){

                const fields = [
                    'name', 'vat', 'address', 'city',
                    'state', 'phone', 'email', 'zip'
                ]

                // Prepare form
                const form = {}
                const errors = {}

                fields.forEach((field) => {
                    form[field] = this.client ? this.client[field] : ''
                    errors[field] = null
                })

                this.form = form
                this.errors = errors
            }
        }
    }
</script>