export default {
	filter: function(size){
		var magnitude = 'bytes';

		if(size > 1024){
			size = size / 1024;
			magnitude = 'KB';
		}

		if(size > 1024){
			size = size / 1024;
			magnitude = 'MB';
		}

		return size.toFixed(2) + ' ' + magnitude;
	}
};