var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "folder", class: { "folder-selected": _vm.selected } },
    [
      _c("img", { attrs: { src: _vm.image } }),
      _vm._v(" "),
      _c("div", { staticClass: "name" }, [_vm._v(_vm._s(_vm.folder.name))]),
      _vm._v(" "),
      _vm.count > 0 && !_vm.remove
        ? _c("div", { staticClass: "count" }, [_vm._v(_vm._s(_vm.count))])
        : _vm._e(),
      _vm._v(" "),
      _vm.remove
        ? _c("div", { staticClass: "remove", on: { click: _vm.removeClick } })
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }