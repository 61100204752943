<template>
    <div>
        <modal v-if="modals.manage">
            <div class="modal-header">
                <h4 class="modal-title">Gestionar carpetas</h4>
            </div>
            <div class="modal-body">
                <div class="buttons-container">
                    <button type="button" class="btn btn-primary" v-on:click="openModal('new')">Crear carpeta</button>
                </div>
                <div class="row">
                    <div class="col-xs-12 col-md-6" v-for="folder in folders" :key="folder.id">
                        <folder class="folder-item" :key="folder.id" :folder="folder" :remove="true" v-on:remove="openRemoveModal(folder)"></folder>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn" v-on:click="closeModal('manage')">Cerrar</button>
            </div>
        </modal>
        <modal v-if="modals.new">
            <div class="modal-header">
                <h4 class="modal-title">Crear carpeta</h4>
            </div>
            <div class="modal-body">
                <input type="text" v-model="form.name">
            </div>
            <div class="modal-footer">
                <button type="button" class="btn" v-on:click="closeModal('new')">Cancelar</button>
                <button type="button" class="btn btn-primary" v-on:click="createFolder">Crear</button>
            </div>
        </modal>
        <modal v-if="modals.edit">
            <div class="modal-header">
                <h4 class="modal-title">Editar carpeta</h4>
            </div>
            <div class="modal-body">
                <input type="text" v-model="form.name">
            </div>
            <div class="modal-footer">
                <button type="button" class="btn" v-on:click="closeModal('edit')">Cancelar</button>
                <button type="button" class="btn btn-primary" v-on:click="updateFolder">Crear</button>
            </div>
        </modal>
        <modal v-if="modals.delete">
            <div class="modal-header">
                <h4 class="modal-title">Eliminar carpeta</h4>
            </div>
            <div class="modal-body">
                ¿Estás seguro de que quieres eliminar la carpeta {{ folderSelected.name }}?
            </div>
            <div class="modal-footer">
                <button type="button" class="btn" v-on:click="closeModal('delete')">Cancelar</button>
                <button type="button" class="btn btn-primary" v-on:click="deleteFolder">Confirmar</button>
            </div>
        </modal>
    </div>
</template>
<script>
    import Folder from '@/components/Folder'

    export default{
        components: {Folder},
        props: {
            folders: {type: Array, default: [], required: true}
        },
        data(){
            return {
                modals: {
                    manage: true,
                    new: false,
                    edit: false,
                    delete: false
                },
                form: {
                    name: '',
                    _token: this.$store.state.csrf
                },
                folderSelected: null
            }
        },
        computed: {
            isAdviser(){
                return this.$store.state.user.role == 'adviser'
            }
        },
        methods: {
            closeModal(modal){
                this.modals[modal] = false
                this.modals.manage = true
                if(modal == 'manage'){
                    this.$emit('close')
                }
            },
            openModal(modal){
                if(modal != 'manage'){
                    this.modals.manage = false
                }
                this.modals[modal] = true
            },
            openRemoveModal(folder){
                this.folderSelected = folder
                this.modals.manage = false
                this.modals.delete = true
            },
            deleteFolder(){
                const folderId = this.folderSelected.id
                Api.deleteFolder(folderId).then((response) => {
                    if(response.data.success) {
                        this.$snotify.success('Carpeta eliminada correctamente')
                        this.$emit('reload')
                    }else{
                        this.$snotify.error(response.data.message)
                    }
                    this.closeModal('delete')

                }).catch((error) => {

                })
            },
            updateFolder(){

            },
            createFolder(){
                var data = {form: this.form}

                if(this.isAdviser){
                    data.clientId = this.$store.state.clientSelected.id
                }

                Api.createFolder(data).then((response) => {
                    this.form.name = ''
                    this.closeModal('new')
                    this.$emit('reload')
                })
            }
        }
    }
</script>