<template>
    <component :is="layout">
        <router-view></router-view>
        <vue-snotify></vue-snotify>
    </component>
</template>

<script>
    export default{
        computed: {
            layout(){
                if(this.$route.meta.layout){
                    return this.$route.meta.layout + '-layout'
                }

                if(this.$route.meta.allowAnonymous){
                    return 'app-anonymous-layout'
                }

                return 'app-logged-layout'
            }
        }
    }
</script>


<style>
    @import "~vue-snotify/styles/material.css"
</style>