<template>
<div class="container">
    <h1 class="title">Asesores registrados</h1>
    <div class="box">
        <div class="adviser" v-for="user in advisers" :key="user.id">
            <div class="name">{{ user.name }} de <strong>{{ user.client.name }}</strong></div>
            <div class="buttons"><button type="button" class="btn" v-on:click="impersonate(user)">Convertirme</button></div>    
        </div>
    </div>
</div>
</template>

<script>
export default{
    data(){
        return {
            advisers: []
        };
    },
    created(){
        Api.listAdviser().then((response) => {
            if(response.data.success){
                this.advisers = response.data.advisers;
            }
        }).catch((error) => {

        });
    },
    methods: {
        impersonate(adviser){
            var params =  {_wanna_be: adviser.email}
            Api.impersonate(params).then((response) => {
                if(response.data.success){
                    this.$store.dispatch('refreshUser').then(() => {
                        this.$router.push({name: 'home'});
                    });
                }
            });
        }
    }
};
</script>