var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    [
      _vm.isAdmin
        ? _c("router-link", { attrs: { to: { name: "adviser_manage" } } }, [
            _vm._v("Asesores")
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }