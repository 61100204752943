<template>
    <div class="container container-sm">
		<h1 class="title">Recordar contraseña</h1>
        <div :class="responseClass" v-if="response != null">{{ response.message }}</div>
        <form v-on:submit="submit" v-if="response == null">
            <div class="box box-buttons">
                <div class="content">
                    <text-input label="E-mail" v-model="form.email" :error="errors.email"></text-input>
                </div>
                <div class="footer">
                    <button type="submit" class="btn btn-primary btn-block">Recordar</button>
                </div>
            </div>
        </form>
	</div>
</template>

<script>


export default{
    data(){
        return {
            form: {email: ''},
            errors: {email: null},
            response: null
        }
    },
    methods: {
        submit(e) {
            e.preventDefault()
            Api.userRememberPassword(this.form).then((response) => {
                if(response.data.success) {
                    this.response = response.data
                }
                this.errors = response.data.errors
            }).catch((error) => {

            })
        }
    },
    computed: {
        responseClass() {
            return 'alert alert-' + (this.response.success?'success':'danger')
        }
    }
}
</script>