<template>
    <div class="files">
        <div class="buttons">
            <div style="float: right">
                <button type="button" class="btn btn-upload" v-on:click="addFile" v-if="!isAdviser"></button>
                <button-dropdown text="" v-if="type == 'invoice' || type == 'invoiceout'" classes="btn btn-download">
                    <ul slot="menu">
                        <li><span v-on:click="downloadFiles('all')">Todas</span></li>
                        <li><span v-on:click="downloadFiles('pending')">Nuevas</span></li>
                    </ul>
                </button-dropdown>
                <button type="button" class="btn btn-download" v-if="type == 'payroll' || type == 'pending'" v-on:click="downloadFiles('all')"></button>
                <button type="button" class="btn btn-claim" v-on:click="showModalClaim" v-if="isAdviser"></button>
            </div>
            <div style="float: left">
                <file-search v-on:search="search"></file-search>
            </div>
        </div>
        <input type="file" multiple="multiple" style="display: none" v-on:change="change" ref="input">
        <div class="ajax-content" v-on:dragover.prevent v-on:drop="drop">
            <div ref="grid" class="flex-native" v-show="!loading">
                <div v-for="file in filteredFiles" :key="file.id" class="span12-sm span6-md span4-lg">
                    <file :key="file.id" :file="file" v-on:click.native="showViewFile(file)" v-on:delete="showModalDelete" v-on:edit="showModalEdit" v-on:download="downloadFile"></file>
                </div>
            </div>
            <loading v-show="loading"></loading>
            <div class="alert alert-warning" v-show="showAlert">
                No hay ninguna factura con las condiciones indicadas.
            </div>
        </div>
        <delete-file-modal v-if="modals.delete" :file="fileSelected" v-on:close="closeModal('delete', $event)"></delete-file-modal>
        <claim-file-modal v-if="modals.claim" :client="clientSelected" v-on:close="closeModal('claim', $event)"></claim-file-modal>
        <edit-file-modal v-if="modals.edit" :file="fileSelected" v-on:close="closeModal('edit', $event)"></edit-file-modal>
        <div class="preview-container" v-if="previewVisible">
            <div class="preview-window">
                <div class="preview-header">
                    {{ fileSelected.name }}
                    <div class="close" v-on:click="hideViewFile"></div>
                </div>
                <iframe :src="viewUrl"></iframe>
            </div>
        </div>
    </div>
</template>

<script>
    import File from './File.vue'
    import ButtonDropdown from './ButtonDropdown.vue'
    import Loading from './Loading.vue'
    import FileSearch from './FileSearch.vue'
    import EditFileModal from './EditFileModal.vue'
    import ClaimFileModal from './ClaimFileModal.vue'
    import DeleteFileModal from './DeleteFileModal.vue'

    export default {
        components: {EditFileModal, ClaimFileModal, DeleteFileModal, File, ButtonDropdown, Loading, FileSearch},
        props: {
            files: {type: Array, default: [], required: true},
            loading: {type: Boolean, default: false, required: false},
            type: {type: String, default: 'invoice', required: false}
        },
        data(){
            return {
                filter: 'all',
                modals: {
                    edit: false,
                    delete: false,
                    claim: false
                },
                fileSelected: null,
                dropzone: null,
                viewUrl: null,
                uploadProcess: 0,
                uploading: false,
                columns: 3
            }
        },
        mounted() {
            window.addEventListener('resize', this.resize)
        },
        methods: {
            setFilter(value){
                this.filter = value
            },
            resize(e){
                var width = this.$refs.grid.offsetWidth
                this.columns = Math.floor(width/250)
            },
            drop(e){
                e.preventDefault()
                this.uploadFiles(e.dataTransfer.files)
            },
            change(e){
                if(e.target.files.length == 0){
                    return
                }

                this.uploadFiles(e.target.files)
            },
            search(query){
                this.$emit('search', query)
            },
            addFile(){
                this.$refs.input.click()
            },
            showViewFile(file){
                this.fileSelected = file
                Api.getDocument(file.id).then((response) => {
                    if(response.data.success){
                        this.viewUrl = response.data.url
                    }
                })
            },
            hideViewFile(){
                this.fileSelected = null
                this.viewUrl = null
            },
            uploadFiles(files) {
                this.$emit('upload', files)
            },
            downloadFiles(status) {
                this.$emit('download-batch', status)
                this.hideDropdownDownload()
            },
            downloadFile(file){
                this.$emit('download', file)
            },
            showModalDelete(file){
                this.fileSelected = file
                this.modals.delete = true
            },
            showModalClaim(){
                this.modals.claim = true
            },
            showModalEdit(file){
                this.fileSelected = file
                this.modals.edit = true
            },
            closeModal(modal, submitted){
                this.modals[modal] = false
                if(submitted){
                    this.$emit('reload')
                }
            }

        },
        computed: {
            clientSelected(){
                return this.$store.state.clientSelected
            },
            filteredFiles() {
                var self = this
                return self.files.filter(function(file){
                    return (self.filter == 'all' || (self.filter == 'pending' && file.downloaded == false))
                })
            },
            showAlert(){
                return this.loading == false && this.filteredFiles.length == 0
            },
            isAdviser(){
                return this.$store.state.user.role == 'adviser'
            },
            previewVisible(){
                return this.viewUrl != null
            },
            gridClass(){
                return 'grid-columns grid-columns-' + this.columns
            }
        }
    }
</script>