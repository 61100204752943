var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("modal", [
    _c("form", { on: { submit: _vm.submit } }, [
      _c("div", { staticClass: "modal-header" }, [
        _c("h4", { staticClass: "modal-title" }, [_vm._v("Enviar factura")])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "modal-body" },
        [
          _c("text-input", {
            attrs: { label: "Asunto" },
            model: {
              value: _vm.form.subject,
              callback: function($$v) {
                _vm.$set(_vm.form, "subject", $$v)
              },
              expression: "form.subject"
            }
          }),
          _vm._v(" "),
          _c("textarea-input", {
            model: {
              value: _vm.form.message,
              callback: function($$v) {
                _vm.$set(_vm.form, "message", $$v)
              },
              expression: "form.message"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "modal-footer" }, [
        _c(
          "button",
          {
            staticClass: "btn",
            attrs: { type: "button" },
            on: { click: _vm.close }
          },
          [_vm._v("Cancelar")]
        ),
        _vm._v(" "),
        _c(
          "button",
          { staticClass: "btn btn-primary", attrs: { type: "submit" } },
          [_vm._v("Enviar")]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }