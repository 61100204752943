<template>
	<header>
		<div class="container">
			<div class="left">
				<router-link :to="{name: 'home'}" class="logo">
					<img src="/images/archive.svg">facturis
				</router-link>
				<header-nav v-if="isLogged"></header-nav>
			</div>
			<div class="right">
				<anonymous-buttons v-if="!isLogged"></anonymous-buttons>
				<router-link :to="{name: 'request_manage'}" class="right-block requests-block" v-if="isClient">
					<div class="bubble" v-if="notifications.requests > 0"></div>
				</router-link>
				<user-dropdown v-if="isLogged"></user-dropdown>
			</div>
		</div>
		<progress-bar v-if="uploading" :progress="progress"></progress-bar>
	</header>
</template>

<script>
import HeaderNav from '@/components/HeaderNav'
import ProgressBar from '@/components/ProgressBar'
import AnonymousButtons from '@/components/AnonymousButtons'
import UserDropdown from '@/components/UserDropdown'

export default {
	components: {HeaderNav, ProgressBar, AnonymousButtons, UserDropdown},
	computed: {
		isLogged() {
			return this.$store.state.user != null
		},
		uploading() {
			return this.$store.state.uploading != false
		},
		isClient() {
			return this.isLogged && this.$store.state.user.role == 'client'
		},
		progress() {
			return this.$store.state.uploading
		},
		notifications(){
			return this.$store.state.notifications	
		}
	}
}
</script>