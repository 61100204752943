
<template>
    <div class="document" :class="fileClass">
        <div class="icon">
            <img :src="image">
        </div>
        <div class="content">
            <div class="name">{{ file.name|truncate(20) }}</div>
            <div class="date">{{ file.date|timeago }}</div>
        </div>
        <div class="options">
            <span class="minibtn minibtn-download" v-on:click.stop="downloadClick"></span>
            <span class="minibtn minibtn-edit" v-on:click.stop="editClick"></span>
            <span class="minibtn minibtn-delete" v-on:click.stop="deleteClick"></span>
        </div>
    </div>
</template>

<script>
export default {
	props: {file: {type: Object}},
	methods: {
		downloadClick() {
			this.$emit('download', this.file)
		},
		editClick() {
			this.$emit('edit', this.file)
		},
		deleteClick() {
			this.$emit('delete', this.file)
		}
	},
	computed: {
		fileClass() {
		    if(this.file.type == 'general'){
		        return ''
            }

            if(this.file.downloaded){
                return 'document-downloaded'
            }

            return 'document-new'
        },
		image(){
			return '/images/icons/' + this.file.extension + '.png'
		}
	}
}
</script>

<style>

</style>