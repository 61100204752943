<template>
	<div class="progress-bar">
		<div class="fill" :style="style"></div>
	</div>
</template>

<script>
export default {
	props: {
		progress: 0
	},
	computed: {
		style(){
			return 'width: ' + this.progress + '%'
		}
	}
}
</script>