var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("h1", { staticClass: "title" }, [_vm._v("Facturas pendientes")]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "content" },
      [
        _vm._l(_vm.requests, function(request) {
          return _c("div", { key: request.id, staticClass: "request" }, [
            _c("div", { staticClass: "options" }, [
              _c("div", {
                staticClass: "minibtn minibtn-upload",
                on: {
                  click: function($event) {
                    return _vm.clickUpload(request)
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "description" }, [
              _vm._v(_vm._s(request.description))
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "total" }, [
              _vm._v(_vm._s(request.total) + " €")
            ])
          ])
        }),
        _vm._v(" "),
        _vm.requests.length == 0
          ? _c("div", { staticClass: "alert alert-success" }, [
              _vm._v(
                "\n\t\t        Por el momento, tu asesor no está esperando ninguna factura.\n\t        "
              )
            ])
          : _vm._e()
      ],
      2
    ),
    _vm._v(" "),
    _c("div", { staticStyle: { display: "none" } }, [
      _c("input", {
        ref: "file",
        attrs: { type: "file" },
        on: { change: _vm.changeFile }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }