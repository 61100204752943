var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("div", { staticClass: "flex-native" }, [
        _c("div", { staticClass: "span12-xs span3-md" }, [
          _c("div", { staticClass: "box box-sidemenu" }, [
            _c("div", { staticClass: "header" }, [_vm._v("Opciones")]),
            _vm._v(" "),
            _c("ul", [
              _c("li", [
                _c(
                  "a",
                  {
                    class: { selected: _vm.section == "profile" },
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        return _vm.change("profile")
                      }
                    }
                  },
                  [_vm._v("Editar mi perfil")]
                )
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    class: { selected: _vm.section == "password" },
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        return _vm.change("password")
                      }
                    }
                  },
                  [_vm._v("Cambiar contraseña")]
                )
              ]),
              _vm._v(" "),
              _vm.isAllowed("users")
                ? _c("li", [
                    _c(
                      "a",
                      {
                        class: { selected: _vm.section == "users" },
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            return _vm.change("users")
                          }
                        }
                      },
                      [_vm._v("Usuarios")]
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    class: { selected: _vm.section == "taxes" },
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        return _vm.change("taxes")
                      }
                    }
                  },
                  [_vm._v("Facturas")]
                )
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "span12-xs span9-md" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.section == "profile",
                  expression: "section == 'profile'"
                }
              ],
              staticClass: "box box-buttons"
            },
            [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "form",
                {
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.submitProfile($event)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "content" }, [
                    _vm.errorsProfile.length > 0
                      ? _c(
                          "div",
                          { staticClass: "alert alert-danger" },
                          _vm._l(_vm.errorsProfile, function(error) {
                            return _c("div", [_vm._v(_vm._s(error))])
                          }),
                          0
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "flex-native" }, [
                      _c(
                        "div",
                        { staticClass: "span12-xs span4-sm" },
                        [
                          _c("image-input", {
                            attrs: {
                              placeholder: _vm.currentAvatar,
                              prefix: "avatar"
                            },
                            model: {
                              value: _vm.formProfile.avatar,
                              callback: function($$v) {
                                _vm.$set(_vm.formProfile, "avatar", $$v)
                              },
                              expression: "formProfile.avatar"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "span12-xs span8-sm" },
                        [
                          _c("text-input", {
                            attrs: {
                              label: "Nombre",
                              error: _vm.errorsProfile.name
                            },
                            model: {
                              value: _vm.formProfile.name,
                              callback: function($$v) {
                                _vm.$set(_vm.formProfile, "name", $$v)
                              },
                              expression: "formProfile.name"
                            }
                          }),
                          _vm._v(" "),
                          _c("text-input", {
                            attrs: {
                              label: "Email",
                              error: _vm.errorsProfile.email
                            },
                            model: {
                              value: _vm.formProfile.email,
                              callback: function($$v) {
                                _vm.$set(_vm.formProfile, "email", $$v)
                              },
                              expression: "formProfile.email"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _vm._m(1)
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.section == "password",
                  expression: "section == 'password'"
                }
              ],
              staticClass: "box box-buttons"
            },
            [
              _vm._m(2),
              _vm._v(" "),
              _c(
                "form",
                {
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.submitPassword($event)
                    }
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "content" },
                    [
                      _vm.errorsPassword.first != null
                        ? _c("div", { staticClass: "alert alert-danger" }, [
                            _vm._v(
                              "\n                                " +
                                _vm._s(_vm.errorsPassword.first) +
                                "\n                            "
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("password-input", {
                        attrs: { label: "Contraseña" },
                        model: {
                          value: _vm.formPassword.first,
                          callback: function($$v) {
                            _vm.$set(_vm.formPassword, "first", $$v)
                          },
                          expression: "formPassword.first"
                        }
                      }),
                      _vm._v(" "),
                      _c("password-input", {
                        attrs: { label: "Repetir contraseña" },
                        model: {
                          value: _vm.formPassword.second,
                          callback: function($$v) {
                            _vm.$set(_vm.formPassword, "second", $$v)
                          },
                          expression: "formPassword.second"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm._m(3)
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.section == "users",
                  expression: "section == 'users'"
                }
              ],
              staticClass: "box box-buttons"
            },
            [
              _c("div", { staticClass: "header" }, [
                _c("h1", [_vm._v("Usuarios")]),
                _vm._v(" "),
                _c("div", { staticClass: "buttons-container" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.showModal("user", null)
                        }
                      }
                    },
                    [_vm._v("Añadir usuario")]
                  )
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "content" },
                [
                  _vm._l(_vm.users, function(user) {
                    return _vm.users.length > 0 && !_vm.loading
                      ? [
                          _c(
                            "div",
                            { key: user.id, staticClass: "client-user" },
                            [
                              _c("div", { staticClass: "name" }, [
                                _vm._v(_vm._s(user.name))
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "email" }, [
                                _vm._v(_vm._s(user.email))
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "buttons" }, [
                                _c("div", {
                                  staticClass: "minibtn minibtn-edit",
                                  on: {
                                    click: function($event) {
                                      return _vm.showModal("user", user)
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c("div", {
                                  staticClass: "minibtn minibtn-delete",
                                  on: {
                                    click: function($event) {
                                      return _vm.showModal("deleteUser", user)
                                    }
                                  }
                                })
                              ])
                            ]
                          )
                        ]
                      : _vm._e()
                  }),
                  _vm._v(" "),
                  _vm.users.length == 0 && !_vm.loading
                    ? _c("div", { staticClass: "alert alert-warning" }, [
                        _vm._v(
                          "\n                            Este cliente no tiene usuarios creados por lo que no puede acceder al sistema. Crea un usuario utilizando el botón de añadir usuario.\n                        "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.loading ? _c("loading") : _vm._e()
                ],
                2
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.section == "taxes",
                  expression: "section == 'taxes'"
                }
              ],
              staticClass: "box box-buttons"
            },
            [
              _c("form", { on: { submit: _vm.submitInvoices } }, [
                _vm._m(4),
                _vm._v(" "),
                _c("div", { staticClass: "content" }, [
                  _c("div", { staticClass: "flex-native" }, [
                    _c(
                      "div",
                      { staticClass: "span12-xs span4-sm" },
                      [
                        _c("h2", { staticClass: "title" }, [_vm._v("Logo")]),
                        _vm._v(" "),
                        _c("image-input", {
                          attrs: {
                            placeholder: _vm.currentLogo,
                            prefix: "logo"
                          },
                          model: {
                            value: _vm.formInvoices.logo,
                            callback: function($$v) {
                              _vm.$set(_vm.formInvoices, "logo", $$v)
                            },
                            expression: "formInvoices.logo"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "span12-xs span8-sm" },
                      [
                        _c("h2", { staticClass: "title" }, [
                          _vm._v("Nota al pie de la factura")
                        ]),
                        _vm._v(" "),
                        _c("textarea-input", {
                          model: {
                            value: _vm.formInvoices.invoiceNote,
                            callback: function($$v) {
                              _vm.$set(_vm.formInvoices, "invoiceNote", $$v)
                            },
                            expression: "formInvoices.invoiceNote"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "span12-xs",
                        staticStyle: { "margin-top": "20px" }
                      },
                      [
                        _c("h2", { staticClass: "title" }, [
                          _vm._v("Impuestos")
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "buttons-container" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              attrs: { type: "button" },
                              on: {
                                click: function($event) {
                                  return _vm.showModal("tax", null)
                                }
                              }
                            },
                            [_vm._v("Añadir impuesto")]
                          )
                        ]),
                        _vm._v(" "),
                        _vm.taxes.length > 0 && !_vm.loading
                          ? _c(
                              "div",
                              { staticClass: "flex-native" },
                              _vm._l(_vm.taxes, function(tax) {
                                return _c(
                                  "div",
                                  { staticClass: "span12-xs span6-sm" },
                                  [
                                    _c("div", { staticClass: "tax-row" }, [
                                      _vm._v(
                                        "\n                                                " +
                                          _vm._s(tax.name) +
                                          " " +
                                          _vm._s(tax.percentage) +
                                          "%\n                                                "
                                      ),
                                      _c("div", { staticClass: "buttons" }, [
                                        _c("div", {
                                          staticClass: "minibtn minibtn-edit",
                                          on: {
                                            click: function($event) {
                                              return _vm.showModal("tax", tax)
                                            }
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("div", {
                                          staticClass: "minibtn minibtn-delete",
                                          on: {
                                            click: function($event) {
                                              return _vm.showModal(
                                                "deleteTax",
                                                tax
                                              )
                                            }
                                          }
                                        })
                                      ])
                                    ])
                                  ]
                                )
                              }),
                              0
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.taxes.length == 0 && !_vm.loading
                          ? _c("div", { staticClass: "alert alert-warning" }, [
                              _vm._v(
                                "\n                                        No tienes los impuestos configurados para tus facturas.\n                                    "
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.loading ? _c("loading") : _vm._e()
                      ],
                      1
                    )
                  ])
                ]),
                _vm._v(" "),
                _vm._m(5)
              ])
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _vm.modals.user
        ? _c("edit-user-modal", {
            attrs: { user: this.userSelected, client: _vm.client },
            on: {
              close: function($event) {
                return _vm.closeModal("user", $event)
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.modals.tax
        ? _c("edit-tax-modal", {
            attrs: { tax: this.taxSelected },
            on: {
              close: function($event) {
                return _vm.closeModal("tax", $event)
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.modals.deleteUser
        ? _c("confirmation-modal", {
            attrs: {
              title: "Eliminar usuario",
              text: "¿Estás seguro de que quieres eliminar el usuario?"
            },
            on: {
              submit: _vm.deleteUser,
              close: function($event) {
                return _vm.closeModal("deleteUser")
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.modals.deleteTax
        ? _c("confirmation-modal", {
            attrs: {
              title: "Eliminar impuesto",
              text: "¿Estás seguro de que quieres eliminar este impuesto?"
            },
            on: {
              submit: _vm.deleteTax,
              close: function($event) {
                return _vm.closeModal("deleteTax")
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header" }, [
      _c("h1", [_vm._v("Editar mi perfil")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "footer" }, [
      _c(
        "button",
        { staticClass: "btn btn-primary", attrs: { type: "submit" } },
        [_vm._v("Guardar")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header" }, [
      _c("h1", [_vm._v("Cambiar contraseña")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "footer" }, [
      _c(
        "button",
        { staticClass: "btn btn-primary", attrs: { type: "submit" } },
        [_vm._v("Guardar")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header" }, [
      _c("h1", [_vm._v("Facturas")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "footer" }, [
      _c(
        "button",
        { staticClass: "btn btn-primary", attrs: { type: "submit" } },
        [_vm._v("Guardar")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }