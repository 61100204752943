var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("modal", [
    _c("form", { on: { submit: _vm.submit } }, [
      _c("div", { staticClass: "modal-header" }, [
        _c("h4", { staticClass: "modal-title" }, [_vm._v(_vm._s(_vm.title))])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "modal-body" }, [
        _c("div", { staticClass: "flex-native" }, [
          _c(
            "div",
            { staticClass: "span12-xs span6-md" },
            [
              _c("text-input", {
                attrs: { label: "Nombre", error: _vm.errors.name },
                model: {
                  value: _vm.form.name,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "span12-xs span6-md" },
            [
              _c("text-input", {
                attrs: { label: "Porcentaje", error: _vm.errors.percentage },
                model: {
                  value: _vm.form.percentage,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "percentage", $$v)
                  },
                  expression: "form.percentage"
                }
              })
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "modal-footer" }, [
        _c(
          "button",
          {
            staticClass: "btn",
            attrs: { type: "button" },
            on: { click: _vm.close }
          },
          [_vm._v("Cancelar")]
        ),
        _vm._v(" "),
        _c(
          "button",
          { staticClass: "btn btn-primary", attrs: { type: "submit" } },
          [_vm._v("Guardar")]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }