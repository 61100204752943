var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "document", class: _vm.fileClass }, [
    _c("div", { staticClass: "icon" }, [
      _c("img", { attrs: { src: _vm.image } })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "name" }, [
        _vm._v(_vm._s(_vm._f("truncate")(_vm.file.name, 20)))
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "date" }, [
        _vm._v(_vm._s(_vm._f("timeago")(_vm.file.date)))
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "options" }, [
      _c("span", {
        staticClass: "minibtn minibtn-download",
        on: {
          click: function($event) {
            $event.stopPropagation()
            return _vm.downloadClick($event)
          }
        }
      }),
      _vm._v(" "),
      _c("span", {
        staticClass: "minibtn minibtn-edit",
        on: {
          click: function($event) {
            $event.stopPropagation()
            return _vm.editClick($event)
          }
        }
      }),
      _vm._v(" "),
      _c("span", {
        staticClass: "minibtn minibtn-delete",
        on: {
          click: function($event) {
            $event.stopPropagation()
            return _vm.deleteClick($event)
          }
        }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }