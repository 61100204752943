<template>
	<div class="dropdown" style="display: inline-block;" v-show="!disabled">
		<slot name="trigger" :toggle="toggle">
			<button type="button" :class="classes" v-on:click="toggle">{{ text }}</button>
		</slot>
		<transition name="menu">
			<div class="dropdown-menu" ref="menu" v-show="visible">
				<slot name="menu"></slot>
			</div>
		</transition>
	</div>
</template>

<script>
export default {
	props: {
		text: {type: String, default: 'Prueba', required: false},
		classes: {type: String, default: 'btn', required: false},
		disabled: {type: Boolean, default: false, required: false}
	},
	data(){
		return {visible: false};
	},
	mounted() {
		document.body.addEventListener('click', this.bodyClick);
	},
	methods: {
		toggle(e){
			e.stopPropagation();
			this.visible = !this.visible;
		},
		bodyClick(e) {
			if(this.visible){
				var element = this.$refs.menu;
				var target = e.target;
				if(target != element && !element.contains(target)){
					this.visible = false;
				}
			}
		}
	}
};
</script>