<template>
    <div id="app" class="home">
        <app-header :class="{'top': scrollTop}"></app-header>
        <main role="main">
            <slot></slot>
        </main>
        <app-footer></app-footer>
    </div>
</template>

<script>
    import AppHeader from '@/components/AppHeader'
    import AppFooter from '@/components/AppFooter'

    export default{
        components: {AppHeader, AppFooter},
        data(){
            return {
                scrollTop: true
            }
        },
        created(){
            const that = this
            document.addEventListener('scroll', function(){
                var doc = document.documentElement;
                var top = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0);
                that.scrollTop = top < 50
            })
        }
    }
</script>