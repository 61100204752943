export default {
	filter: function(date){
		var date = moment(date);

		var str = 'hace nada';
		var difference = moment().unix() - date.unix();

		var prev = 'now';

		if(difference >= 60 && prev == 'now'){
			prev = 'minutes';
			difference = difference / 60;
			str = 'hace ' + Math.round(difference) + ' minuto';
			if(Math.round(difference) > 1){
				str += 's';
			}
		}

		if(difference >= 60 && prev == 'minutes'){
			prev = 'hours';
			difference = difference / 60;
			str = 'hace ' + Math.round(difference) + ' hora';
			if(Math.round(difference) > 1){
				str += 's';
			}
		}

		if(difference >= 24 && prev == 'hours'){
			prev = 'days';
			difference = difference / 24;
			str = 'hace ' + Math.round(difference) + ' día';
			if(Math.round(difference) > 1){
				str += 's';
			}
		}

		if(difference >= 7 && prev == 'days'){
			prev = 'weeks';
			difference = difference / 7;
			str = 'hace ' + Math.round(difference) + ' semana';
			if(Math.round(difference) > 1){
				str += 's';
			}
		}

		if(difference >= 4 && prev == 'weeks'){
			prev = 'months';
			difference = difference / 4;
			str = 'hace ' + Math.round(difference) + ' mes';
			if(Math.round(difference) > 1){
				str += 'es';
			}
		}

		if(difference >= 3 && prev == 'months'){
			str = 'el ' + date.format('DD/MM/YYYY');
		}
		
		return str;
	}
};