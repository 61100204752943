<template>
    <div class="container container-md">
        <form v-on:submit="submit">
            <div class="box box-buttons">
                <div class="header">
                    <h1>Cambiar contraseña</h1>
                </div>

                <div class="content">
                    <text-input type="password" label="Contraseña" v-model="form.first" :error="errors.first"></text-input>
                    <text-input type="password" label="Repetir contraseña" v-model="form.second"></text-input>
                </div>
                <div class="footer">
                    <button type="submit" class="btn btn-primary">Guardar</button>
                </div>

            </div>
        </form>
    </div>
</template>

<script>


export default{
    data(){
        return {
            form: {
                first: '',
                second: '',
            },
            errors: {
                first: null
            }
        }
    },
    methods: {
        submit(e){
            e.preventDefault();
            const userId = this.$route.params.userId
            const token = this.$route.params.token
            Api.resetPassword(userId, token, this.form).then((response) => {
                if(response.data.success){
                    this.$snotify.success('Hemos actualizado tu contraseña.');
                    this.$store.dispatch('refreshUser').then(() => {
                        this.$router.push({name: this.$store.state.user.role + '_home'});
                    });
                }else{
                    // In this case, we take the password object as it is encapsulated in a property
                    this.errors = response.data.errors.password
                }
            }).catch((error) => {

            })
        }
    }
}
</script>