<template>
    <div class="form-group" :class="{'has-error': error != null}">
        <label v-if="label != ''">{{ label }}</label>
        <textarea v-on:input="update" :value="value" :placeholder="placeholder"></textarea>
        <div class="error-desc" v-show="error != null">{{ error }}</div>
    </div>
</template>

<script>
    export default{
        props: {
            label: {type: String, default: '', required: false},
            placeholder: {type: String, default: '', required: false},
            value: {type: String, default: '', required: false},
            error: {type: String, default: null, required: false}
        },
        methods: {
            update(e) {
                this.$emit('input', e.target.value);
            }
        }
    };
</script>

<style lang="scss" scoped="scoped">
    .message-container {
        label {
            color: #000000;
            text-transform: uppercase;
        }

        textarea {
            background-color: #F6F6F6;
            padding: 10px;
            border: 0px;
        }
    }
</style>