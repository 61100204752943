var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group" }, [
    _vm.label != "" ? _c("label", [_vm._v(_vm._s(_vm.label))]) : _vm._e(),
    _vm._v(" "),
    _c("input", {
      attrs: { type: "password" },
      domProps: { value: _vm.value },
      on: { input: _vm.update }
    }),
    _vm._v(" "),
    _c("div", { staticClass: "error-desc" })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }