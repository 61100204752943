var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "section section-1" }, [
      _c(
        "div",
        { staticClass: "container" },
        [
          _c("div", { staticClass: "title" }, [
            _vm._v("Comparte facturas con tus clientes")
          ]),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _c(
            "router-link",
            {
              staticClass: "btn btn-primary",
              attrs: { to: { name: "business_registration" } }
            },
            [_vm._v("Regístrate gratis")]
          )
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "section section-2" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "pretitle" }, [
          _vm._v("CONTABLES SATISFECHOS")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "title" }, [
          _vm._v("Ya ahorran tiempo usando Facturis")
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "row sm-flex" },
          _vm._l(_vm.reviews, function(review) {
            return _c(
              "div",
              { staticClass: "col-xs-12 col-sm-6 col-md-3 sm-flex" },
              [
                _c("div", { staticClass: "review" }, [
                  _c("img", { attrs: { src: review.image } }),
                  _vm._v(" "),
                  _c("div", { staticClass: "text" }, [
                    _vm._v(_vm._s(review.text))
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "info" }, [
                    _vm._v(_vm._s(review.name))
                  ])
                ])
              ]
            )
          }),
          0
        )
      ])
    ]),
    _vm._v(" "),
    _vm._m(1),
    _vm._v(" "),
    _c("section", { staticClass: "section section-contact" }, [
      _c("div", { staticClass: "container container-sm" }, [
        _c("div", { staticClass: "pretitle" }, [_vm._v("¿alguna duda?")]),
        _vm._v(" "),
        _c("h1", { staticClass: "title" }, [_vm._v("Contacta con nosotros")]),
        _vm._v(" "),
        _c("form", { on: { submit: _vm.submit } }, [
          _vm.status == "success"
            ? _c("div", { staticClass: "alert alert-success" }, [
                _vm._v(
                  "\n                Hemos recibido tu mensaje correctamente. Nos pondremos en contacto contigo lo antes posible."
                ),
                _c("br"),
                _vm._v(
                  "\n                ¡Muchas gracias por tu interés!\n            "
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.status == "error"
            ? _c("div", { staticClass: "alert alert-danger" }, [
                _vm._v(
                  "\n                Se ha producido un error al enviar tu mensaje.\n            "
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              staticClass: "box loading"
            },
            [_vm._m(2)]
          ),
          _vm._v(" "),
          _vm.status == null
            ? _c("div", { staticClass: "box box-buttons" }, [
                _c(
                  "div",
                  { staticClass: "content" },
                  [
                    _c("text-input", {
                      attrs: { label: "Nombre" },
                      model: {
                        value: _vm.form.name,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "name", $$v)
                        },
                        expression: "form.name"
                      }
                    }),
                    _vm._v(" "),
                    _c("text-input", {
                      attrs: { label: "Email" },
                      model: {
                        value: _vm.form.email,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "email", $$v)
                        },
                        expression: "form.email"
                      }
                    }),
                    _vm._v(" "),
                    _c("textarea-input", {
                      attrs: { label: "Mensaje" },
                      model: {
                        value: _vm.form.message,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "message", $$v)
                        },
                        expression: "form.message"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _vm._m(3)
              ])
            : _vm._e()
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "subtitle" }, [
      _vm._v("Invierte tu tiempo en lo que importa. Nuestro software"),
      _c("br"),
      _vm._v("simplificará el intercambio de facturas con tus clientes.")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "section section-how" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "title" }, [
          _vm._v("Conoce todas las ventajas")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-lg-3 col-lg-offset-3" }, [
            _c("div", { staticClass: "subtitle" }, [_vm._v("Para contables")]),
            _vm._v(" "),
            _c("div", { staticClass: "spec" }, [
              _vm._v(
                "\n                        Las facturas de todos tus clientes en un único lugar\n                    "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "spec" }, [
              _vm._v(
                "\n                        Solicita las facturas que te falten a tus clientes\n                    "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "spec" }, [
              _vm._v(
                "\n                        Distingue fácilmente entre facturas que ya has descargado y cuáles no\n                    "
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-lg-3" }, [
            _c("div", { staticClass: "subtitle" }, [_vm._v("Para clientes")]),
            _vm._v(" "),
            _c("div", { staticClass: "spec" }, [
              _vm._v(
                "\n                        Sube las facturas a través de la web y olvídate de mails pesados\n                    "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "spec" }, [
              _vm._v(
                "\n                        Recibe notificaciones cuando a tu asesor le falta alguna factura\n                    "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "spec" }, [
              _vm._v(
                "\n                        Dirección de correo a la que podrás reenviar los emails con archivos adjuntos y se subirán automáticamente\n                    "
              )
            ])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "spinner" }, [
      _c("div", { staticClass: "rect1" }),
      _vm._v(" "),
      _c("div", { staticClass: "rect2" }),
      _vm._v(" "),
      _c("div", { staticClass: "rect3" }),
      _vm._v(" "),
      _c("div", { staticClass: "rect4" }),
      _vm._v(" "),
      _c("div", { staticClass: "rect5" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "footer" }, [
      _c(
        "button",
        { staticClass: "btn btn-primary", attrs: { type: "submit" } },
        [_vm._v("Enviar")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }