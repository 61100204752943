<template>
    <div>
        <div class="section section-1">
            <div class="container">
                <div class="title">Comparte facturas con tus clientes</div>
                <div class="subtitle">Invierte tu tiempo en lo que importa. Nuestro software<br>simplificará el intercambio de facturas con tus clientes.</div>
                <router-link :to="{name: 'business_registration'}" class="btn btn-primary">Regístrate gratis</router-link>
            </div>
        </div>
        <div class="section section-2">
            <div class="container">
                <div class="pretitle">CONTABLES SATISFECHOS</div>
                <div class="title">Ya ahorran tiempo usando Facturis</div>
                <div class="row sm-flex">
                    <div class="col-xs-12 col-sm-6 col-md-3 sm-flex" v-for="review of reviews">
                        <div class="review">
                            <img :src="review.image">
                            <div class="text">{{ review.text }}</div>
                            <div class="info">{{ review.name }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <section class="section section-how">
            <div class="container">
                <div class="title">Conoce todas las ventajas</div>
                <div class="row">
                    <div class="col-lg-3 col-lg-offset-3">
                        <div class="subtitle">Para contables</div>
                        <div class="spec">
                            Las facturas de todos tus clientes en un único lugar
                        </div>
                        <div class="spec">
                            Solicita las facturas que te falten a tus clientes
                        </div>
                        <div class="spec">
                            Distingue fácilmente entre facturas que ya has descargado y cuáles no
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="subtitle">Para clientes</div>
                        <div class="spec">
                            Sube las facturas a través de la web y olvídate de mails pesados
                        </div>
                        <div class="spec">
                            Recibe notificaciones cuando a tu asesor le falta alguna factura
                        </div>
                        <div class="spec">
                            Dirección de correo a la que podrás reenviar los emails con archivos adjuntos y se subirán automáticamente
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section section-contact">
            <div class="container container-sm">
                <div class="pretitle">¿alguna duda?</div>
                <h1 class="title">Contacta con nosotros</h1>
                <form v-on:submit="submit">
                <div class="alert alert-success" v-if="status == 'success'">
                    Hemos recibido tu mensaje correctamente. Nos pondremos en contacto contigo lo antes posible.<br>
                    ¡Muchas gracias por tu interés!
                </div>
                <div class="alert alert-danger" v-if="status == 'error'">
                    Se ha producido un error al enviar tu mensaje.
                </div>
                <div class="box loading" v-show="loading">
                    <div class="spinner">
                        <div class="rect1"></div>
                        <div class="rect2"></div>
                        <div class="rect3"></div>
                        <div class="rect4"></div>
                        <div class="rect5"></div>
                    </div>
                </div>
                <div class="box box-buttons" v-if="status == null">
                    <div class="content">
                        <text-input label="Nombre" v-model="form.name"></text-input>
                        <text-input label="Email" v-model="form.email"></text-input>
                        <textarea-input label="Mensaje" v-model="form.message"></textarea-input>
                    </div>
                    <div class="footer">
                        <button type="submit" class="btn btn-primary">Enviar</button>
                    </div>
                </div>
                </form>
            </div>
        </section>
    </div>
</template>

<script>
export default{
    data(){
        return {
            form: {
                name: '',
                email: '',
                message: ''
            },
            loading: false,
            status: null,
            reviews: [
                {image: '/images/reviews/1.jpg', text: 'Desde que uso Facturis, he olvidado los problemas de tener que enviar mails a mis clientes solicitando facturas que me faltan. Lo recomiendo 100%', name: 'Emilio León'},
                {image: '/images/reviews/2.jpg', text: 'Al final de cada trimestre, entro a Factoris y descargo las facturas de mis clientes cómodamente. Es sencillo, útil y rápido.', name: 'Sandra Martos'},
                {image: '/images/reviews/3.jpg', text: 'Estoy encantado con Factoris. Es muy simple pero tremendamente útil. Se acabaron los correos.', name: 'Miguel Morales'},
                {image: '/images/reviews/4.jpg', text: 'He mejorado la organización de las facturas de cada cliente. Ahora sé cuáles he descargado y cuáles tengo pendientes que me envíen. Muy contento.', name: 'Felipe Gil'}

            ]
        }
    },
    mounted(){
        if(this.$store.state.user != null){
            this.$router.replace({name: this.$store.state.user.role + '_home'});
        }
    },
    methods: {
        submit(e){
            e.preventDefault()
            this.loading = true
            Api.contact(this.form).then((response) => {
                if(response.data.success){
                    this.status = 'success'
                }else{
                    this.status = 'error'
                }
                this.loading = false
            }).catch((error) => {
                this.status = 'error'
                this.loading = false
            })
        }
    }
};
</script>