<template>
    <div class="sidebar" v-if="isLogged" v-on:mouseenter="openSidebar()" v-on:mouseleave="closeSidebar()">
        <!--
        <div class="logo">
            <img src="/images/archive.svg">
        </div>-->
        <div class="sidebar-item sidebar-item-selected">
            <i class="fas fa-file-invoice-dollar"></i>
            <div class="collapsable">
                Documentos
            </div>
        </div>
        <div class="sidebar-item">
            <i class="fas fa-file"></i>
            <div class="collapsable">
                Facturas
            </div>
        </div>
    </div>
</template>

<style lang="scss">

.sidebar-open{
    main{
        margin-left: 200px;
    }

    .sidebar{
        width: 200px;
        padding: 30px 10px 0px 10px;

        .sidebar-item{
            .collapsable{
                padding-left: 0px;
            }
        }
    }
}

.sidebar{
    position: fixed;
    top: 60px;
    left: 0px;
    bottom: 0px;
    background-color: #344c67;
    width: 60px;
    z-index: 999;
    padding-left: 10px;
    padding-top: 30px;
    transition: width 0.3s ease-in-out;

    .logo{
        height: 60px;
        line-height: 60px;
        margin-bottom: 20px;
    }

    img{
        max-height: 60px;
    }

    .sidebar-item{
        color: #fff;
        line-height: 50px;
        font-size: 14px;
        cursor: pointer;
        padding: 0px 10px;
        border-radius: 10px;
        margin-bottom: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        overflow: hidden;

        i{
            font-size: 30px;
            flex-grow: 0;
            flex-shrink: 0;
            line-height: inherit;
        }

        &.selected{
            background-color: #4274E7;
        }

        &:hover{
            background-color: rgba(255, 255, 255, 0.1);
        }

        .collapsable{
            flex-grow: 1;
            flex-shrink: 1;
            margin-left: 10px;
            padding-left: 20px;
            transition: all 0.2s ease-in;
            transition-delay: 0.1s;
        }
    }
}
</style>

<script>
    export default{
        computed: {
            isLogged() {
                return this.$store.state.user != null
            }
        },
        methods: {
            openSidebar(){
                document.body.classList.add('sidebar-open')
            },
            closeSidebar(){
                document.body.classList.remove('sidebar-open')
            }
        }
    }
</script>