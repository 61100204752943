<template>
<div class="container" v-if="client != null">
    <h1 class="title">Perfil de cliente</h1>
    <div class="flex-native">
        <div class="span12-xs span4-sm">
            <div class="client box box-buttons">
                <div class="header">
                    <div class="name">{{ client.name }}</div>
                </div>
                <div class="content">
                    <div class="info">
                        <div class="line"><span>Dirección</span> {{ client.address }}<br>{{ client.zip }} {{ client.city }} ({{ client.state }})</div>
                        <div class="line"><span>Teléfono</span> {{ client.phone }}</div>
                        <div class="line"><span>CIF</span> {{ client.vat }}</div>
                    </div>
                </div>
                <div class="footer">
                    <button type="button" class="btn btn-primary" v-on:click="openModal('data', null)">Editar datos</button>
                    <button type="button" class="btn btn-primary" v-on:click="openModal('delete', null)">Eliminar cliente</button>
                </div>
            </div>
        </div>
        <div class="span12-xs span8-sm">
            <div class="box box-buttons">
                <div class="header">
                    <h2>Usuarios</h2>
                </div>
                <div class="content">
                    <template v-for="user in client.users" v-if="client.users.length > 0">
                        <div class="client-user" :key="user.id">
                            <div class="name">{{ user.name }}</div>
                            <div class="email">{{ user.email }}</div>
                            <div class="buttons">
                                <div class="minibtn minibtn-edit" v-on:click="openModal('editUser', user)"></div>
                                <div class="minibtn minibtn-delete" v-on:click="openModal('deleteUser', user)"></div>
                            </div>
                        </div>
                    </template>
                    <div class="alert alert-warning" v-if="client.users.length == 0">Este cliente no tiene usuarios creados por lo que no puede acceder al sistema. Puedes crear un usuario con el botón de <strong>Crear usuario.</strong></div>
                </div>
                <div class="footer">
                    <button type="button" class="btn btn-primary" v-on:click="openModal('editUser', null)">Añadir usuario</button>
                </div>
            </div>
        </div>
    </div>

    <edit-client-modal v-if="modals.data" :client="client" v-on:close="closeModal('data', $event)"></edit-client-modal>
    <edit-user-modal v-if="modals.editUser" :user="userSelected" :client="client" v-on:close="closeModal('editUser', $event)"></edit-user-modal>
    <confirmation-modal v-if="modals.deleteUser" v-on:submit="deleteUser" v-on:close="closeModal('deleteUser')" title="Eliminar usuario" text="¿Estás seguro de que quieres eliminar el usuario?"></confirmation-modal>
    <confirmation-modal v-if="modals.delete" title="Eliminar cliente" v-on:submit="deleteClient" v-on:close="closeModal('delete', $event)">
        <template v-slot:body>
            ¿Estás seguro de que quires eliminar a {{ client.name }}?
        </template>
    </confirmation-modal>
</div>
</template>

<script>
import EditUserModal from '@/components/EditUserModal'
import ConfirmationModal from '@/components/ConfirmationModal'
import EditClientModal from '@/components/Client/EditModal'

export default {
    components: {EditUserModal, ConfirmationModal, EditClientModal},
    data(){
        return {
            modals: {
                data: false,
                delete: false,
                editUser: false,
                deleteUser: false
            },
            errors: null,
            userSelected: null,
            client: null,
            form: null
        }
    },
    created(){
        this.load()
    },
    methods: {
        load(){
            const clientId = this.$route.params.clientId
            Api.getClient(clientId).then((response) => {
                if(response.data.success){
                    this.client = response.data.client
                }
            })
        },
        openModal(modal, user){
            this.userSelected = user
            if(modal == 'data') {
                this.form = {
                    name: this.client.name,
                    address: this.client.address,
                    zip: this.client.zip,
                    city: this.client.city,
                    state: this.client.state,
                    phone: this.client.phone,
                    vat: this.client.vat,
                    email: this.client.email
                }
                this.errors = {
                    name: null,
                    address: null,
                    zip: null,
                    city: null,
                    state: null,
                    phone: null,
                    vat: null,
                    email: null
                }
            }
            this.modals[modal] = true
        },
        closeModal(modal, reload){
            this.modals[modal] = false
            if(reload){
                this.load()
            }
        },
        deleteClient(){
            Api.deleteClient(this.client.id).then((response) => {
                if(response.data.success){
                    this.modals.delete = false
                    this.$router.push({name: 'client_manage'})
                    this.$snotify.success('Cliente eliminado correctamente')
                }else{
                    this.$snotify.error(response.data.message)
                }
            }).catch((error) => {
                this.$snotify.error(error)
            })
        },
        deleteUser(){
            Api.deleteClientUser(this.userSelected.id).then((response) => {
                if(response.data.success){
                    this.modals.deleteUser = false
                    this.load()
                }
            })
        }
    }
}
</script>
