var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-group", class: { "has-error": _vm.error != null } },
    [
      _c("label", [_vm._v("Número")]),
      _vm._v(" "),
      !_vm.active
        ? _c("div", { staticClass: "number-automatic" }, [
            _c("div", [_vm._v("Automático")]),
            _vm._v(" "),
            _c("div", { staticClass: "link", on: { click: _vm.setActive } }, [
              _vm._v("Editar")
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.active
        ? _c(
            "input",
            _vm._g(
              { attrs: { type: "text" }, domProps: { value: _vm.parsedValue } },
              _vm.listeners
            )
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.error != null,
              expression: "error != null"
            }
          ],
          staticClass: "error-desc"
        },
        [_vm._v(_vm._s(_vm.error))]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }