var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("modal", [
    _c(
      "div",
      { staticClass: "modal-header" },
      [_vm._t("title", [_vm._v(_vm._s(_vm.title))])],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "modal-body" },
      [
        _vm._t("body", [
          _c("h2", { staticClass: "subtitle" }, [_vm._v(_vm._s(_vm.text))])
        ])
      ],
      2
    ),
    _vm._v(" "),
    _c("div", { staticClass: "modal-footer" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-secondary",
          attrs: { type: "button" },
          on: { click: _vm.close }
        },
        [_vm._v("Cancelar")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-primary",
          attrs: { type: "button" },
          on: { click: _vm.submit }
        },
        [_vm._v("Confirmar")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }