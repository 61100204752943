<template>
    <modal>
        <form v-on:submit="submit">
            <div class="modal-header">
                <h4 class="modal-title">Solicitar facturas</h4>
            </div>
            <div class="modal-body">
                <div class="alert alert-danger" v-if="errors.length > 0">
                    <div v-for="error in errors" :key="error">{{ error }}</div>
                </div>
                <div class="flex-native">
                    <template v-for="claim in claims">
                        <div class="span12-xs span8-sm" :key="claim.id">
                            <text-input placeholder="Descripción" v-model="claim.description"></text-input>
                        </div>
                        <div class="span12-xs span4-sm" :key="claim.id">
                            <text-input placeholder="Total" v-model="claim.total"></text-input>
                        </div>
                    </template>
                </div>
                <div class="buttons-container">
                    <div class="minibtn minibtn-add" v-on:click="addClaim"></div>
                </div>
                <div class="alert alert-warning">Por cada factura que reclames, notificaremos al cliente para que suba la factura cuanto antes.</div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn" v-on:click="close">Cancelar</button>
                <button type="submit" class="btn btn-primary">Enviar</button>
            </div>
        </form>
    </modal>
</template>
<script>
    export default{
        props: {
            client: {type: Object, default: null, required: true}
        },
        data(){
            return {
                claims: [],
                errors: []
            }
        },
        created(){
            this.addClaim()
        },
        methods: {
            close() {
                this.$emit('close')
            },
            submit(e) {
                e.preventDefault()
                var data = {requests: this.claims}
                Api.createRequest(this.client.id, data).then((response) => {
                    this.errors = response.data.errors
                    if (response.data.success) {
                        this.$snotify.success('Solicitudes de facturas enviadas correctamente.')
                        this.$emit('close', true)
                    }else{
                        this.$snotify.danger(response.data.message)
                    }
                })
            },
            addClaim() {
                this.claims.push({description: '', total: ''})
            }
        }
    }
</script>