<template>
    <modal>
        <form v-on:submit="submit">
            <div class="modal-header">
                <h4 class="modal-title">Editar factura</h4>
            </div>
            <div class="modal-body">
                <text-input label="Nombre del documento" v-model="form.name"></text-input>
                <select-input label="Tipo de documento" v-model="form.type" :options="types" option-label="name" option-value="type" v-on:input="reloadFolders"></select-input>
                <select-input label="Carpeta" placeholder="Selecciona una carpeta" v-model="form.period" :options="folders" option-label="name" option-value="id"></select-input>
                <switch-input label="Descargada" v-model="form.downloaded"></switch-input>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn" v-on:click="close">Cancelar</button>
                <button type="submit" class="btn btn-primary">Guardar</button>
            </div>
        </form>
    </modal>
</template>

<script>
    export default{
        props: {
            file: {type: Object, default: null, required: true}
        },
        data(){
            return {
                folders: [],
                form: {
                    name: '',
                    type: null,
                    period: null,
                    downloaded: false
                }
            }
        },
        created(){
            this.form = {
                name: this.file.name,
                type: this.file.type,
                period: this.file.period,
                downloaded: this.file.downloaded
            }

            this.loadFolders()
        },
        computed: {
            types(){
                return this.$store.getters.types
            },
            isAdviser(){
                return this.$store.state.user.role == 'adviser'
            }
        },
        methods: {
            loadFolders(){
                let params = {
                    type: this.form.type
                }

                if(this.isAdviser){
                    params.clientId = this.$store.state.clientSelected.id
                }

                return Api.listFolder(params).then((response) => {
                    if (response.data.success) {
                        this.folders = response.data.folders
                    }
                })
            },
            reloadFolders(){
                this.loadFolders().then((response) => {

                    // Try to select to maintain the same folder if possible
                    let newFolderSelected = folders.find((el) => el.id == this.form.period)

                    if(newFolderSelected == null){
                        newFolderSelected = this.folders[0]
                    }

                    this.form.period = newFolderSelected
                })
            },
            close(){
                this.$emit('close', false)
            },
            submit(e){
                e.preventDefault()
                Api.updateDocument(this.file.id, this.form).then((response) => {
                    if(response.data.success){
                        this.$emit('close', true)
                    }
                })
            }
        }
    }
</script>