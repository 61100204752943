<template lang="pug">
    .image-input
        img(:src="image")
        .btn-photo(v-on:click="onClick") Cambiar
        input(type="file" ref="input" v-on:change="onChange")
</template>

<script>
    export default{
        props: {
            value: {type: String, default: '', required: false},
            placeholder: {type: String, default: '/images/logo-placeholder.png', required: false},
            prefix: {type: String, default: '', required: false}
        },
        data(){
            return {
                tmp: null
            }
        },
        created(){
            console.log('created')
        },
        destroyed(){
            console.log('destroyed')
        },
        computed: {
            image(){
                // Force dependency of placeholder
                if(this.placeholder != null){

                }
                if(this.tmp != null){
                    return this.tmp
                }
                if(this.value != ''){
                    return this.value
                }
                return this.placeholder
            }
        },
        methods: {
            onClick(){
                this.$refs.input.click()
            },
            onChange(){
                var files = this.$refs.input.files

                if(files.length == 0){
                    return
                }

                var reader = new FileReader()
                reader.addEventListener('load', (e) => {
                    this.tmp = e.target.result
                })
                reader.readAsDataURL(files[0])

                this.upload(files[0])
            },
            upload(file){
                var data = new FormData()
                data.append('file', file)
                if(this.prefix != null){
                    data.append('prefix', this.prefix)
                }

                Api.uploadFile(data).then((response) => {
                    if(response.data.success) {
                        this.$emit('input', response.data.path)
                    }
                }).catch((error) => {
                    this.$snotify.danger(error)
                })
            }
        }
    }
</script>

<style lang="scss">

    .image-input{
        text-align: center;
        position: relative;
        border-radius: 5px;
        overflow: hidden;
        background-color: #F2F2F0;

        img{
            margin: 0px auto;
            display: block;
            max-width: 100%;
        }

        input{
            display: none;
        }

        .btn-photo{
            text-align: center;
            margin-top: 10px;
            cursor: pointer;
            color: #EEEEEE;
            position: absolute;
            bottom: 0px;
            left: 0px;
            right: 0px;
            line-height: 40px;
            background-color: rgba(0, 0, 0, 0.3);

            &:hover{
                color: #FFFFFF;
            }
        }
    }
</style>