var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isLogged
    ? _c(
        "div",
        {
          staticClass: "sidebar",
          on: {
            mouseenter: function($event) {
              return _vm.openSidebar()
            },
            mouseleave: function($event) {
              return _vm.closeSidebar()
            }
          }
        },
        [_vm._m(0), _vm._v(" "), _vm._m(1)]
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "sidebar-item sidebar-item-selected" }, [
      _c("i", { staticClass: "fas fa-file-invoice-dollar" }),
      _vm._v(" "),
      _c("div", { staticClass: "collapsable" }, [
        _vm._v("\n            Documentos\n        ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "sidebar-item" }, [
      _c("i", { staticClass: "fas fa-file" }),
      _vm._v(" "),
      _c("div", { staticClass: "collapsable" }, [
        _vm._v("\n            Facturas\n        ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }