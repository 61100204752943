<template>
    <modal>
        <form v-on:submit="submit">
            <div class="modal-header">
                <h4 class="modal-title">{{ title }}</h4>
            </div>
            <div class="modal-body">
                <div class="flex-native">
                    <div class="span12-xs span6-md">
                        <text-input label="Nombre" v-model="form.name" :error="errors.name"></text-input>
                    </div>
                    <div class="span12-xs span6-md">
                        <text-input label="Porcentaje" v-model="form.percentage" :error="errors.percentage"></text-input>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn" v-on:click="close">Cancelar</button>
                <button type="submit" class="btn btn-primary">Guardar</button>
            </div>
        </form>
    </modal>
</template>
<script>
    export default{
        props: {
            tax: {type: Object, default: null, required: false}
        },
        data(){
            return {
                form: null,
                errors: {
                    name: null,
                    percentage: null
                }
            }
        },
        computed: {
            title(){
                if(this.tax != null){
                    return 'Editar impuesto'
                }

                return 'Añadir impuesto'
            }
        },
        created(){
            this.refreshForm()
        },
        methods: {
            close(){
                this.$emit('close', false)
            },
            submit(e){
                e.preventDefault()

                const handleResponse = (response) => {
                    if(response.data.success){
                        this.$emit('close', true)
                    }
                }

                const handleError = (error) => {
                    console.error(error)
                }

                if(this.tax == null){
                    Api.createTax(this.form).then(handleResponse).catch(handleError)
                }else{
                    Api.updateTax(this.tax.id, this.form).then(handleResponse).catch(handleError)
                }
            },
            refreshForm(){
                if(this.tax != null) {
                    this.form = {name: this.tax.name, percentage: this.tax.percentage}
                }else{
                    this.form = {name: '', percentage: 0}
                }
            }
        }
    }
</script>