<template>
    <div class="form-group date-input" :class="{'has-error': error != null}">
        <label v-if="label != ''">{{ label }}</label>
        <input type="text" :value="value" v-on="listeners">
        <div class="error-desc" v-show="error != null">{{ error }}</div>
        <div class="calendar-container" v-if="visible" ref="calendar">
            <div id="calendar" class="calendar">
                <div class="datepicker-header">
                    <div class="year-left" v-on:click="yearLeft"></div>
                    <div class="arrow-left" v-on:click="monthLeft"></div>
                    <div class="content">{{ title }}</div>
                    <div class="arrow-right" v-on:click="monthRight"></div>
                    <div class="year-right" v-on:click="yearRight"></div>
                </div>
                <div class="week">
                    <div>L</div>
                    <div>M</div>
                    <div>X</div>
                    <div>J</div>
                    <div>V</div>
                    <div>S</div>
                    <div>D</div>
                </div>
                <div class="body">
                    <div :class="cellClasses(cell)" v-for="cell of cells" v-on:click="selectDate(cell)">
                        {{ cell.content }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment'

    export default{
        props: {
            label: {type: String, default: '', required: false},
            error: {type: String, default: null, required: false},
            value: {type: String, default: null, required: false}
        },
        data(){
            return {
                visible: false,
                month: moment().month(),
                year: moment().year(),
            }
        },
        computed: {
            listeners(){
                return {
                    ...this.$listeners,
                    input: event => this.$emit('input', event.target.value),
                    focus: event => {
                        console.log('llegoo')
                        event.stopPropagation()
                        this.visible = true
                        document.body.addEventListener('click', this.bodyClick)
                    },
                    click: event => event.stopPropagation()
                }
            },
            title(){
                var months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
                return months[this.month] + ' ' + this.year
            },
            cells(){
                const month = this.month
                const year = this.year
                var days = this.daysInMonth(month, year);
                var firstDay = new Date(year, month, 1, 13).getDay()-1;

                if(firstDay < 0){
                    firstDay = 6;
                }

                var offsetInit = firstDay;
                var lastDay = new Date(year, month+1, 0, 13).getDay();
                var offsetEnd = 7 - lastDay;

                if(offsetEnd >= 7){
                    offsetEnd = 0;
                }

                let cells = []
                for(var i = 0; i<offsetInit; i++){
                    cells.push({type: 'empty', content: ''})
                }
                for(var i = 0; i<days;i++){
                    const date = this.getDateFormatted(i+1)
                    cells.push({type: 'day', content: (i+1).toString(), date: date})
                }
                for(var i = 0; i<offsetEnd; i++){
                    cells.push({type: 'empty', content: ''})
                }
                return cells
            }
        },
        methods: {
            bodyClick(e){
                console.log('llego2')
                if(this.visible){
                    console.log('llego3')
                    var element = this.$refs.calendar
                    var target = e.target
                    if(target != element && !element.contains(target)){
                        this.close()          
                    }
                }
            },
            close(){
                this.visible = false
                document.body.removeEventListener('click', this.bodyClick)
            },
            yearRight(){
                this.year += 1

            },
            yearLeft(){
                this.year -= 1
            },
            monthLeft(){
                var month = this.month - 1
                var year = this.year
                if(month < 0){
                    month = 11
                    year = year - 1
                }

                this.month = month
                this.year = year
            },
            monthRight(){
                var month = this.month + 1
                var year = this.year

                if(month > 11){
                    month = 0
                    year = year + 1
                }
                this.month = month
                this.year = year
            },
            daysInMonth(month, year) {
                return new Date(year, (month+1), 0, 13).getDate()
            },
            getDateFormatted(day){
                let month = this.month + 1
                month = (month<10?'0':'') + month.toString()

                day = (day<10?'0':'') + day.toString()

                return day + '/' + month + '/' + this.year
            },
            selectDate(cell){
                if(cell.type == 'day'){
                    this.$emit('input', cell.date)
                    this.close()
                }
            },
            cellClasses(cell){
                let classes = []
                if(cell.type == 'day'){
                    classes.push('day')
                }

                if(this.value != null && cell.date == this.value){
                    classes.push('selected')
                }
                return classes.join(' ')
            }
        }
    }
</script>

<style lang="scss" scoped>
    .date-input{
        position: relative;
    }

    .calendar-container{
        position: absolute;
        right: 0px;
        top: 60px;
        max-width: 250px;

        &:before{
            border-bottom: 10px solid #B0413E;
            content: '';
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            position: absolute;
            top: -10px;
            right: 20px;
            width: 0px;
            height: 0px;
            z-index: 2;
        }

        .calendar{
            box-shadow: 0px 2px 10px 5px rgba(0,0,0,0.1);
            border-radius: 5px;

            .datepicker-header{
                line-height: 40px;
                height: auto;

                .arrow-left, .arrow-right, .year-left, .year-right, .content{
                    font-size: 14px;
                }
            }

            .body > div{
                height: auto;
                line-height: 30px;
            }
        }
    }
</style>