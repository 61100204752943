import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		user: null,
		csrf: null,
		uploading: false,
		notifications: {requests: 0},
		loading: true,
		clientSelected: null
	},
	getters: {
        types: () => {
            return [
                {type: 'invoiceout', name: 'Ingresos', permission: 'invoices'},
                {type: 'invoice', name: 'Gastos', permission: 'invoices'},
                {type: 'payroll', name: 'Nóminas', permission: 'payrolls'},
                {type: 'general', name: 'Documentos', permission: 'documents'},
				{type: 'pending', name: 'Buzón', permission: 'invoices'}
            ]
        },
		permissions: () => {
        	return [
                {name: 'Facturas', value: 'invoices'},
                {name: 'Nominas', value: 'payrolls'},
                {name: 'Documentos', value: 'documents'},
                {name: 'Usuarios', value: 'users'},
			]
		},
        isAllowed: (state, getters) => (permission) => {

			if(permission == 'pending'){
        		return true
			}

            if(state.user.role == 'adviser') {
                return true
            }

            if(state.user.role == 'client' && state.user.permissions[permission]){
                return true
            }

            return false
        },
	},
	mutations: {
		setUser(state, data){
			state.user = data.user;
			state.csrf = data.csrf;
			state.loading = false;			
		},
		setUploading(state, progress){
			state.uploading = progress;
		},
		setNotifications(state, notifications){
			state.notifications = notifications;
		},
		setClientSelected(state, client){
			state.clientSelected = client;
		}
	},
	actions: {
		refreshUser(){
			return new Promise((resolve, reject) => {
				Api.me().then((response) => {
					if(response.data.success){
						this.commit('setUser', response.data);
						resolve();
					}
				}).catch((error) => {
					reject(error);	
				});
			});
		}
	}
});