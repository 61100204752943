<template>
    <div class="container">
		<h1 class="title">Facturas pendientes</h1>
		<div class="content">
            <div class="request" v-for="request in requests" :key="request.id">
                <div class="options">
                    <div class="minibtn minibtn-upload" v-on:click="clickUpload(request)"></div>
                </div>
                <div class="description">{{ request.description }}</div>
                <div class="total">{{ request.total }} €</div>
		    </div>
            <div class="alert alert-success" v-if="requests.length == 0">
		        Por el momento, tu asesor no está esperando ninguna factura.
	        </div>
		</div>
        <div style="display: none">
            <input type="file" ref="file" v-on:change="changeFile">
        </div>
	</div>
</template>

<script>
export default {
    data(){
        return {
            requests: [],
            selected: null,
            timer: null
        }
    },
    created(){
        this.load()
    },
    methods: {
        load(){
            Api.listRequest().then((response) => {
                if(response.data.success){
                    this.requests = response.data.requests
                    this.timer = setTimeout(this.load, 6000)
                }
            }).catch((error) => {

            })
        },
        clickUpload(request){
            this.selected = request
            this.$refs.file.click()
        },
        changeFile(){
            var data = new FormData()
            data.append('file', this.$refs.file.files[0])

            Api.uploadRequest(this.selected.id, data).then((response) => {
                if(response.data.success){
                    clearTimeout(this.timer)
                    this.load()
                    this.$snotify.success('Factura subida correctamente.')
                }else{
                    this.$snotify.error(response.data.message)
                }
            }).catch((error) => {

            })
        }
    }
}
</script>
