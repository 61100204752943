<template>
    <transition name="modal">
        <div class="modal-backdrop">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">Selecciona una fecha</div>
                    <div class="">
                        <date-picker :value="value" v-on:input="emitInput"></date-picker>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn" v-on:click="close">Cerrar</button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    import DatePicker from './DatePicker'

    export default{
        components: {DatePicker},
        props: {
            value: {type: String, default: null, required: false}
        },
        date(){
            return {
                date: null
            }
        },
        methods: {
            close(){
                this.$emit('close', false)
            },
            emitInput(e){
                this.$emit('input', e)
                this.$emit('close', false)
            }
        }
    }
</script>