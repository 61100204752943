var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "form",
        { on: { submit: _vm.submit } },
        [
          _c("div", { staticClass: "box box-buttons" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "content" }, [
              _c(
                "div",
                { staticClass: "form-inline" },
                [
                  _c("text-input", {
                    attrs: { label: "Serie" },
                    model: {
                      value: _vm.form.series,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "series", $$v)
                      },
                      expression: "form.series"
                    }
                  }),
                  _vm._v(" "),
                  _c("number-input", {
                    model: {
                      value: _vm.form.number,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "number", $$v)
                      },
                      expression: "form.number"
                    }
                  }),
                  _vm._v(" "),
                  _c("client-selector", {
                    attrs: { error: _vm.errors.to },
                    model: {
                      value: _vm.client,
                      callback: function($$v) {
                        _vm.client = $$v
                      },
                      expression: "client"
                    }
                  }),
                  _vm._v(" "),
                  _c("text-input", {
                    attrs: { label: "Fecha factura", error: _vm.errors.date },
                    on: {
                      focus: function($event) {
                        _vm.dateInputSelected = "date"
                      }
                    },
                    model: {
                      value: _vm.form.date,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "date", $$v)
                      },
                      expression: "form.date"
                    }
                  }),
                  _vm._v(" "),
                  _c("due-date-input", {
                    attrs: { error: _vm.errors.dueDate },
                    on: {
                      focus: function($event) {
                        _vm.dateInputSelected = "dueDate"
                      }
                    },
                    model: {
                      value: _vm.form.dueDate,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "dueDate", $$v)
                      },
                      expression: "form.dueDate"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "lines" },
                [
                  _vm._m(1),
                  _vm._v(" "),
                  _vm._l(_vm.form.lines, function(line, index) {
                    return _c("invoice-line", {
                      key: line.id,
                      attrs: {
                        value: line,
                        "show-delete": _vm.form.lines.length > 1
                      },
                      on: {
                        delete: function($event) {
                          return _vm.deleteLine(index)
                        },
                        recalculate: _vm.recalculate
                      }
                    })
                  }),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn-add-line",
                      attrs: { type: "button" },
                      on: { click: _vm.addLine }
                    },
                    [_vm._v("Añadir nueva línea")]
                  )
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "invoice-totals" },
                [
                  _c("div", { staticClass: "total-line" }, [
                    _c("div", { staticClass: "name" }, [_vm._v("Subtotal")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm._f("amount")(_vm.subtotal, 2)) + " €")
                    ])
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.form.taxes, function(tax) {
                    return _c("div", { staticClass: "total-line" }, [
                      _c("div", { staticClass: "name" }, [
                        _vm._v(
                          _vm._s(tax.name) +
                            " (" +
                            _vm._s(tax.percentage) +
                            " %)"
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "value" }, [
                        _vm._v(_vm._s(_vm._f("amount")(tax.total, 2)) + " €")
                      ])
                    ])
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "total-line" }, [
                    _c("div", { staticClass: "name" }, [_vm._v("Total")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm._f("amount")(_vm.total, 2)) + " €")
                    ])
                  ])
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "invoice-note" },
                [
                  _c("div", [_vm._v("Notas")]),
                  _vm._v(" "),
                  _c("textarea-input", {
                    attrs: {
                      placeholder:
                        "Escribe aquí el número de cuenta, formas de pago o cualquier cosa que consideres oportuno que aparezca en la factura"
                    },
                    model: {
                      value: _vm.form.note,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "note", $$v)
                      },
                      expression: "form.note"
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _vm._m(2)
          ]),
          _vm._v(" "),
          _vm.dateInputSelected != null
            ? _c("date-picker-modal", {
                on: {
                  close: function($event) {
                    _vm.dateInputSelected = null
                  }
                },
                model: {
                  value: _vm.form[_vm.dateInputSelected],
                  callback: function($$v) {
                    _vm.$set(_vm.form, _vm.dateInputSelected, $$v)
                  },
                  expression: "form[dateInputSelected]"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "box box-buttons" }, [
        _vm._m(3),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "content" },
          [
            _vm.payments.length > 0 && !_vm.loading.payments
              ? _c(
                  "table",
                  { staticClass: "table-payments" },
                  [
                    _vm._m(4),
                    _vm._v(" "),
                    _vm._l(_vm.payments, function(payment) {
                      return _c("tr", { key: payment.id }, [
                        _c("td", [
                          _vm._v(
                            _vm._s(_vm._f("format")(payment.date, "DD/MM/YYYY"))
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(payment.method))]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            _vm._s(_vm._f("amount")(payment.amount, 2)) + " €"
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c(
                            "span",
                            {
                              staticClass: "link",
                              on: {
                                click: function($event) {
                                  return _vm.openModal("delete", payment)
                                }
                              }
                            },
                            [_vm._v("Eliminar")]
                          )
                        ])
                      ])
                    })
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.payments.length == 0 && !_vm.loading.payments
              ? _c("div", { staticClass: "alert alert-warning" }, [
                  _vm._v("Aún no hay ningún pago para esta factura")
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.loading.payments ? _c("loading") : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "footer" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-primary",
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  return _vm.openModal("payment")
                }
              }
            },
            [_vm._v("Añadir un pago")]
          )
        ])
      ]),
      _vm._v(" "),
      _vm.modals.payment
        ? _c("payment-modal", {
            attrs: { amount: this.total },
            on: {
              close: function($event) {
                return _vm.closeModal("payment")
              },
              submit: _vm.submitPayment
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.modals.delete
        ? _c("confirmation-modal", {
            attrs: { title: "Eliminar pago" },
            on: {
              submit: _vm.deletePayment,
              close: function($event) {
                return _vm.closeModal("delete", $event)
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "body",
                  fn: function() {
                    return [
                      _vm._v(
                        "\n        ¿Estás seguro de que quires eliminar el pago de " +
                          _vm._s(_vm.paymentSelected.amount) +
                          " €?\n        "
                      )
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              446187093
            )
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header" }, [
      _c("h1", [_vm._v("Editar factura")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "line-header" }, [
      _c("div", { staticClass: "description" }, [_vm._v("Descripción")]),
      _vm._v(" "),
      _c("div", { staticClass: "quantity" }, [_vm._v("Cantidad")]),
      _vm._v(" "),
      _c("div", { staticClass: "price" }, [_vm._v("Precio")]),
      _vm._v(" "),
      _c("div", { staticClass: "total" }, [_vm._v("Total")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "footer" }, [
      _c(
        "button",
        { staticClass: "btn btn-primary", attrs: { type: "submit" } },
        [_vm._v("Guardar")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header" }, [_c("h2", [_vm._v("Pagos")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", [_vm._v("Fecha")]),
      _vm._v(" "),
      _c("th", [_vm._v("Forma de pago")]),
      _vm._v(" "),
      _c("th", [_vm._v("Importe")]),
      _vm._v(" "),
      _c("th", [_vm._v("Opciones")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }