var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "image-input" }, [
    _c("img", { attrs: { src: _vm.image } }),
    _c("div", { staticClass: "btn-photo", on: { click: _vm.onClick } }, [
      _vm._v("Cambiar")
    ]),
    _c("input", {
      ref: "input",
      attrs: { type: "file" },
      on: { change: _vm.onChange }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }