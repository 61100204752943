var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "right-block user-block" },
    [
      _c(
        "router-link",
        { staticClass: "btn", attrs: { to: { name: "user_login" } } },
        [_vm._v("Accede")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }