<template>
	<div class="folder" v-bind:class="{'folder-selected': selected}">
		<img :src="image">
		<div class="name">{{ folder.name }}</div>
		<div class="count" v-if="count > 0 && !remove">{{ count }}</div>
		<div class="remove" v-if="remove" v-on:click="removeClick"></div>
	</div>
</template>

<script>
export default {
	props: {
	    folder: {type: Object},
		selected: {type: Boolean, default: false, required: false},
		remove: {type: Boolean, default: false, required: false}
	},
	computed: {
		image(){
			var image = '/images/icons/folder-closed.png'
			if(this.selected){
				image = '/images/icons/folder-open.png'
			}
			return image
		},
		count(){
			return this.folder.count
		}
	},
	methods: {
	    removeClick(){
	        this.$emit('remove')
		}
	}
}
</script>

