var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("modal", [
    _c("form", { on: { submit: _vm.submit } }, [
      _c("div", { staticClass: "modal-header" }, [
        _c("h4", { staticClass: "modal-title" }, [_vm._v("Añadir pago")])
      ]),
      _c("div", { staticClass: "modal-body" }, [
        _c("div", { staticClass: "flex-native" }, [
          _c(
            "div",
            { staticClass: "span6-md" },
            [
              _c("date-input", {
                attrs: { label: "Fecha" },
                model: {
                  value: _vm.form.date,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "date", $$v)
                  },
                  expression: "form.date"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "span6-md" },
            [
              _c("select-input", {
                attrs: {
                  label: "Forma de pago",
                  placeholder: "Selecciona",
                  options: _vm.types,
                  "option-label": "label",
                  "option-value": "value"
                },
                model: {
                  value: _vm.form.method,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "method", $$v)
                  },
                  expression: "form.method"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "span6-md" },
            [
              _c("text-input", {
                attrs: { label: "Importe" },
                model: {
                  value: _vm.form.amount,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "amount", $$v)
                  },
                  expression: "form.amount"
                }
              })
            ],
            1
          )
        ])
      ]),
      _c("div", { staticClass: "modal-footer" }, [
        _c(
          "button",
          {
            staticClass: "btn",
            attrs: { type: "button" },
            on: { click: _vm.close }
          },
          [_vm._v("Cancelar")]
        ),
        _c(
          "button",
          { staticClass: "btn btn-primary", attrs: { type: "submit" } },
          [_vm._v("Enviar")]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }