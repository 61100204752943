var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-group", class: { "has-error": _vm.error != null } },
    [
      _c("label", [_vm._v("Vencimiento")]),
      _vm._v(" "),
      _c("div", { staticClass: "due-date input" }, [
        _c(
          "input",
          _vm._g(
            { attrs: { type: "text" }, domProps: { value: _vm.value } },
            _vm.listeners
          )
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            on: {
              click: function($event) {
                return _vm.setDueDate(30)
              }
            }
          },
          [_vm._v("30")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            on: {
              click: function($event) {
                return _vm.setDueDate(60)
              }
            }
          },
          [_vm._v("60")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            on: {
              click: function($event) {
                return _vm.setDueDate(90)
              }
            }
          },
          [_vm._v("90")]
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.error != null,
              expression: "error != null"
            }
          ],
          staticClass: "error-desc"
        },
        [_vm._v(_vm._s(_vm.error))]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }