<template>
	<nav>
		<router-link :to="{name: 'adviser_manage'}" v-if="isAdmin">Asesores</router-link>
	</nav>
</template>

<script>

export default{
	computed: {
		isAdviser() {
			return this.$store.state.user.role == 'adviser';
		},
		isClient() {
			return this.$store.state.user.role == 'client';
		},
		isAdmin() {
			return this.$store.state.user.role == 'admin';
		}
	}
};
</script>