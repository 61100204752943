<template>
    <div class="container">
		<h1 class="title">Buzón de faturas</h1>
		<div class="box box-inbox">
			<div class="alert alert-warning">
				Cualquier email que envíes a esta dirección con facturas como archivos adjuntos, se importarán automáticamente en tu cuenta y estarán disponibles para tu asesor.
				<br>Recuerda que también puedes crear un alias de correo con el dominio de tu empresa y redirigirlo a esta dirección y te será más fácil de recordar.
			</div>
			<div class="address">
			{{ address }}
			</div>
		</div>
	</div>
</template>

<script>
export default {
    computed: {
        address() {
            return this.$store.state.user.address
        }
    }
}
</script>
