var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container container-md" }, [
    _c("form", { on: { submit: _vm.submit } }, [
      _c("div", { staticClass: "box box-buttons" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "content" },
          [
            _c("text-input", {
              attrs: {
                type: "password",
                label: "Contraseña",
                error: _vm.errors.first
              },
              model: {
                value: _vm.form.first,
                callback: function($$v) {
                  _vm.$set(_vm.form, "first", $$v)
                },
                expression: "form.first"
              }
            }),
            _vm._v(" "),
            _c("text-input", {
              attrs: { type: "password", label: "Repetir contraseña" },
              model: {
                value: _vm.form.second,
                callback: function($$v) {
                  _vm.$set(_vm.form, "second", $$v)
                },
                expression: "form.second"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _vm._m(1)
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header" }, [
      _c("h1", [_vm._v("Cambiar contraseña")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "footer" }, [
      _c(
        "button",
        { staticClass: "btn btn-primary", attrs: { type: "submit" } },
        [_vm._v("Guardar")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }