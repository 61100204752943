var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.total > 1
    ? _c("div", { staticClass: "pagination-centered" }, [
        _c(
          "ul",
          { staticClass: "pagination" },
          [
            _vm.firstPage != null
              ? _c(
                  "li",
                  {
                    on: {
                      click: function($event) {
                        return _vm.goTo(_vm.firstPage)
                      }
                    }
                  },
                  [_c("span", [_vm._v("<<")])]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.pages, function(page) {
              return _c(
                "li",
                {
                  key: page,
                  class: { active: page == _vm.current },
                  on: {
                    click: function($event) {
                      return _vm.goTo(page)
                    }
                  }
                },
                [_c("span", [_vm._v(_vm._s(page))])]
              )
            }),
            _vm._v(" "),
            _vm.lastPage != null
              ? _c(
                  "li",
                  {
                    on: {
                      click: function($event) {
                        return _vm.goTo(_vm.lastPage)
                      }
                    }
                  },
                  [_c("span", [_vm._v(">>")])]
                )
              : _vm._e()
          ],
          2
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }