var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("modal", [
    _c("form", { on: { submit: _vm.submit } }, [
      _c("div", { staticClass: "modal-header" }, [
        _c("h4", { staticClass: "modal-title" }, [_vm._v("Editar factura")])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "modal-body" },
        [
          _c("text-input", {
            attrs: { label: "Nombre del documento" },
            model: {
              value: _vm.form.name,
              callback: function($$v) {
                _vm.$set(_vm.form, "name", $$v)
              },
              expression: "form.name"
            }
          }),
          _vm._v(" "),
          _c("select-input", {
            attrs: {
              label: "Tipo de documento",
              options: _vm.types,
              "option-label": "name",
              "option-value": "type"
            },
            on: { input: _vm.reloadFolders },
            model: {
              value: _vm.form.type,
              callback: function($$v) {
                _vm.$set(_vm.form, "type", $$v)
              },
              expression: "form.type"
            }
          }),
          _vm._v(" "),
          _c("select-input", {
            attrs: {
              label: "Carpeta",
              placeholder: "Selecciona una carpeta",
              options: _vm.folders,
              "option-label": "name",
              "option-value": "id"
            },
            model: {
              value: _vm.form.period,
              callback: function($$v) {
                _vm.$set(_vm.form, "period", $$v)
              },
              expression: "form.period"
            }
          }),
          _vm._v(" "),
          _c("switch-input", {
            attrs: { label: "Descargada" },
            model: {
              value: _vm.form.downloaded,
              callback: function($$v) {
                _vm.$set(_vm.form, "downloaded", $$v)
              },
              expression: "form.downloaded"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "modal-footer" }, [
        _c(
          "button",
          {
            staticClass: "btn",
            attrs: { type: "button" },
            on: { click: _vm.close }
          },
          [_vm._v("Cancelar")]
        ),
        _vm._v(" "),
        _c(
          "button",
          { staticClass: "btn btn-primary", attrs: { type: "submit" } },
          [_vm._v("Guardar")]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }