<template>
    <div class="switch-container">
        <div class="switch">
            <div :class="classes" v-on:click="change"></div>
        </div>
        <label v-if="label != null">{{ label }}</label>
        <slot></slot>
    </div>
</template>

<script>
    export default{
        props: {
            label: {type: String, default: null, required: false},
            value: {type: Boolean, default: false, required: false}
        },
        methods: {
            change(e){
                this.$emit('input', !this.value);
            }
        },
        computed: {
            classes(){
                var classes = ['handler'];
                if(this.value){
                    classes.push('handler-checked');
                }
                return classes.join(' ');
            }
        }
    };
</script>