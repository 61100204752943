var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _vm._l(_vm.folders, function(folder) {
        return !_vm.loading
          ? _c("folder", {
              key: folder.id,
              attrs: {
                folder: folder,
                selected: _vm.folderSelected == folder,
                remove: _vm.remove
              },
              nativeOn: {
                click: function($event) {
                  return _vm.select(folder)
                }
              }
            })
          : _vm._e()
      }),
      _vm._v(" "),
      _c("loading", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loading,
            expression: "loading"
          }
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }