<template>
    <div class="form-group" :class="{'has-error': error != null}">
        <label>Número</label>
        <div v-if="!active" class="number-automatic">
            <div>Automático</div>
            <div class="link" v-on:click="setActive">Editar</div>
        </div>
        <input type="text" :value="parsedValue"  v-on="listeners" v-if="active">
        <div class="error-desc" v-show="error != null">{{ error }}</div>
    </div>
</template>

<script>
    export default{
        props: {
            error: {type: String, default: null, required: false},
            value: {type: Number, default: null, required: false}
        },
        data(){
            return {
                active: false
            }
        },
        computed: {
            parsedValue(){
              if(this.value == 0){
                  return ''
              }
              return this.value
            },
            listeners(){
                return {
                    ...this.$listeners,
                    keypress: event => {
                        console.log('primero')
                        if(!event.key.match(/[0-9]/)){
                            console.log('ignorando key')
                            event.preventDefault()
                            return false
                        }
                        return true
                    },
                    input: event => {
                        var value = event.target.value

                        if(value == ''){
                            value = 0
                        }else{
                            value = parseInt(value)
                        }
                        this.$emit('input', value)
                    }
                }
            },
        },
        methods: {
            setActive(){
                this.active = true
            }
        },
        watch: {
            value(newVal, oldVal){
                if(newVal != null){
                    this.active = true
                }
            }
        }
    }
</script>

<style lang="scss">
    .number-automatic{
        height: 43px;
        display: flex;
        flex-direction: column;
        justify-items: center;
    }
</style>