var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    [
      _c("div", { staticClass: "container" }, [
        _c(
          "div",
          { staticClass: "left" },
          [
            _c(
              "router-link",
              { staticClass: "logo", attrs: { to: { name: "home" } } },
              [
                _c("img", { attrs: { src: "/images/archive.svg" } }),
                _vm._v("facturis\n\t\t\t")
              ]
            ),
            _vm._v(" "),
            _vm.isLogged ? _c("header-nav") : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "right" },
          [
            !_vm.isLogged ? _c("anonymous-buttons") : _vm._e(),
            _vm._v(" "),
            _vm.isClient
              ? _c(
                  "router-link",
                  {
                    staticClass: "right-block requests-block",
                    attrs: { to: { name: "request_manage" } }
                  },
                  [
                    _vm.notifications.requests > 0
                      ? _c("div", { staticClass: "bubble" })
                      : _vm._e()
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isLogged ? _c("user-dropdown") : _vm._e()
          ],
          1
        )
      ]),
      _vm._v(" "),
      _vm.uploading
        ? _c("progress-bar", { attrs: { progress: _vm.progress } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }