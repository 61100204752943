var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c(
      "form",
      { on: { submit: _vm.submit } },
      [
        _c("div", { staticClass: "box box-buttons" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "content" }, [
            _c(
              "div",
              { staticClass: "form-inline" },
              [
                _c("text-input", {
                  attrs: { label: "Serie" },
                  model: {
                    value: _vm.form.series,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "series", $$v)
                    },
                    expression: "form.series"
                  }
                }),
                _vm._v(" "),
                _c("number-input", {
                  model: {
                    value: _vm.form.number,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "number", $$v)
                    },
                    expression: "form.number"
                  }
                }),
                _vm._v(" "),
                _c("client-selector", {
                  attrs: { error: _vm.errors.to },
                  model: {
                    value: _vm.client,
                    callback: function($$v) {
                      _vm.client = $$v
                    },
                    expression: "client"
                  }
                }),
                _vm._v(" "),
                _c("text-input", {
                  attrs: { label: "Fecha factura", error: _vm.errors.date },
                  on: {
                    focus: function($event) {
                      _vm.dateInputSelected = "date"
                    }
                  },
                  model: {
                    value: _vm.form.date,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "date", $$v)
                    },
                    expression: "form.date"
                  }
                }),
                _vm._v(" "),
                _c("due-date-input", {
                  attrs: { error: _vm.errors.dueDate },
                  on: {
                    focus: function($event) {
                      _vm.dateInputSelected = "dueDate"
                    }
                  },
                  model: {
                    value: _vm.form.dueDate,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "dueDate", $$v)
                    },
                    expression: "form.dueDate"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "lines" },
              [
                _vm._m(1),
                _vm._v(" "),
                _vm._l(_vm.form.lines, function(line, index) {
                  return _c("invoice-line", {
                    key: line.id,
                    attrs: {
                      value: line,
                      "show-delete": _vm.form.lines.length > 1
                    },
                    on: {
                      delete: function($event) {
                        return _vm.deleteLine(index)
                      },
                      recalculate: _vm.recalculate
                    }
                  })
                }),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn-add-line",
                    attrs: { type: "button" },
                    on: { click: _vm.addLine }
                  },
                  [_vm._v("Añadir nueva línea")]
                )
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "invoice-totals" },
              [
                _c("div", { staticClass: "total-line" }, [
                  _c("div", { staticClass: "name" }, [_vm._v("Subtotal")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "value" }, [
                    _vm._v(_vm._s(_vm._f("amount")(_vm.subtotal, 2)) + " €")
                  ])
                ]),
                _vm._v(" "),
                _vm._l(_vm.form.taxes, function(tax) {
                  return _c("div", { staticClass: "total-line" }, [
                    _c("div", { staticClass: "name" }, [
                      _vm._v(
                        _vm._s(tax.name) + " (" + _vm._s(tax.percentage) + " %)"
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm._f("amount")(tax.total, 2)) + " €")
                    ])
                  ])
                }),
                _vm._v(" "),
                _c("div", { staticClass: "total-line" }, [
                  _c("div", { staticClass: "name" }, [_vm._v("Total")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "value" }, [
                    _vm._v(_vm._s(_vm._f("amount")(_vm.total, 2)) + " €")
                  ])
                ])
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "invoice-note" },
              [
                _c("div", [_vm._v("Notas")]),
                _vm._v(" "),
                _c("textarea-input", {
                  attrs: {
                    placeholder:
                      "Escribe aquí el número de cuenta, formas de pago o cualquier cosa que consideres oportuno que aparezca en la factura"
                  },
                  model: {
                    value: _vm.form.note,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "note", $$v)
                    },
                    expression: "form.note"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _vm._m(2)
        ]),
        _vm._v(" "),
        _vm.dateInputSelected != null
          ? _c("date-picker-modal", {
              on: {
                close: function($event) {
                  _vm.dateInputSelected = null
                }
              },
              model: {
                value: _vm.form[_vm.dateInputSelected],
                callback: function($$v) {
                  _vm.$set(_vm.form, _vm.dateInputSelected, $$v)
                },
                expression: "form[dateInputSelected]"
              }
            })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header" }, [
      _c("h1", [_vm._v("Crear factura")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "line-header" }, [
      _c("div", { staticClass: "description" }, [_vm._v("Descripción")]),
      _vm._v(" "),
      _c("div", { staticClass: "quantity" }, [_vm._v("Cantidad")]),
      _vm._v(" "),
      _c("div", { staticClass: "price" }, [_vm._v("Precio")]),
      _vm._v(" "),
      _c("div", { staticClass: "total" }, [_vm._v("Total")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "footer" }, [
      _c(
        "button",
        { staticClass: "btn btn-primary", attrs: { type: "submit" } },
        [_vm._v("Guardar")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }