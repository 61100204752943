<template>
    <div class="container">
        <form v-on:submit="submit">
            <div class="box box-buttons">
                <div class="header"><h1>Editar factura</h1></div>
                <div class="content">
                    <div class="form-inline">
                        <text-input label="Serie" v-model="form.series"></text-input>
                        <number-input v-model="form.number"></number-input>
                        <client-selector v-model="client" :error="errors.to"></client-selector>
                        <text-input label="Fecha factura" v-model="form.date" :error="errors.date" v-on:focus="dateInputSelected = 'date'"></text-input>
                        <due-date-input v-model="form.dueDate" :error="errors.dueDate" v-on:focus="dateInputSelected = 'dueDate'"></due-date-input>
                    </div>
                    <div class="lines">
                        <div class="line-header">
                            <div class="description">Descripción</div>
                            <div class="quantity">Cantidad</div>
                            <div class="price">Precio</div>
                            <div class="total">Total</div>
                        </div>
                        <invoice-line v-for="(line, index) of form.lines" :key="line.id" :value="line" :show-delete="form.lines.length > 1" v-on:delete="deleteLine(index)" v-on:recalculate="recalculate"></invoice-line>
                        <button type="button" class="btn-add-line" v-on:click="addLine">Añadir nueva línea</button>
                    </div>
                    <div class="invoice-totals">
                        <div class="total-line">
                            <div class="name">Subtotal</div>
                            <div class="value">{{ subtotal|amount(2) }} €</div>
                        </div>
                        <div class="total-line" v-for="tax of form.taxes">
                            <div class="name">{{ tax.name }} ({{ tax.percentage }} %)</div>
                            <div class="value">{{ tax.total|amount(2) }} €</div>
                        </div>
                        <div class="total-line">
                            <div class="name">Total</div>
                            <div class="value">{{ total|amount(2) }} €</div>
                        </div>
                    </div>
                    <div class="invoice-note">
                        <div>Notas</div>
                        <textarea-input v-model="form.note" placeholder="Escribe aquí el número de cuenta, formas de pago o cualquier cosa que consideres oportuno que aparezca en la factura"></textarea-input>
                    </div>
                </div>
                <div class="footer">
                    <button type="submit" class="btn btn-primary">Guardar</button>
                </div>
            </div>

            <date-picker-modal v-if="dateInputSelected != null" v-model="form[dateInputSelected]" v-on:close="dateInputSelected = null"></date-picker-modal>
        </form>
        <div class="box box-buttons">
            <div class="header">
                <h2>Pagos</h2>
            </div>
            <div class="content">
                <table class="table-payments" v-if="payments.length > 0 && !loading.payments">
                    <tr>
                        <th>Fecha</th>
                        <th>Forma de pago</th>
                        <th>Importe</th>
                        <th>Opciones</th>
                    </tr>
                    <tr v-for="payment of payments" :key="payment.id">
                        <td>{{ payment.date|format('DD/MM/YYYY') }}</td>
                        <td>{{ payment.method }}</td>
                        <td>{{ payment.amount|amount(2) }} €</td>
                        <td><span class="link" v-on:click="openModal('delete', payment)">Eliminar</span></td>
                    </tr>
                </table>
                <div class="alert alert-warning" v-if="payments.length == 0 && !loading.payments">Aún no hay ningún pago para esta factura</div>
                <loading v-if="loading.payments"></loading>
            </div>
            <div class="footer">
                <button type="button" class="btn btn-primary" v-on:click="openModal('payment')">Añadir un pago</button>
            </div>
        </div>
        <payment-modal v-if="modals.payment" :amount="this.total" v-on:close="closeModal('payment')" v-on:submit="submitPayment"></payment-modal>
        <confirmation-modal v-if="modals.delete" title="Eliminar pago" v-on:submit="deletePayment" v-on:close="closeModal('delete', $event)">
            <template v-slot:body>
            ¿Estás seguro de que quires eliminar el pago de {{ paymentSelected.amount }} €?
            </template>
        </confirmation-modal>
    </div>
</template>

<script>
    import ClientSelector from '@/components/Invoice/ClientSelector'
    import DatePickerModal from '@/components/DatePickerModal'
    import PaymentModal from '@/components/Invoice/PaymentModal'
    import ConfirmationModal from '@/components/ConfirmationModal'
    import DueDateInput from '@/components/Invoice/DueDateInput'
    import NumberInput from '@/components/Invoice/NumberInput'
    import InvoiceLine from '@/components/Invoice/Line'
    import moment from 'moment'

    export default{
        components: {
            ClientSelector, DatePickerModal, DueDateInput,
            NumberInput, InvoiceLine, PaymentModal, ConfirmationModal
        },
        data(){
            return {
                client: null,
                numberActive: false,
                loading: {
                    payments: false
                },
                form: {
                    to: null,
                    series: '',
                    number: null,
                    date: moment().format('DD/MM/YYYY'),
                    dueDate: null,
                    lines: [],
                    taxes: [],
                    note: ''
                },
                errors: {
                    to: null,
                    number: null,
                    date: null,
                    dueDate: null
                },
                modals: {
                    payment: false,
                    delete: false
                },
                paymentSelected: null,
                dateInputSelected: null,
                taxes: [],
                taxsApplied: [],
                payments: [],
                subtotal: 0,
                total: 0,
                invoice: null,
                modal: false
            }
        },
        created(){
            this.load()
            this.loadPayments()
        },
        methods: {
            load(){
                Api.getInvoice(this.$route.params.id).then((response) => {
                    const invoice = response.data.invoice
                    this.client = invoice.to
                    this.form.to = invoice.to.id
                    this.form.series = invoice.series
                    this.form.number = invoice.number
                    this.form.date = moment(invoice.date).format('DD/MM/YYYY')
                    this.form.dueDate = moment(invoice.dueDate).format('DD/MM/YYYY')
                    this.form.lines = invoice.lines
                    this.form.taxes = [...invoice.taxes]
                    this.form.note = invoice.note
                    this.invoice = invoice

                    this.recalculate()
                })
            },
            loadPayments(){
                this.loading.payments = true
                Api.listPayments(this.$route.params.id).then((response) => {
                    if(response.data.success){
                        this.payments = response.data.payments
                        this.loading.payments = false
                    }
                }).catch((error) => {
                    console.error(error)
                })
            },
            addLine(){
                this.form.lines.push({
                    description: '',
                    quantity: 1,
                    price: 0,
                    total: 0
                })
            },
            deleteLine(index){
                this.form.lines.splice(index, 1);
            },
            recalculate(){
                var subtotal = 0

                this.form.lines.map(function(line){
                    line.total = line.quantity * line.price
                    subtotal += line.total
                    return line
                })

                var total = subtotal

                for(var tax of this.form.taxes){
                    tax.total = subtotal*tax.percentage/100
                    total += tax.total
                }

                this.subtotal = subtotal
                this.total = total
            },
            checkInteger(line){
                line.quantity = line.quantity.replace(/[^0-9]/, '')
                this.recalculate()
            },
            checkAmount(line){
                line.price = line.price.replace(/[^0-9.]/, '')
                this.recalculate()
            },
            submitPayment(data){
                // Set invoiceId
                data.invoiceId = this.invoice.id
                Api.createPayment(data).then((response) => {
                    this.$snotify.success('Pago añadido correctamente')
                    this.loadPayments()
                    this.modals.payment = false
                }).catch((error) => {
                    this.$snotify.error(response.data.message)
                })
            },
            submit(e){
                e.preventDefault()
                Api.updateInvoice(this.invoice.id, this.form).then((response) => {
                    if(response.data.success) {
                        this.$router.push({name: 'invoice_manage'})
                    }else{
                        this.$snotify.error(response.data.message)
                    }
                }).catch((error) => {
                    this.$snotify.error(response.data.message)
                })
            },
            deletePayment(){
                Api.deletePayment(this.paymentSelected.id).then((response) => {
                    if(response.data.success){
                        this.$snotify.success('Pago eliminado correctamente')
                        this.modals.delete = false
                        this.loadPayments()
                    }
                }).catch((error) => {
                    this.$snotify.danger('Se produjo un error al eliminar: ' + error)
                })
            },
            openModal(modal, payment){
                if(modal == 'delete'){
                    this.paymentSelected = payment
                }
                this.modals[modal] = true
            },
            closeModal(modal){
                this.modals[modal] = false
            }
        },
        watch: {
            client(newVal, oldVal){
                this.form.to = newVal.id
            }
        }
    }
</script>

<style lang="scss">
    .form-inline{
        display: flex;
        flex-direction: row;

        & > div{
            margin-right: 20px;
        }
    }

    .tax-line{
        display: flex;
        flex-direction: row;
    }

    .invoice-line, .line-header{
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid #DDDDDD;
        font-size: 18px;
        line-height: 40px;
        background-color: #EEEEEE;
        padding-left: 60px;
        position: relative;

        .delete{
            position: absolute;
            top: 0px;
            left: 10px;
            cursor: pointer;
            width: 40px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            font-size: 14px;
            &:before {
                content: 'J';
                font-family: 'Glyphter';
            }
        }


        input, textarea{
            border: 0px;
            padding: 0px;
            padding: 7px 0px;
            line-height: 26px;
            text-align: inherit;
            border-radius: 0px;
            background-color: transparent;
        }

        textarea{
            min-height: 40px;
        }

        .description{
            flex-grow: 1;
            border-left: 1px solid #DDDDDD;
            border-right: 1px solid #DDDDDD;
            padding: 0px 10px;
        }

        .quantity, .price, .total{
            text-align: right;
            width: 100px;
            border-right: 1px solid #DDDDDD;
            padding: 0px 10px;
        }

        div{
            flex-grow: 0;
        }
    }

    .line-header{
        font-weight: 500;
        font-size: 16px;
        background-color: #344c67;
        color: #FFFFFF;
    }

    .btn-add-line{
        background-color: #EEE;
        border-color: #ddd;
        border-width: 0px 1px 1px 1px;
        display: block;
        height: 40px;
        line-height: 40px;
        font-size: 18px;
        font-weight: 500;
        margin: 0px auto 20px auto;
        position: relative;
        top: -1px;
        padding: 0px 20px;

        &:before{
            content: 'N';
            font-family: 'Glyphter';
            font-size: 16px;
            vertical-align: middle;
            margin-right: 10px;
            margin-top: -2px;
            display: inline-block;
        }
    }

    .invoice-totals{
        display: flex;
        flex-direction: row;
        background-color: #EEEEEE;
        justify-content: flex-end;

        .total-line{
            padding: 10px 20px;
            border-right: 1px solid #DDDDDD;
            text-align: right;

            .name{
                text-transform: uppercase;
                font-weight: 700;
                color: #888888;
            }

            .value{
                font-size: 26px;
                color: #000000;
            }
        }
    }

    .table-payments{
        width: 100%;

        th, td{
            text-align: right;
        }
    }
</style>