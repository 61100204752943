<template>
<div class="client-selector">
    <div class="current current-clickable" v-on:click="showSearchModal">
        <div class="title">Cliente</div>
        <div class="name">{{ clientName }}</div>
    </div>
    <modal v-if="modalSearchVisible" class="modal-selector">
		<div class="modal-header">
			<h4 class="modal-title">Seleccionar cliente</h4>
		</div>
        <div class="modal-body modal-search">    
            <input type="text" v-model="query" placeholder="Buscar..." v-on:keyup="keypress">
        </div>
		<div class="modal-body client-list">
            <div class="alert alert-warning" v-show="!loading && clients.length == 0">No hay ningún cliente con las condiciones indicadas</div>
            <div class="client-row" v-show="!loading && clients.length > 0" v-for="client in clients" v-on:click="select(client)" :key="client.id">
                {{ client.name }}
                <div class="client-row-buttons">
                    <div v-on:click="editClient(client, $event)" class="minibtn minibtn-edit"></div>
                </div>
            </div>
            <loading v-show="loading"></loading>
		</div>
		<div class="modal-footer">
			<button type="button" class="btn" v-on:click="hideSearchModal">Cerrar</button>
		</div>
	</modal>
</div>
</template>

<script>
import Modal from '@/components/Modal'
import Loading from '@/components/Loading'

export default{
    components: {Modal, Loading},
    props: {
        value: {type: Object, default: null, required: true}
    },
    data() {
        return {
            modalSearchVisible: false,
            clients: [],
            query: '',
            timer: undefined,
            loading: false
        }
    },
    computed: {
        clientName(){
            if(this.value != null){
                return this.value.name
            }
            return ''
        }
    },
    methods: {
        search(){
            this.loading = true
            const params = {query: this.query}
            Api.listClient(params).then((response) => {
                if(response.data.success){
                    this.clients = response.data.clients
                    this.loading = false
                }
            })
        },
        keypress(){
			clearTimeout(this.timer)
			this.timer = setTimeout(this.search, 300)
		},
        showSearchModal(){
            this.search()
            this.modalSearchVisible = true
        },
        hideSearchModal(){
            this.modalSearchVisible = false
        },
        select(client){
            console.log('llego2')
            this.query = ''
            this.modalSearchVisible = false

            this.$router.push({name: 'client_view', params: {clientId: client.id}})
        },
        editClient(client, e){
            e.stopPropagation()
            this.$router.push({name: 'client_edit', params: {clientId: client.id}})
        }
    }
}
</script>