<template>
  <div class="container container-sm">
	<h1 class="title">Accede a tu cuenta</h1>
	<div class="box box-buttons">
		<form v-on:submit="login">
		<div class="content">
			<div class="alert alert-danger" v-if="error">Usuario o contraseña incorrectos</div>	
			<text-input label="E-mail" v-model="form.username"></text-input>
			<password-input label="Contraseña" v-model="form.password"></password-input>
			<div class="recover">
				<router-link :to="{name: 'user_remember'}">¿No recuerdas tus datos de acceso?</router-link>
			</div>
		</div>
		<div class="footer">
			<button type="submit" class="btn btn-primary btn-block">Entrar</button>
		</div>
		</form>
	</div>
</div>
</template>

<script>

export default {
	data(){
		return {
			form: {username: null, password: null},
			error: false
		}
	},
	created(){
        this.$store.dispatch('refreshUser').then(() => {
            if(this.$store.state.user != null) {
                this.$router.push({name: this.$store.state.user.role + '_home'});
            }
        });
	},
	methods: {
		login(e) {
			e.preventDefault()
			this.error = false
			Api.login(this.form).then((response) => {
				if(response.data.success){
					this.$store.dispatch('refreshUser').then(() => {
						this.$router.push({name: this.$store.state.user.role + '_home'})
					})
				}else{
					this.error = true
				}
			})
			.catch((error) => {
				this.error = true
			})
		}
	}
}
</script>