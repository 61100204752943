var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-group", class: { "has-error": _vm.error != null } },
    [
      _vm.label != null ? _c("label", [_vm._v(_vm._s(_vm.label))]) : _vm._e(),
      _vm._v(" "),
      _c(
        "select",
        { on: { input: _vm.update } },
        [
          _vm.placeholder != null
            ? _c("option", { attrs: { value: "" } }, [
                _vm._v(_vm._s(_vm.placeholder))
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.options, function(option, index) {
            return _c(
              "option",
              {
                key: index,
                domProps: { value: index, selected: _vm.isSelected(index) }
              },
              [_vm._v(_vm._s(_vm.getLabel(option)))]
            )
          })
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.error != null,
              expression: "error != null"
            }
          ],
          staticClass: "error-desc"
        },
        [_vm._v(_vm._s(_vm.error))]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }