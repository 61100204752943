<template>
    <modal>
        <div class="modal-header">
            <slot name="title">{{ title }}</slot>
        </div>
        <div class="modal-body">
            <slot name="body">
                <h2 class="subtitle">{{ text }}</h2>
            </slot>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" v-on:click="close">Cancelar</button>
            <button type="button" class="btn btn-primary" v-on:click="submit">Confirmar</button>
        </div>
    </modal>
</template>

<script>
    export default{
        props: {
            title: {type: String, default: '', required: false},
            text: {type: String, default: '', required: false}
        },
        methods: {
            close(){
                this.$emit('close', false)
            },
            submit(){
                this.$emit('submit', false)
            }
        }
    }
</script>