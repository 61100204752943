var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("modal", [
    _c("form", { on: { submit: _vm.submit } }, [
      _c("div", { staticClass: "modal-header" }, [
        _c("h4", { staticClass: "modal-title" }, [_vm._v(_vm._s(_vm.title))])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "modal-body" }, [
        _c("div", { staticClass: "flex-native" }, [
          _c(
            "div",
            { staticClass: "span12-xs span6-md" },
            [
              _c("text-input", {
                attrs: { label: "Nombre" },
                model: {
                  value: _vm.form.name,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "span12-xs span6-md" },
            [
              _c("text-input", {
                attrs: { label: "E-mail" },
                model: {
                  value: _vm.form.email,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "email", $$v)
                  },
                  expression: "form.email"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "span12-xs span6-md" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("label", [_vm._v("Permisos")]),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { display: "flex", "flex-direction": "row" } },
                [
                  _c("checkbox-input", {
                    attrs: { label: "Facturas" },
                    model: {
                      value: _vm.form.permissions.invoices,
                      callback: function($$v) {
                        _vm.$set(_vm.form.permissions, "invoices", $$v)
                      },
                      expression: "form.permissions.invoices"
                    }
                  }),
                  _vm._v(" "),
                  _c("checkbox-input", {
                    attrs: { label: "Nóminas" },
                    model: {
                      value: _vm.form.permissions.payrolls,
                      callback: function($$v) {
                        _vm.$set(_vm.form.permissions, "payrolls", $$v)
                      },
                      expression: "form.permissions.payrolls"
                    }
                  }),
                  _vm._v(" "),
                  _c("checkbox-input", {
                    attrs: { label: "Documentos" },
                    model: {
                      value: _vm.form.permissions.documents,
                      callback: function($$v) {
                        _vm.$set(_vm.form.permissions, "documents", $$v)
                      },
                      expression: "form.permissions.documents"
                    }
                  }),
                  _vm._v(" "),
                  _c("checkbox-input", {
                    attrs: { label: "Configuración" },
                    model: {
                      value: _vm.form.permissions.configuration,
                      callback: function($$v) {
                        _vm.$set(_vm.form.permissions, "configuration", $$v)
                      },
                      expression: "form.permissions.configuration"
                    }
                  })
                ],
                1
              )
            ])
          ])
        ]),
        _vm._v(" "),
        this.user == null
          ? _c("div", { staticClass: "alert alert-warning" }, [
              _vm._v(
                "\n                Al crear un nuevo usuario para el cliente, se enviará un email invitándole a usar Facturis.\n            "
              )
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "modal-footer" }, [
        _c(
          "button",
          {
            staticClass: "btn",
            attrs: { type: "button" },
            on: { click: _vm.close }
          },
          [_vm._v("Cancelar")]
        ),
        _vm._v(" "),
        _c(
          "button",
          { staticClass: "btn btn-primary", attrs: { type: "submit" } },
          [_vm._v("Guardar")]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }