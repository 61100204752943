<template>
    <div class="">
        <div class="form-group" v-on:click="openModal('search')" :class="{'has-error': error != null}">
            <label>Cliente</label>
            <input type="text" :value="clientName">
            <div class="error-desc" v-show="error != null">{{ error }}</div>
        </div>
        <modal v-if="modals.search" class="modal-selector">
            <div class="modal-header">
                <h4 class="modal-title">Seleccionar cliente</h4>
            </div>
            <div class="modal-body modal-search">
                <div class="flex-native">
                    <div class="span12-xs span8-md">
                        <input type="text" v-model="query" placeholder="Buscar..." v-on:keyup="keypress">
                    </div>
                    <div class="span12-xs span4-md">
                        <button type="button" class="btn btn-primary btn-block" v-on:click="openModal('editor', null)">Crear cliente</button>
                    </div>
                </div>
            </div>
            <div class="modal-body client-list">
                <div class="alert alert-warning" v-show="!loading && clients.length == 0">No hay ningún cliente con las condiciones indicadas</div>
                <div class="client-row" v-show="!loading && clients.length > 0" v-for="client in clients" v-on:click="select(client)" :key="client.id">
                    {{ client.name }}
                    <div class="client-row-buttons">
                        <div class="minibtn minibtn-edit" v-on:click.stop="openModal('editor', client)"></div>
                    </div>
                </div>
                <loading v-show="loading"></loading>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn" v-on:click="closeModal('search')">Cerrar</button>
            </div>
        </modal>
        <edit-client-modal v-if="modals.editor" :client="clientSelected" v-on:close="closeModal('editor', $event)"></edit-client-modal>
    </div>
</template>

<script>
    import Modal from '@/components/Modal.vue'
    import EditClientModal from '@/components/Client/EditModal'
    import Loading from '@/components/Loading.vue'

    export default{
        components: {Modal, Loading, EditClientModal},
        props: {
            error: {type: String, default: null, required: false},
            value: {type: Object, default: null, required: false}
        },
        data() {
            return {
                clients: [],
                clientSelected: null,
                query: '',
                timer: undefined,
                loading: false,
                modals: {
                    search: false,
                    editor: false
                },
                form: {}
            }
        },
        computed: {
            clientName(){
                if(this.value != null){
                    return this.value.name
                }
                return ''
            },
            modalEditorTitle(){
                if(this.clientSelected == null){
                    return 'Crear cliente'
                }

                return 'Editar cliente'
            }
        },
        methods: {
            search(){
                this.loading = true
                const params = {query: this.query}
                Api.listClient(params).then((response) => {
                    if(response.data.success){
                        this.clients = response.data.clients
                        this.loading = false
                    }
                })
            },
            keypress(){
                clearTimeout(this.timer)
                this.timer = setTimeout(this.search, 300)
            },
            fillFormData(){
                // Fill fields for create or edit
                const client = this.clientSelected
                const fields = ['name', 'address', 'zip', 'city', 'state', 'phone', 'vat', 'email']
                for(var field of fields){
                    this.form[field] = client == null ? '' : client[field]
                }
            },
            openModal(modal, client){
                if(modal == 'search'){
                    this.search()
                }

                if(modal == 'editor'){
                    this.modals.search = false
                }
                this.clientSelected = client

                this.fillFormData()

                this.modals[modal] = true
            },
            closeModal(modal){
                this.modals[modal] = false

                if(modal == 'editor'){
                    this.search()
                    this.modals.search = true
                }
            },
            select(client){
                this.query = ''
                this.modals.search = false
                this.$emit('input', client)
            }
        }
    }
</script>