<template>
	<div class="content">
		<folder v-for="folder in folders" v-if="!loading" :key="folder.id" :folder="folder" :selected="folderSelected == folder" :remove="remove" v-on:click.native="select(folder)"></folder>
		<loading v-show="loading"></loading>
	</div>
</template>

<script>
import Folder from './Folder.vue'
import Loading from './Loading.vue'

export default {
	components: {Folder, Loading},
	props: {
		folders: {type: Array, default: [], required: true},
		folderSelected: {type: Object, default: null, required: false},
		loading: {type: Boolean, default: false, required: false},
        remove: {type: Boolean, default: false, required: false}
	},
	methods: {
		select(folder){
			this.$emit('select', folder)
		}
	}
}
</script>