
import Vue from 'vue'
import Snotify from 'vue-snotify'
import axios from 'axios'
import moment from 'moment'


import store from '@/store'
import router from '@/router'
import timeago from '@/filters/timeago'
import size from '@/filters/size'
import amount from '@/filters/amount'
import format from '@/filters/format'
import Api from '@/api'
import truncate from 'vue-truncate-filter'

import App from '@/App'
import HomeLayout from '@/layouts/Home'
import AppAnonymousLayout from '@/layouts/AppAnonymous'
import AppLoggedLayout from '@/layouts/AppLogged'

import TextInput from '@/components/Forms/TextInput'
import DateInput from '@/components/Forms/DateInput'
import TextareaInput from '@/components/Forms/TextareaInput'
import SwitchInput from '@/components/Forms/SwitchInput'
import SelectInput from '@/components/Forms/SelectInput'
import PasswordInput from '@/components/Forms/PasswordInput'
import CheckboxInput from '@/components/Forms/CheckboxInput'
import Loading from '@/components/Loading'
import Modal from '@/components/Modal'

import '../css/style.scss'

Vue.config.devtools =  true

Vue.component('home-layout', HomeLayout)
Vue.component('app-anonymous-layout', AppAnonymousLayout)
Vue.component('app-logged-layout', AppLoggedLayout)

Vue.component('text-input', TextInput)
Vue.component('date-input', DateInput)
Vue.component('textarea-input', TextareaInput)
Vue.component('switch-input', SwitchInput)
Vue.component('select-input', SelectInput)
Vue.component('checkbox-input', CheckboxInput)
Vue.component('password-input', PasswordInput)
Vue.component('loading', Loading)
Vue.component('modal', Modal)


Vue.filter('timeago', timeago.filter)
Vue.filter('size', size.filter)
Vue.filter('amount', amount.filter)
Vue.filter('format', format.filter)

Vue.use(truncate)
Vue.use(Snotify)

window.axios = axios
window.moment = moment
window.Api = Api

router.beforeEach((to, from, next) => {

	function proceed(){
		if(store.state.user != null || to.matched.some((element) => element.meta.allowAnonymous)){
			next()
		}else{
			next({name: 'user_login'})
		}
	}

	if(store.state.loading){
		store.watch(
			(state) => state.loading,
			(value) => {
				console.debug('[router]    ok store initialization state changed', value)
				if (value == false){
					proceed()
				}
			}
		)
	}else{
		proceed()
	}
})

var vm = new Vue({
	el: '#app',
	store: store,
	router: router,
    render: h => h(App),
	data() {
		return {
			timer: null
        }
	},
	created(){
		this.$store.dispatch('refreshUser').then(() => {
			if(this.$store.state.user != null){
				this.refreshNotifications()				
			}
		})
	},
	beforeDestroy(){
		clearTimeout(this.timer)
	},
	methods: {
		refreshNotifications(){
			Api.getNotifications().then((response) => {
				if(response.data.success){
					this.$store.commit('setNotifications', response.data.notifications)
					this.timer = setTimeout(this.refreshNotifications, 5000)
				}
			}).catch((error) => {

			})
		}
	}
})