<template>
<div class="search">
    <div class="input-container">
        <input type="text" v-model="query" placeholder="Buscar en esta carpeta..." v-on:keyup="keypress">
        <div class="clear" v-on:click="clear" v-show="query != ''"></div>
    </div>
</div>
</template>

<script>
export default{
    data(){
        return {
            timer: null,
            query: ''
        }
    },
    methods: {
        keypress(){
			clearTimeout(this.timer)
			this.timer = setTimeout(this.search, 300)
        },
        search(){
            this.$emit('search', this.query)
        },
        clear(){
            this.query = ''
            this.search()
        }
    }
}
</script>