var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container container-sm" }, [
    _c("h1", { staticClass: "title" }, [_vm._v("Recordar contraseña")]),
    _vm._v(" "),
    _vm.response != null
      ? _c("div", { class: _vm.responseClass }, [
          _vm._v(_vm._s(_vm.response.message))
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.response == null
      ? _c("form", { on: { submit: _vm.submit } }, [
          _c("div", { staticClass: "box box-buttons" }, [
            _c(
              "div",
              { staticClass: "content" },
              [
                _c("text-input", {
                  attrs: { label: "E-mail", error: _vm.errors.email },
                  model: {
                    value: _vm.form.email,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "email", $$v)
                    },
                    expression: "form.email"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _vm._m(0)
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "footer" }, [
      _c(
        "button",
        { staticClass: "btn btn-primary btn-block", attrs: { type: "submit" } },
        [_vm._v("Recordar")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }