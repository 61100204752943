<template>
    <modal>
        <form v-on:submit="submit">
            <div class="modal-header">
                <h4 class="modal-title">Enviar factura</h4>
            </div>
            <div class="modal-body">
              <text-input label="Asunto" v-model="form.subject"></text-input>
              <textarea-input v-model="form.message"></textarea-input>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn" v-on:click="close">Cancelar</button>
                <button type="submit" class="btn btn-primary">Enviar</button>
            </div>
        </form>
    </modal>
</template>
<script>
    export default{
        props: {
            invoice: {type: Object, default: null, required: true}
        },
        data(){
          return {
            form: {
              subject: 'Factura ' + this.invoice.number,
              message: 'Adjunto envío factura'
            }
          }
        },
        methods: {
            close() {
                this.$emit('close')
            },
            submit(e) {
                e.preventDefault()
                this.$emit('submit', this.form)                
            }
        }
    }
</script>