var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("modal", [
    _c("form", { on: { submit: _vm.submit } }, [
      _c("div", { staticClass: "modal-header" }, [
        _c("h4", { staticClass: "modal-title" }, [_vm._v("Solicitar facturas")])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "modal-body" }, [
        _vm.errors.length > 0
          ? _c(
              "div",
              { staticClass: "alert alert-danger" },
              _vm._l(_vm.errors, function(error) {
                return _c("div", { key: error }, [_vm._v(_vm._s(error))])
              }),
              0
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex-native" },
          [
            _vm._l(_vm.claims, function(claim) {
              return [
                _c(
                  "div",
                  { key: claim.id, staticClass: "span12-xs span8-sm" },
                  [
                    _c("text-input", {
                      attrs: { placeholder: "Descripción" },
                      model: {
                        value: claim.description,
                        callback: function($$v) {
                          _vm.$set(claim, "description", $$v)
                        },
                        expression: "claim.description"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { key: claim.id, staticClass: "span12-xs span4-sm" },
                  [
                    _c("text-input", {
                      attrs: { placeholder: "Total" },
                      model: {
                        value: claim.total,
                        callback: function($$v) {
                          _vm.$set(claim, "total", $$v)
                        },
                        expression: "claim.total"
                      }
                    })
                  ],
                  1
                )
              ]
            })
          ],
          2
        ),
        _vm._v(" "),
        _c("div", { staticClass: "buttons-container" }, [
          _c("div", {
            staticClass: "minibtn minibtn-add",
            on: { click: _vm.addClaim }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "alert alert-warning" }, [
          _vm._v(
            "Por cada factura que reclames, notificaremos al cliente para que suba la factura cuanto antes."
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "modal-footer" }, [
        _c(
          "button",
          {
            staticClass: "btn",
            attrs: { type: "button" },
            on: { click: _vm.close }
          },
          [_vm._v("Cancelar")]
        ),
        _vm._v(" "),
        _c(
          "button",
          { staticClass: "btn btn-primary", attrs: { type: "submit" } },
          [_vm._v("Enviar")]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }