<template>
  <div class="client">
        <div class="header">
            <div class="name" v-on:click="clickView">{{ client.name }}</div>
            <div class="buttons">
                <div class="minibtn minibtn-edit" v-on:click="clickEdit"></div>
            </div>
        </div>
        <div class="info">
            <div class="line"><span>Dirección</span> {{ client.address }}<br>{{ client.zip }} {{ client.city }} ({{ client.state }})</div>
            <div class="line"><span>Teléfono</span> {{ client.phone }}</div>
            <div class="line"><span>CIF</span> {{ client.vat }}</div>
        </div>
    </div>
</template>

<script>
export default {
    props: {client: {type: Object, required: true}},
    methods: {
        clickEdit() {
            this.$emit('clickEdit', this.client)
        },
        clickView() {
            this.$emit('clickView', this.client)
        }
    }
}
</script>
