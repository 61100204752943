var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container container-md" }, [
    _vm._m(0),
    _vm._v(" "),
    _vm.result == "success"
      ? _c("div", { staticClass: "alert alert-success" }, [
          _vm._v(
            "\n        Te hemos enviado un email para confirmar tu cuenta. Haz click en el botón que aparece en el email y entrarás directamente.\n    "
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.result == "error"
      ? _c("div", { staticClass: "alert alert-danger" }, [
          _vm._v("\n        Se ha producido un error en el registro\n    ")
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.result == null
      ? _c("form", { on: { submit: _vm.submit } }, [
          _c("div", { staticClass: "box box-buttons" }, [
            _c(
              "div",
              { staticClass: "content" },
              [
                _c("text-input", {
                  attrs: { label: "Nombre de la asesoría" },
                  model: {
                    value: _vm.form.client.name,
                    callback: function($$v) {
                      _vm.$set(_vm.form.client, "name", $$v)
                    },
                    expression: "form.client.name"
                  }
                }),
                _vm._v(" "),
                _c("text-input", {
                  attrs: { label: "Tu nombre" },
                  model: {
                    value: _vm.form.name,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "name", $$v)
                    },
                    expression: "form.name"
                  }
                }),
                _vm._v(" "),
                _c("text-input", {
                  attrs: { label: "Tu email" },
                  model: {
                    value: _vm.form.email,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "email", $$v)
                    },
                    expression: "form.email"
                  }
                }),
                _vm._v(" "),
                _c("text-input", {
                  attrs: { label: "Contraseña", type: "password" },
                  model: {
                    value: _vm.form.password.first,
                    callback: function($$v) {
                      _vm.$set(_vm.form.password, "first", $$v)
                    },
                    expression: "form.password.first"
                  }
                }),
                _vm._v(" "),
                _c("text-input", {
                  attrs: { label: "Repetir contraseña", type: "password" },
                  model: {
                    value: _vm.form.password.second,
                    callback: function($$v) {
                      _vm.$set(_vm.form.password, "second", $$v)
                    },
                    expression: "form.password.second"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _vm._m(1)
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h1", { staticClass: "title" }, [
      _vm._v("Crea tu cuenta "),
      _c("span", [_vm._v("¡gratis!")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "footer" }, [
      _c(
        "button",
        { staticClass: "btn btn-primary btn-block", attrs: { type: "submit" } },
        [_vm._v("Aceptar")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }