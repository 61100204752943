var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "switch-container" },
    [
      _c("div", { staticClass: "switch" }, [
        _c("div", { class: _vm.classes, on: { click: _vm.change } })
      ]),
      _vm._v(" "),
      _vm.label != null ? _c("label", [_vm._v(_vm._s(_vm.label))]) : _vm._e(),
      _vm._v(" "),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }