var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.modals.manage
        ? _c("modal", [
            _c("div", { staticClass: "modal-header" }, [
              _c("h4", { staticClass: "modal-title" }, [
                _vm._v("Gestionar carpetas")
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body" }, [
              _c("div", { staticClass: "buttons-container" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.openModal("new")
                      }
                    }
                  },
                  [_vm._v("Crear carpeta")]
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "row" },
                _vm._l(_vm.folders, function(folder) {
                  return _c(
                    "div",
                    { key: folder.id, staticClass: "col-xs-12 col-md-6" },
                    [
                      _c("folder", {
                        key: folder.id,
                        staticClass: "folder-item",
                        attrs: { folder: folder, remove: true },
                        on: {
                          remove: function($event) {
                            return _vm.openRemoveModal(folder)
                          }
                        }
                      })
                    ],
                    1
                  )
                }),
                0
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.closeModal("manage")
                    }
                  }
                },
                [_vm._v("Cerrar")]
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.modals.new
        ? _c("modal", [
            _c("div", { staticClass: "modal-header" }, [
              _c("h4", { staticClass: "modal-title" }, [
                _vm._v("Crear carpeta")
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.name,
                    expression: "form.name"
                  }
                ],
                attrs: { type: "text" },
                domProps: { value: _vm.form.name },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.form, "name", $event.target.value)
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.closeModal("new")
                    }
                  }
                },
                [_vm._v("Cancelar")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "button" },
                  on: { click: _vm.createFolder }
                },
                [_vm._v("Crear")]
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.modals.edit
        ? _c("modal", [
            _c("div", { staticClass: "modal-header" }, [
              _c("h4", { staticClass: "modal-title" }, [
                _vm._v("Editar carpeta")
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.name,
                    expression: "form.name"
                  }
                ],
                attrs: { type: "text" },
                domProps: { value: _vm.form.name },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.form, "name", $event.target.value)
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.closeModal("edit")
                    }
                  }
                },
                [_vm._v("Cancelar")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "button" },
                  on: { click: _vm.updateFolder }
                },
                [_vm._v("Crear")]
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.modals.delete
        ? _c("modal", [
            _c("div", { staticClass: "modal-header" }, [
              _c("h4", { staticClass: "modal-title" }, [
                _vm._v("Eliminar carpeta")
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body" }, [
              _vm._v(
                "\n            ¿Estás seguro de que quieres eliminar la carpeta " +
                  _vm._s(_vm.folderSelected.name) +
                  "?\n        "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.closeModal("delete")
                    }
                  }
                },
                [_vm._v("Cancelar")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "button" },
                  on: { click: _vm.deleteFolder }
                },
                [_vm._v("Confirmar")]
              )
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }