var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box-browser" },
    [
      _c(
        "div",
        { staticClass: "header" },
        [
          _vm.isAdviser
            ? _c("client-selector", { attrs: { value: _vm.client } })
            : _vm._e(),
          _vm._v(" "),
          _vm.isClient
            ? _c("div", { staticClass: "client-selector" }, [
                _c("div", { staticClass: "current" }, [
                  _c("div", { staticClass: "title" }, [_vm._v("Tu asesor")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "name" }, [
                    _vm._v(_vm._s(_vm.user.adviser))
                  ])
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "types" },
            _vm._l(_vm.typesAllowed, function(type) {
              return _c(
                "span",
                {
                  class: { selected: type.type == _vm.typeSelected },
                  on: {
                    click: function($event) {
                      return _vm.loadType(type.type)
                    }
                  }
                },
                [_vm._v(_vm._s(type.name))]
              )
            }),
            0
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "body" },
        [
          _c(
            "div",
            { staticClass: "folders" },
            [
              _c("div", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.typeTitle))
              ]),
              _vm._v(" "),
              _c("folder-list", {
                attrs: {
                  folders: _vm.folders,
                  folderSelected: _vm.folderSelected,
                  loading: _vm.loadingFolders
                },
                on: { select: _vm.selectFolder }
              }),
              _vm._v(" "),
              _vm.typeSelected == "general"
                ? _c(
                    "div",
                    {
                      staticClass: "create-folder",
                      on: { click: _vm.openManageFolders }
                    },
                    [
                      _vm._v(
                        "\n                Gestionar carpetas\n            "
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c("file-list", {
            attrs: {
              files: _vm.files,
              type: _vm.typeSelected,
              loading: _vm.loading
            },
            on: {
              upload: _vm.uploadFiles,
              download: _vm.downloadFile,
              search: _vm.search,
              "download-batch": _vm.downloadFiles,
              reload: _vm.reload
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.manageFolders
        ? _c("manage-folders", {
            attrs: { folders: _vm.folders },
            on: { reload: _vm.loadFolders, close: _vm.closeManageFolders }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }