<template>
  <div class="pagination-centered" v-if="total > 1">
        <ul class="pagination">
            <li v-if="firstPage != null" v-on:click="goTo(firstPage)">
                <span>&lt&lt</span>
            </li>
            <li v-for="page in pages" :key="page" :class="{'active': page == current}" v-on:click="goTo(page)">
                <span>{{ page }}</span>
            </li>
            <li v-if="lastPage != null" v-on:click="goTo(lastPage)">
                <span>&gt&gt</span>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        show: {type: Number, default: 7, required: false},
        total: {type: Number, default: 50, required: false},
        current: {type: Number, default: 9, required: false}
    },
    data() {
        return {
            
        }
    },
    methods: {
        goTo(page) {
            this.$emit('change', page)
        }
    },
    computed: {
        firstPage(){
            var pages = this.pages
            var page = null
            if(pages[0] > 1){
                page = 1
            }
            return page
        },
        lastPage(){
            var pages = this.pages
            var page = null
            if(pages[pages.length-1] < this.total){
                page = this.total
            }
            return page
        },
        pages(){
            var sup = Math.floor(this.show/2) // 5 --> 2, 6 --> 3
            var inf = -sup

            if(this.show%2 == 0){
                inf++
            }

            while((this.current + inf) < 1){
                inf++
                sup++
            }

            while((this.current + sup) > this.total){
                inf--
                sup--
            }

            var pages = []
            for(var i = inf; i <= sup; i++){
                var page = i + this.current
                if(page > 0 && page <= this.total){
                    pages.push(page)
                }
            }
            return pages
        }
    }
}
</script>