var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.disabled,
          expression: "!disabled"
        }
      ],
      staticClass: "dropdown",
      staticStyle: { display: "inline-block" }
    },
    [
      _vm._t(
        "trigger",
        [
          _c(
            "button",
            {
              class: _vm.classes,
              attrs: { type: "button" },
              on: { click: _vm.toggle }
            },
            [_vm._v(_vm._s(_vm.text))]
          )
        ],
        { toggle: _vm.toggle }
      ),
      _vm._v(" "),
      _c("transition", { attrs: { name: "menu" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.visible,
                expression: "visible"
              }
            ],
            ref: "menu",
            staticClass: "dropdown-menu"
          },
          [_vm._t("menu")],
          2
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }