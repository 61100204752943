var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "invoice-line" }, [
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showDelete,
          expression: "showDelete"
        }
      ],
      staticClass: "delete",
      on: { click: _vm.onDelete }
    }),
    _vm._v(" "),
    _c("div", { staticClass: "description" }, [
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.value.description,
            expression: "value.description"
          }
        ],
        attrs: { placeholder: "Descripción" },
        domProps: { value: _vm.value.description },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.value, "description", $event.target.value)
          }
        }
      })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "quantity" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.value.quantity,
            expression: "value.quantity"
          }
        ],
        attrs: { type: "text", placeholder: "Cantidad" },
        domProps: { value: _vm.value.quantity },
        on: {
          input: [
            function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.value, "quantity", $event.target.value)
            },
            function($event) {
              return _vm.forceFloat("quantity")
            }
          ]
        }
      })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "price" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.value.price,
            expression: "value.price"
          }
        ],
        attrs: { type: "text", placeholder: "Precio" },
        domProps: { value: _vm.value.price },
        on: {
          input: [
            function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.value, "price", $event.target.value)
            },
            function($event) {
              return _vm.forceFloat("price")
            }
          ],
          blur: _vm.fixDecimal
        }
      })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "total" }, [
      _vm._v(
        "\n        " + _vm._s(_vm._f("amount")(_vm.value.total, 2)) + " €\n    "
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }