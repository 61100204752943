var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "modal" } }, [
    _c("div", { staticClass: "modal-backdrop" }, [
      _c("div", { staticClass: "modal-dialog" }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header" }, [
            _vm._v("Selecciona una fecha")
          ]),
          _vm._v(" "),
          _c(
            "div",
            {},
            [
              _c("date-picker", {
                attrs: { value: _vm.value },
                on: { input: _vm.emitInput }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "modal-footer" }, [
            _c(
              "button",
              {
                staticClass: "btn",
                attrs: { type: "button" },
                on: { click: _vm.close }
              },
              [_vm._v("Cerrar")]
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }