var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("h1", { staticClass: "title" }, [_vm._v("Asesores registrados")]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "box" },
      _vm._l(_vm.advisers, function(user) {
        return _c("div", { key: user.id, staticClass: "adviser" }, [
          _c("div", { staticClass: "name" }, [
            _vm._v(_vm._s(user.name) + " de "),
            _c("strong", [_vm._v(_vm._s(user.client.name))])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "buttons" }, [
            _c(
              "button",
              {
                staticClass: "btn",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.impersonate(user)
                  }
                }
              },
              [_vm._v("Convertirme")]
            )
          ])
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }