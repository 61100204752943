<template>
  <div class="right-block user-block">
		<div class="avatar-container" v-on:click="toggle">
			<div>
				<div class="business">{{ user.business }}</div>
				<div class="name">{{ user.name }}</div>
			</div>
			<img :src="avatar">
		</div>
		<transition name="menu">
			<div class="dropdown-menu" ref="menu" v-show="visible">
				<ul>
					<li v-if="roleAdviser">
						<a href="#" v-on:click="goTo('client_manage', $event)">Mis clientes</a>
					</li>
					<!--
					<li>
						<a href="#" v-on:click="goTo('invoice_manage', $event)">Mis facturas</a>
					</li>-->
					<li v-if="roleClient">
						<a href="#" v-on:click="goTo('client_inbox', $event)">Buzón de facturas</a>
					</li>
					<li>
						<a href="#" v-on:click="goTo('user_configuration', $event)">Configuración</a>
					</li>
					<li class="logout">
						<a href="#" v-on:click="logout">Salir</a>
					</li>
				</ul>
			</div>
		</transition>
	</div>
</template>

<script>


export default {
	data() {
		return {visible: false}		
	},
	mounted() {
		document.body.addEventListener('click', this.bodyClick)
	},
	methods: {
		toggle(e){
			e.stopPropagation()
			this.visible = !this.visible
		},
		bodyClick(e) {
			if(this.visible){
				var element = this.$refs.menu
				var target = e.target
				if(target != element && !element.contains(target)){
					this.visible = false
				}
			}
		},
		goTo(route, e){
            e.preventDefault()
            this.visible = false
		    this.$router.push({name: route})
		},
		logout(e){
			e.preventDefault()
			this.visible = false
			Api.logout().then((response) => {
				this.$store.dispatch('refreshUser').then(() => {
					this.$router.push({name: 'home'})
				})
			})
		}
	},
	computed: {
		user() {
			return this.$store.state.user
		},
		avatar(){
		    if(this.user.avatarUrl == null){
		        return '/images/user.jpg'
			}
			return this.user.avatarUrl
		},
		roleAdviser() {
			return this.$store.state.user.role == 'adviser'
		},
		roleClient() {
			return this.$store.state.user.role == 'client'
		}
	}
}
</script>