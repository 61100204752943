<template>
    <div class="container">
        <h1 class="title">Gestión de clientes</h1>
        <div class="buttons-container">
            <router-link :to="{name: 'client_new'}" class="btn btn-primary">Añadir cliente</router-link>
        </div>
        <loading v-show="loading"></loading>
        <div class="flex-native" v-if="clients.length > 0 && !loading">
            <div class="span12-xs span6-md" v-for="client in clients" :key="client.id">
                <client :client="client" v-on:clickEdit="clickEdit" v-on:clickView="clickView" v-on:clickDelete="showDeleteClientModal(client)"></client>
            </div>
        </div>
        <pagination :show="7" :total="pages" :current="currentPage" v-on:change="goToPage"></pagination>
    </div>
</template>

<script>

import Client from '@/components/Client'
import Modal from '@/components/Modal'
import Pagination from '@/components/Pagination'

export default {
    components: {Client, Modal, Pagination},
    data() {
        return {
            loading: false,
            clients: [],
            selectedClient: null,
            pages: 1
        }
    },
    mounted() {
        this.loadClients()
    },
    computed: {
        currentPage(){
            if(this.$route.query.page == null){
                return 1
            }

            return parseInt(this.$route.query.page)
        }
    },
    methods: {
        loadClients(){
            this.loading = true
            const params = {page: this.currentPage, extra: 'extra'}
            Api.listClient(params).then((response) => {
                if(response.data.success){
                    this.clients = response.data.clients
                    this.pages = response.data.pages
                    this.loading = false
                }
            }).catch((error) => {

            })
        },
        goToPage(page){
            this.$router.push({name: 'client_manage', query: {page: page}})
        },
        clickEdit(client){
            this.$router.push({name: 'client_edit', params: {clientId: client.id}})
        },
        clickView(client){
            this.$router.push({name: 'client_view', params: {clientId: client.id}})
        }
    },
    watch: {
        '$route': function(){
            this.loadClients()
        }
    }
}
</script>