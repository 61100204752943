<template>
    <div class="checkbox" v-on:click="change">
        <div class="check" :class="{'enabled': value}"></div>
        <div class="text">{{ label }}</div>
    </div>
</template>
<script>
    export default{
        props: {
            value: {type: Boolean, default: false, required: false},
            label: {type: String, default: null, required: false}
        },
        methods: {
            change(){
                console.log(!this.value)
                this.$emit('input', !this.value)
            }
        }
    }
</script>
<style lang="scss">
    .checkbox{
        display: flex;
        flex-direction: row;
        line-height: 20px;
        margin-right: 20px;
        user-select: none;
        cursor: pointer;

        .check{
            border: 1px solid #DDDDDD;
            width: 20px;
            height: 20px;
            margin-right: 5px;
            border-radius: 5px;
            line-height: 20px;
            text-align: center;
            font-size: 12px;

            &:before{
                width: 100%;
                height: 100%;
                border-radius: 50%;
                content: 'R';
                display: block;
                color: #4274e7;
                font-family: 'Glyphter';
                transform: scale(0);
                transition: transform 0.2s ease-in-out;
            }

            &.enabled:before{
                transform: scale(1);
            }
        }
    }
</style>