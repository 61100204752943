var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("h1", { staticClass: "title" }, [_vm._v("Tus clientes")]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "content" },
      [
        _c("loading", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.loading,
              expression: "loading"
            }
          ]
        }),
        _vm._v(" "),
        _vm._l(_vm.clients, function(client) {
          return _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.loading,
                  expression: "!loading"
                }
              ],
              key: client.id,
              staticClass: "box-client-documents"
            },
            [
              client.documents > 0
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-primary btn-download",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.download(client)
                        }
                      }
                    },
                    [_vm._v("Descargar nuevas")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  staticClass: "title",
                  attrs: {
                    to: { name: "client_view", params: { clientId: client.id } }
                  }
                },
                [_vm._v(_vm._s(client.name))]
              ),
              _vm._v(" "),
              client.documents > 0
                ? _c("div", [
                    _vm._v(_vm._s(client.documents) + " facturas nuevas")
                  ])
                : _vm._e()
            ],
            1
          )
        }),
        _vm._v(" "),
        _vm.clients.length == 0 && !_vm.loading
          ? _c("div", { staticClass: "alert alert-warning" }, [
              _c("div", [_vm._v("No tienes aún ningún cliente creado.")]),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { to: { name: "client_new" } }
                    },
                    [_vm._v("Crear cliente")]
                  )
                ],
                1
              )
            ])
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }