var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.client != null
    ? _c(
        "div",
        { staticClass: "container" },
        [
          _c("h1", { staticClass: "title" }, [_vm._v("Perfil de cliente")]),
          _vm._v(" "),
          _c("div", { staticClass: "flex-native" }, [
            _c("div", { staticClass: "span12-xs span4-sm" }, [
              _c("div", { staticClass: "client box box-buttons" }, [
                _c("div", { staticClass: "header" }, [
                  _c("div", { staticClass: "name" }, [
                    _vm._v(_vm._s(_vm.client.name))
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "content" }, [
                  _c("div", { staticClass: "info" }, [
                    _c("div", { staticClass: "line" }, [
                      _c("span", [_vm._v("Dirección")]),
                      _vm._v(" " + _vm._s(_vm.client.address)),
                      _c("br"),
                      _vm._v(
                        _vm._s(_vm.client.zip) +
                          " " +
                          _vm._s(_vm.client.city) +
                          " (" +
                          _vm._s(_vm.client.state) +
                          ")"
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "line" }, [
                      _c("span", [_vm._v("Teléfono")]),
                      _vm._v(" " + _vm._s(_vm.client.phone))
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "line" }, [
                      _c("span", [_vm._v("CIF")]),
                      _vm._v(" " + _vm._s(_vm.client.vat))
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "footer" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.openModal("data", null)
                        }
                      }
                    },
                    [_vm._v("Editar datos")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.openModal("delete", null)
                        }
                      }
                    },
                    [_vm._v("Eliminar cliente")]
                  )
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "span12-xs span8-sm" }, [
              _c("div", { staticClass: "box box-buttons" }, [
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "content" },
                  [
                    _vm._l(_vm.client.users, function(user) {
                      return _vm.client.users.length > 0
                        ? [
                            _c(
                              "div",
                              { key: user.id, staticClass: "client-user" },
                              [
                                _c("div", { staticClass: "name" }, [
                                  _vm._v(_vm._s(user.name))
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "email" }, [
                                  _vm._v(_vm._s(user.email))
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "buttons" }, [
                                  _c("div", {
                                    staticClass: "minibtn minibtn-edit",
                                    on: {
                                      click: function($event) {
                                        return _vm.openModal("editUser", user)
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("div", {
                                    staticClass: "minibtn minibtn-delete",
                                    on: {
                                      click: function($event) {
                                        return _vm.openModal("deleteUser", user)
                                      }
                                    }
                                  })
                                ])
                              ]
                            )
                          ]
                        : _vm._e()
                    }),
                    _vm._v(" "),
                    _vm.client.users.length == 0
                      ? _c("div", { staticClass: "alert alert-warning" }, [
                          _vm._v(
                            "Este cliente no tiene usuarios creados por lo que no puede acceder al sistema. Puedes crear un usuario con el botón de "
                          ),
                          _c("strong", [_vm._v("Crear usuario.")])
                        ])
                      : _vm._e()
                  ],
                  2
                ),
                _vm._v(" "),
                _c("div", { staticClass: "footer" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.openModal("editUser", null)
                        }
                      }
                    },
                    [_vm._v("Añadir usuario")]
                  )
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _vm.modals.data
            ? _c("edit-client-modal", {
                attrs: { client: _vm.client },
                on: {
                  close: function($event) {
                    return _vm.closeModal("data", $event)
                  }
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.modals.editUser
            ? _c("edit-user-modal", {
                attrs: { user: _vm.userSelected, client: _vm.client },
                on: {
                  close: function($event) {
                    return _vm.closeModal("editUser", $event)
                  }
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.modals.deleteUser
            ? _c("confirmation-modal", {
                attrs: {
                  title: "Eliminar usuario",
                  text: "¿Estás seguro de que quieres eliminar el usuario?"
                },
                on: {
                  submit: _vm.deleteUser,
                  close: function($event) {
                    return _vm.closeModal("deleteUser")
                  }
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.modals.delete
            ? _c("confirmation-modal", {
                attrs: { title: "Eliminar cliente" },
                on: {
                  submit: _vm.deleteClient,
                  close: function($event) {
                    return _vm.closeModal("delete", $event)
                  }
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "body",
                      fn: function() {
                        return [
                          _vm._v(
                            "\n            ¿Estás seguro de que quires eliminar a " +
                              _vm._s(_vm.client.name) +
                              "?\n        "
                          )
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  1752320328
                )
              })
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header" }, [
      _c("h2", [_vm._v("Usuarios")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }