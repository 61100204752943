import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '@/views/Home'
import AdviserHome from '@/views/AdviserHome'
import ClientHome from '@/views/ClientHome'
import AdminHome from '@/views/AdminHome'
import AdviserManage from '@/views/AdviserManage'

import RequestManage from '@/views/RequestManage'
import Inbox from '@/views/Inbox'

import Legal from '@/views/Legal'
import Privacy from '@/views/Privacy'
import Whoweare from '@/views/Whoweare'

import BusinessRegistration from '@/views/Business/Registration'

import InvoiceNew from '@/views/Invoice/New'
import InvoiceEdit from '@/views/Invoice/Edit'
import InvoiceManage from '@/views/Invoice/Manage'

import ClientManage from '@/views/Client/Manage'
import ClientView from '@/views/Client/View'
import ClientNew from '@/views/Client/New'
import ClientEdit from '@/views/Client/Edit'

import UserLogin from '@/views/User/Login'
import UserConfiguration from '@/views/User/Configuration'
import UserRemember from '@/views/User/Remember'
import UserReset from '@/views/User/Reset'

Vue.use(VueRouter)

export default new VueRouter({
	mode: 'history',
	routes: [
		{name: 'home', path: '/', component: Home, meta: {allowAnonymous: true, layout: 'home'}},
		{name: 'legal', path: '/legal', component: Legal, meta: {allowAnonymous: true}},
		{name: 'privacy', path: '/politica-privacidad', component: Privacy, meta: {allowAnonymous: true}},
		{name: 'whoweare', path: '/quienes-somos', component: Whoweare, meta: {allowAnonymous: true}},
		{name: 'user_login', path: '/user/login', component: UserLogin, meta: {allowAnonymous: true}},
		{name: 'user_remember', path: '/user/remember', component: UserRemember, meta: {allowAnonymous: true}},
        {name: 'user_reset', path: '/user/reset/:userId/:token', component: UserReset, meta: {allowAnonymous: true}},
        {name: 'business_registration', path: '/business/registration', component: BusinessRegistration, meta: {allowAnonymous: true}},
        {name: 'admin_home', path: '/user/home/admin', component: AdminHome},
		{name: 'adviser_home', path: '/user/home/adviser', component: AdviserHome},
		{name: 'client_home', path: '/user/home/client', component: ClientHome},
		{name: 'client_view', path: '/client/view/:clientId', component: ClientView},
		{name: 'client_manage', path: '/client/manage', component: ClientManage},
		{name: 'client_new', path: '/client/new', component: ClientNew},
		{name: 'client_edit', path: '/client/edit/:clientId', component: ClientEdit},
		{name: 'user_configuration', path: '/user/configuration', component: UserConfiguration},
		{name: 'request_manage', path: '/request/manage', component: RequestManage},
		{name: 'client_inbox', path: '/client/inbox', component: Inbox},
		{name: 'adviser_manage', path: '/adviser/manage', component: AdviserManage},
		{name: 'invoice_new', path: '/invoice/new', component: InvoiceNew},
		{name: 'invoice_edit', path: '/invoice/edit/:id', component: InvoiceEdit},
		{name: 'invoice_manage', path: '/invoice/manage', component: InvoiceManage}
	],
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
})