<template lang="pug">
    modal
        form(v-on:submit="submit")
            .modal-header
                h4.modal-title Añadir pago
            
            .modal-body
                .flex-native
                    .span6-md
                        date-input(label="Fecha" v-model="form.date")
                    .span6-md
                        select-input(label="Forma de pago" placeholder="Selecciona" :options="types" option-label="label" option-value="value" v-model="form.method")
                    .span6-md
                        text-input(label="Importe" v-model="form.amount")
            .modal-footer
                button.btn(type="button" v-on:click="close") Cancelar
                button.btn.btn-primary(type="submit") Enviar
</template>

<script>
export default{
    props: {
        amount: {type: Number, default: null, required: true}
    },
    data(){
        return {
            form: {
                date: null,
                method: null,
                amount: this.amount
            }
        }
    },
    computed: {
        types(){
            return [
                {value: 'transfer', label: 'Transferencia'},
                {value: 'cash', label: 'Efectivo'}
            ]
        }
    },
    methods: {
        submit(e){
            e.preventDefault()
            this.$emit('submit', this.form)
        },
        close(){
            this.$emit('close')
        }

    }
}
</script>