var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-group", class: { "has-error": _vm.error != null } },
    [
      _vm.label != "" ? _c("label", [_vm._v(_vm._s(_vm.label))]) : _vm._e(),
      _vm._v(" "),
      _c(
        "input",
        _vm._g(
          {
            attrs: {
              type: _vm.type,
              placeholder: _vm.placeholder,
              autocapitalize: _vm.autocapitalizeValue
            },
            domProps: { value: _vm.value }
          },
          _vm.listeners
        )
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.error != null,
              expression: "error != null"
            }
          ],
          staticClass: "error-desc"
        },
        [_vm._v(_vm._s(_vm.error))]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }