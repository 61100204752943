import axios from 'axios'
import store from '@/store'

export default {
    call(request){
        if(request.method != 'GET') {
            if(request.data == null){
                request.data = {}
            }

            if(request.data instanceof FormData){
                request.data.append('_token', store.state.csrf)
            }else{
                request.data._token = store.state.csrf
            }
        }
        return axios(request)
    },
    businessRegistration(data){
        const request = {
            method: 'POST',
            url: '/api/business/registration',
            data: data
        }
        return this.call(request)
    },
    listClient(params){
        const request = {
            method: 'GET',
            url: '/api/client',
            params: params
        }
        return this.call(request)
    },
    getClient(clientId){
        const request = {
            method: 'GET',
            url: '/api/client/' + clientId + '?extra=users,private'
        }
        return this.call(request)
    },
    createClient(data){
        const request = {
            method: 'POST',
            url: '/api/client',
            data: data
        }
        return this.call(request)
    },
    createClientWithUser(data){
        const request = {
            method: 'POST',
            url: '/api/client/withuser',
            data: data
        }
        return this.call(request)
    },
    updateClient(clientId, data){
        const request = {
            method: 'PUT',
            url: '/api/client/' + clientId,
            data: data
        }
        return this.call(request)
    },
    deleteClient(clientId){
        const request = {
            method: 'DELETE',
            url: '/api/client/' + clientId
        }
        return this.call(request)
    },
    listInvoices(params){
        const request = {
            method: 'GET',
            url: '/api/invoice',
            params: params
        }
        return this.call(request)
    },
    createInvoice(data){
        const request = {
            method: 'POST',
            url: '/api/invoice',
            data: data
        }
        return this.call(request)
    },
    updateInvoice(invoiceId, data){
        const request = {
            method: 'PUT',
            url: '/api/invoice/' + invoiceId,
            data: data
        }
        return this.call(request)
    },
    getInvoice(invoiceId){
        const request = {
            method: 'GET',
            url: '/api/invoice/' + invoiceId
        }
        return this.call(request)
    },
    sendInvoice(invoiceId, data){
        const request = {
            method: 'POST',
            url: '/api/invoice/send/' + invoiceId,
            data: data
        }
        return this.call(request)
    },
    issueInvoice(invoiceId){
        const request = {
            method: 'POST',
            url: '/api/invoice/issue/' + invoiceId
        }
        return this.call(request)
    },
    userRememberPassword(data){
        const request = {
            method: 'POST',
            url: '/api/user/remember',
            data: data
        }
        return this.call(request)
    },
    resetPassword(userId, token, data){
        const request = {
            method: 'POST',
            url: '/api/user/reset/' + userId + '/' + token,
            data: {password: data}
        }
        return this.call(request)
    },
    updateMyProfile(data){
        const request = {
            method: 'POST',
            url: '/api/user/edit-my-profile',
            data: data
        }
        return this.call(request)
    },
    listTax(){
        const request = {
            method: 'GET',
            url: '/api/clienttax'
        }
        return this.call(request)
    },
    createTax(data) {
        const request = {
            method: 'POST',
            url: '/api/clienttax',
            data: data
        }
        return this.call(request)
    },
    updateTax(taxId, data){
        const request = {
            method: 'PUT',
            url: '/api/clienttax/' + taxId,
            data: data
        }
        return this.call(request)
    },
    deleteTax(taxId){
        const request = {
            method: 'DELETE',
            url: '/api/clienttax/' + taxId
        }
        return this.call(request)
    },
    listClientUser(clientId){
        const request = {
            method: 'GET',
            url: '/api/clientuser/' + clientId
        }
        return this.call(request)
    },
    createClientUser(clientId, data){
        const request = {
            method: 'POST',
            url: '/api/clientuser/' + clientId,
            data: data
        }
        return this.call(request)
    },
    updateClientUser(userId, data){
        const request = {
            method: 'PUT',
            url: '/api/clientuser/' + userId,
            data: data
        }
        return this.call(request)
    },
    deleteClientUser(userId){
        const request = {
            method: 'DELETE',
            url: '/api/clientuser/' + userId
        }
        return this.call(request)
    },
    setClientInvoices(data){
        const request = {
            method: 'POST',
            url: '/api/client/invoices',
            data: data
        }
        return this.call(request)
    },
    updateMyPassword(data){
        const request = {
            method: 'POST',
            url: '/api/user/edit-my-password',
            data: {password: data}
        }
        return this.call(request)
    },
    createFolder(data){
        const request = {
            method: 'POST',
            url: '/api/folder',
            data: data
        }
        return this.call(request)
    },
    deleteFolder(folderId){
        const request = {
            method: 'DELETE',
            url: '/api/folder/' + folderId
        }
        return this.call(request)
    },
    listFolder(params){
        const request = {
            method: 'GET',
            url: '/api/folder',
            params: params
        }
        return this.call(request)
    },
    updateDocument(documentId, data){
        const request = {
            method: 'PUT',
            url: '/api/document/' + documentId,
            data: data
        }
        return this.call(request)
    },
    createDocument(data, progressHandler){
        const request = {
            method: 'POST',
            url: '/api/document',
            data: data,
            onUploadProgress: progressHandler
        }

        return this.call(request)
    },
    listDocument(params){
        const request = {
            method: 'GET',
            url: '/api/document',
            params: params
        }
        return this.call(request)
    },
    deleteDocument(documentId){
        const request = {
            method: 'DELETE',
            url: '/api/document/' + documentId,
        }
        return this.call(request)
    },
    getDocument(documentId){
        const request = {
            method: 'GET',
            url: '/api/document/view/' + documentId
        }
        return this.call(request)
    },
    listRequest(){
        const request = {
            method: 'GET',
            url: '/api/request'
        }
        return this.call(request)
    },
    createRequest(clientId, data){
        const request = {
            method: 'POST',
            url: '/api/request/' + clientId,
            data: data
        }
        return this.call(request)
    },
    uploadRequeset(requestId, data){
        const request = {
            method: 'PUT',
            url: '/api/request/upload/' + requestId,
            data: data
        }
        return this.call(request)
    },
    listPayments(invoiceId){
        const request = {
            method: 'GET',
            url: '/api/payment',
            params: {invoiceId: invoiceId}
        }
        return this.call(request)
    },
    createPayment(data){
        const request = {
            method: 'POST',
            url: '/api/payment',
            data: data
        }

        return this.call(request)
    },
    deletePayment(paymentId){
        const request = {
            method: 'DELETE',
            url: '/api/payment/' + paymentId
        }

        return this.call(request)
    },
    getAdviserHome(){
        const request = {
            method: 'GET',
            url: '/api/business/adviser/home'
        }
        return this.call(request)
    },
    listAdviser() {
        const request = {
            method: 'GET',
            url: '/api/adviser/list'
        }
        return this.call(request)
    },
    impersonate(params){
        const request = {
            method: 'GET',
            url: '/api/user/me',
            params: params,
            maxRedirects: 0
        }
        return this.call(request)
    },
    uploadFile(data){
        const request = {
            method: 'POST',
            url: '/api/file/upload',
            data: data
        }
        return this.call(request)
    },
    me(){
        const request = {
            method: 'GET',
            url: '/api/user/me'
        }
        return this.call(request)
    },
    getNotifications(params = null){
        const request = {
            method: 'GET',
            url: '/api/user/notifications',
            params: params
        }
        return this.call(request)
    },
    contact(data){
        const request = {
            method: 'POST',
            url: '/api/user/contact',
            data: data
        }
        return this.call(request)
    },
    login(data){
        const request = {
            method: 'POST',
            url: '/api/user/login',
            data: data
        }
        return this.call(request)
    },
    logout(){
        const request = {
            method: 'POST',
            url: '/api/user/logout',
        }
        return this.call(request)
    },
}