<template>
    <div class="form-group" :class="{'has-error': error != null}">
        <label>Vencimiento</label>
        <div class="due-date input">
            <input type="text" :value="value" v-on="listeners">
            <div v-on:click="setDueDate(30)">30</div>
            <div v-on:click="setDueDate(60)">60</div>
            <div v-on:click="setDueDate(90)">90</div>
        </div>
        <div class="error-desc" v-show="error != null">{{ error }}</div>
    </div>
</template>

<script>

    export default{
        props: {
            error: {type: String, default: null, required: false},
            value: {type: String, default: null, required: false}
        },
        computed: {
            listeners(){
                return {
                    ...this.$listeners,
                    input: event => this.$emit('input', event.target.value)
                }
            }
        },
        methods: {
            setDueDate(days){
                const date = moment().add(days, 'days').format('DD/MM/YYYY')
                this.$emit('input', date)
            },
        }
    }
</script>

<style lang="scss">
    .due-date{
        display: flex;
        flex-direction: row;
        border-radius: 5px;
        border: 1px solid #DFE3E6;
        background-color: #FFFFFF;

        input{
            border-radius: 0px;
            border: 0px;
            background-color: transparent;
        }

        div{
            padding: 0px 10px;
            background-color: #EEEEEE;
            color: #333333;
            display: flex;
            align-items: center;
            cursor: pointer;

            &:hover{
                background-color: #DDDDDD;
            }
        }

        div:last-child{
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }
    }
</style>