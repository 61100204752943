<template>
    <div id="app" class="logged">
        <app-header></app-header>
        <app-sidebar v-if="false"></app-sidebar>
        <main role="main">
            <slot></slot>
        </main>
        <app-footer></app-footer>
    </div>
</template>

<script>
    import AppHeader from '@/components/AppHeader'
    import AppSidebar from '@/components/AppSidebar'
    import AppFooter from '@/components/AppFooter'

    export default{
        components: {AppHeader, AppSidebar, AppFooter}
    }
</script>