<template>
    <modal>
        <form v-on:submit="submit">
            <div class="modal-header">
                <h4 class="modal-title">{{ title }}</h4>
            </div>
            <div class="modal-body">
                <div class="flex-native">
                    <div class="span12-xs span6-md">
                        <text-input label="Nombre" v-model="form.name"></text-input>
                    </div>
                    <div class="span12-xs span6-md">
                        <text-input label="E-mail" v-model="form.email"></text-input>
                    </div>
                    <div class="span12-xs span6-md">
                        <div class="form-group">
                            <label>Permisos</label>
                            <div style="display: flex; flex-direction: row;">
                                <checkbox-input v-model="form.permissions.invoices" label="Facturas"></checkbox-input>
                                <checkbox-input v-model="form.permissions.payrolls" label="Nóminas"></checkbox-input>
                                <checkbox-input v-model="form.permissions.documents" label="Documentos"></checkbox-input>
                                <checkbox-input v-model="form.permissions.configuration" label="Configuración"></checkbox-input>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="alert alert-warning" v-if="this.user == null">
                    Al crear un nuevo usuario para el cliente, se enviará un email invitándole a usar Facturis.
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn" v-on:click="close">Cancelar</button>
                <button type="submit" class="btn btn-primary">Guardar</button>
            </div>
        </form>
    </modal>
</template>
<script>
    export default{
        props: {
            user: {type: Object, default: null, required: false},
            client: {type: Object, default: null, required: true}
        },
        data(){
            return {
                form: {
                    name: '',
                    email: '',
                    permissions: {}
                }
            }
        },
        computed: {
            permissions(){
                this.$store.getters.permissions
            },
            title(){
                if(this.user != null){
                    return 'Editar usuario'
                }

                return 'Añadir usuario'
            }
        },
        created(){
            this.refreshForm()
        },
        methods: {
            close(){
                this.$emit('close', false)
            },
            submit(e){
                e.preventDefault()

                const handleResponse = (response) => {
                    if(response.data.success) {
                        this.$emit('close', true)
                    }
                }

                const handleError = (error) => {
                    console.error(error)
                }

                if(this.user == null){
                    Api.createClientUser(this.client.id, this.form).then(handleResponse).catch(handleError)
                }else{
                    Api.updateClientUser(this.user.id, this.form).then(handleResponse).catch(handleError)
                }
            },
            refreshForm(){

                if(this.user != null) {
                    this.form = {
                        name: this.user.name,
                        email: this.user.email,
                        permissions: JSON.parse(JSON.stringify(this.user.permissions))
                    }
                }else{
                    this.form = {name: '', email: '', permissions: null}
                }


                if(!this.form.permissions) {
                    this.form.permissions = {}
                }


                // Fill the permissions in case they are not set
                this.permissions.forEach((p) => {
                    if(!this.form.permissions[p.value]) {
                        this.$set(this.form.permissions, p.value, false)
                    }
                })
            }
        }
    }
</script>