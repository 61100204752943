<template>
    <div class="box-browser">
        <div class="header">
            <client-selector :value="client"  v-if="isAdviser"></client-selector>
            <div class="client-selector" v-if="isClient">
                <div class="current">
                    <div class="title">Tu asesor</div>
                    <div class="name">{{ user.adviser }}</div>
                </div>
            </div>
            <div class="types">
                <span v-on:click="loadType(type.type)" :class="{'selected': type.type == typeSelected}" v-for="type of typesAllowed">{{ type.name }}</span>
            </div>
        </div>
        <div class="body">
            <div class="folders">
                <div class="title">{{ typeTitle }}</div>
                <folder-list :folders="folders" :folderSelected="folderSelected" :loading="loadingFolders" v-on:select="selectFolder"></folder-list>
                <div v-if="typeSelected == 'general'" class="create-folder" v-on:click="openManageFolders">
                    Gestionar carpetas
                </div>
            </div>
            <file-list :files="files" :type="typeSelected" :loading="loading" v-on:upload="uploadFiles" v-on:download="downloadFile" v-on:search="search" v-on:download-batch="downloadFiles" v-on:reload="reload"></file-list>
        </div>
        <manage-folders v-if="manageFolders" :folders="folders" v-on:reload="loadFolders" v-on:close="closeManageFolders"></manage-folders>
    </div>
</template>

<script>
import FileList from './FileList'
import FolderList from './FolderList'
import ClientSelector from './ClientSelector'
import ManageFolders from './ManageFolders'

export default{
    props: {
        client: {type: Object, default: null, required: false}
    },
    components: {ManageFolders, FileList, FolderList, ClientSelector},
    data(){
        return {
            manageFolders: false,
            typeSelected: 'invoice',
            folderSelected: null,
            searchQuery: null,
            folders: [],
            files: [],
            loading: false,
            loadingFolders: false
        }
    },
    computed: {
        typeTitle(){
            const type = this.typesAllowed.filter((el) => el.type == this.typeSelected)
            return type.name
        },
        typesAllowed(){
            return this.$store.getters.types.filter((t) => this.isAllowed(t.permission))
        },
        isAdviser(){
            return this.role == 'adviser'
        },
        isClient(){
            return this.role == 'client'
        },
        role(){
            return this.user.role
        },
        user(){
            return this.$store.state.user
        }
    },
    created(){
        this.typeSelected = this.typesAllowed[0].type
        this.loadFolders()
    },
    methods: {
        isAllowed(permission){
            return this.$store.getters.isAllowed(permission)
        },
        loadType(type){
            this.typeSelected = type
            this.loadFolders()
        },
        selectFolder(folder){
            this.folderSelected = folder
            this.loadFiles()
        },
        search(query){
            this.searchQuery = query
        },
        refreshCounters(){
            var self = this
            let params = {type: this.typeSelected}

            if(this.isAdviser){
                params.clientId = this.client.id
            }

            Api.getNotifications(params).then((response) => {
                if(response.data.success){
                    self.folders.map((folder) => {
                        var count = parseInt(response.data.counters[folder.id])
                        if( count == undefined){
                            count = 0
                        }
                        self.$set(folder, 'count', count)
                    })
                }
            })
        },
        loadFolders(){
            var params = {counters: 1, type: this.typeSelected}
            this.loadingFolders = true

			if(this.isAdviser){
				params.clientId = this.client.id
            }

			return Api.listFolder(params).then((response) => {
				if(response.data.success){
					var folders = response.data.folders
                    this.folders = folders
                    this.loadingFolders = false
                    this.refreshCounters()

                    // Try to select to maintain the same folder if possible
                    let newFolderSelected = null

                    if(this.folderSelected != null){
					    newFolderSelected = folders.find((el) => el.id == this.folderSelected.id)
                    }

                    if(newFolderSelected == null){
					    newFolderSelected = folders[0]
                    }

                    this.selectFolder(newFolderSelected)

                }
			})
        },
        openManageFolders(){
            this.manageFolders = true
        },
        closeManageFolders(){
            this.manageFolders = false
        },
        uploadFiles(files){
            var self = this
			var data = new FormData()
            data.append('period', this.folderSelected.id)
            data.append('type', this.typeSelected)

			for(var i = 0; i < files.length; i++){
				data.append('files[]', files[i])
			}

			if(this.isAdviser){
				data.append('clientId', this.client.id)
            }

			var onUploadProgress = function(progressEvent) {
                var percent = Math.round( (progressEvent.loaded * 100) / progressEvent.total )
                self.$store.commit('setUploading', percent)
            }

			self.$store.commit('setUploading', 0)

			Api.createDocument(data, onUploadProgress).then((response) => {
				if(response.data.success){
                    self.$store.commit('setUploading', false)
                    this.loadFiles()
                    this.refreshCounters()
				}
			}).catch((error) => {
        this.$snotify.danger('Se produjo un error al subir')
			})
        },
        reload(){
            this.loadFiles()
            this.refreshCounters()
        },
        loadFiles() {
			this.loading = true
			var params = {
				period: this.folderSelected.id,
                query: this.searchQuery,
                type: this.typeSelected
			}

			if(this.isAdviser){
				params.clientId = this.client.id
			}

			Api.listDocument(params).then(response => {
				if(response.data.success){
                    this.files = response.data.files
					this.loading = false
				}
			})
        },
        downloadFiles(status){
            var url = '/api/document/download/batch'
            url += '?status=' + status + '&type=' + this.typeSelected + '&period=' + this.folderSelected.id

			// Detect when we are as an adviser or as
			if(this.isAdviser){
				url += '&clientId=' + this.client.id
			}

			location.href = url
        },
        downloadFile(file){
            location.href = '/api/document/download/' + file.id
        }
    },
    watch: {
        client(){
            this.loadFolders()
        },
        searchQuery(){
            this.loadFiles()
        }
    }
}
</script>