var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "right-block user-block" },
    [
      _c(
        "div",
        { staticClass: "avatar-container", on: { click: _vm.toggle } },
        [
          _c("div", [
            _c("div", { staticClass: "business" }, [
              _vm._v(_vm._s(_vm.user.business))
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "name" }, [_vm._v(_vm._s(_vm.user.name))])
          ]),
          _vm._v(" "),
          _c("img", { attrs: { src: _vm.avatar } })
        ]
      ),
      _vm._v(" "),
      _c("transition", { attrs: { name: "menu" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.visible,
                expression: "visible"
              }
            ],
            ref: "menu",
            staticClass: "dropdown-menu"
          },
          [
            _c("ul", [
              _vm.roleAdviser
                ? _c("li", [
                    _c(
                      "a",
                      {
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            return _vm.goTo("client_manage", $event)
                          }
                        }
                      },
                      [_vm._v("Mis clientes")]
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.roleClient
                ? _c("li", [
                    _c(
                      "a",
                      {
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            return _vm.goTo("client_inbox", $event)
                          }
                        }
                      },
                      [_vm._v("Buzón de facturas")]
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        return _vm.goTo("user_configuration", $event)
                      }
                    }
                  },
                  [_vm._v("Configuración")]
                )
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "logout" }, [
                _c("a", { attrs: { href: "#" }, on: { click: _vm.logout } }, [
                  _vm._v("Salir")
                ])
              ])
            ])
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }