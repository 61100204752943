var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container container-sm" }, [
    _c("h1", { staticClass: "title" }, [_vm._v("Accede a tu cuenta")]),
    _vm._v(" "),
    _c("div", { staticClass: "box box-buttons" }, [
      _c("form", { on: { submit: _vm.login } }, [
        _c(
          "div",
          { staticClass: "content" },
          [
            _vm.error
              ? _c("div", { staticClass: "alert alert-danger" }, [
                  _vm._v("Usuario o contraseña incorrectos")
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("text-input", {
              attrs: { label: "E-mail" },
              model: {
                value: _vm.form.username,
                callback: function($$v) {
                  _vm.$set(_vm.form, "username", $$v)
                },
                expression: "form.username"
              }
            }),
            _vm._v(" "),
            _c("password-input", {
              attrs: { label: "Contraseña" },
              model: {
                value: _vm.form.password,
                callback: function($$v) {
                  _vm.$set(_vm.form, "password", $$v)
                },
                expression: "form.password"
              }
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "recover" },
              [
                _c(
                  "router-link",
                  { attrs: { to: { name: "user_remember" } } },
                  [_vm._v("¿No recuerdas tus datos de acceso?")]
                )
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _vm._m(0)
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "footer" }, [
      _c(
        "button",
        { staticClass: "btn btn-primary btn-block", attrs: { type: "submit" } },
        [_vm._v("Entrar")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }