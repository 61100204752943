<template>
    <div id="calendar" class="calendar">
        <div class="datepicker-header">
            <div class="year-left" v-on:click="yearLeft"></div>
            <div class="arrow-left" v-on:click="monthLeft"></div>
            <div class="content">{{ title }}</div>
            <div class="arrow-right" v-on:click="monthRight"></div>
            <div class="year-right" v-on:click="yearRight"></div>
        </div>
        <div class="week">
            <div>L</div>
            <div>M</div>
            <div>X</div>
            <div>J</div>
            <div>V</div>
            <div>S</div>
            <div>D</div>
        </div>
        <div class="body">
            <div :class="cellClasses(cell)" v-for="cell of cells" v-on:click="selectDate(cell)">
                {{ cell.content }}
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment'

    export default{
        props: {
            value: {type: String, default: null, required: false}
        },
        data(){
            return {
                month: moment().month(),
                year: moment().year(),
            }
        },
        created(){
            if(this.value != null){
                this.month = moment(this.value, 'DD/MM/YYYY').month()
                this.year = moment(this.value, 'DD/MM/YYYY').year()
            }
        },
        computed: {
            title() {
                var months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
                return months[this.month] + ' ' + this.year
            },
            cells(){
                const month = this.month
                const year = this.year
                var days = this.daysInMonth(month, year);
                var firstDay = new Date(year, month, 1, 13).getDay()-1;

                if(firstDay < 0){
                    firstDay = 6;
                }

                var offsetInit = firstDay;
                var lastDay = new Date(year, month+1, 0, 13).getDay();
                var offsetEnd = 7 - lastDay;

                if(offsetEnd >= 7){
                    offsetEnd = 0;
                }

                let cells = []
                for(var i = 0; i<offsetInit; i++){
                    cells.push({type: 'empty', content: ''})
                }
                for(var i = 0; i<days;i++){
                    const date = this.getDateFormatted(i+1)
                    cells.push({type: 'day', content: (i+1).toString(), date: date})
                }
                for(var i = 0; i<offsetEnd; i++){
                    cells.push({type: 'empty', content: ''})
                }
                return cells
            }
        },
        methods: {
            yearRight(){
                this.year += 1

            },
            yearLeft(){
                this.year -= 1
            },
            monthLeft(){
                var month = this.month - 1
                var year = this.year
                if(month < 0){
                    month = 11
                    year = year - 1
                }

                this.month = month
                this.year = year
            },
            monthRight(){
                var month = this.month + 1
                var year = this.year

                if(month > 11){
                    month = 0
                    year = year + 1
                }
                this.month = month
                this.year = year
            },
            daysInMonth(month, year) {
                return new Date(year, (month+1), 0, 13).getDate()
            },
            getDateFormatted(day){
                let month = this.month + 1
                month = (month<10?'0':'') + month.toString()

                day = (day<10?'0':'') + day.toString()

                return day + '/' + month + '/' + this.year
            },
            selectDate(cell){
                if(cell.type == 'day'){
                    this.$emit('input', cell.date)
                }
            },
            cellClasses(cell){
                let classes = []
                if(cell.type == 'day'){
                    classes.push('day')
                }

                if(this.value != null && cell.date == this.value){
                    classes.push('selected')
                }
                return classes.join(' ')
            }
        }
    }
</script>

<style lang="scss">
    .calendar{

        overflow: hidden;

        .datepicker-header{
            background-color: #B0413E;
            height: 50px;
            line-height: 50px;
            display: flex;
            flex-direction: row;

            .content{
                color: #FFFFFF;
                font-size: 18px;
                flex: 1;
                padding: 0px 20px;
                text-align: center;
                white-space: nowrap;
            }

            .arrow-left, .arrow-right, .year-left, .year-right{
                height: 100%;
                text-align: center;
                cursor: pointer;
                font-size: 24px;
                flex: 1;
                max-width: 40px;

                &:hover{
                    background-color: rgba(0,0,0,0.1);
                }
            }

            .month{
                float: left;
                width: 50%;
                height: 100%;
                padding: 0px 5px;
            }

            .year{
                float: left;
                width: 30%;
                height: 100%;
                padding: 0px 5px;
            }

            .arrow-left:before{
                content: 'D';
                font-family: 'Glyphter';
                color: #FFFFFF;
            }

            .arrow-right:before{
                content: 'A';
                font-family: 'Glyphter';
                color: #FFFFFF;
            }

            .year-left:before{
                content: 'V';
                font-family: 'Glyphter';
                color: #FFFFFF;
            }

            .year-right:before{
                content: 'W';
                font-family: 'Glyphter';
                color: #FFFFFF;
            }
        }

        .week{
            display: flex;
            flex-direction: row;
            border-bottom: 1px solid #EEEEEE;
            & > div{
                width: 14.28%;
                line-height: 30px;
                background-color: #DDDDDD;
                font-weight: bold;
                text-align: center;
            }

        }

        .body{
            background-color: #FFFFFF;
            border-right: 1px solid #EEEEEE;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            & > div{
                width: 14.28%;
                text-align: center;
                height: 50px;
                line-height: 50px;
                border-left: 1px solid #EEEEEE;
                border-bottom: 1px solid #EEEEEE;
                background-color: #FAFAFA;
                flex: 0 0 auto;

                &.day{
                    background-color: #FFFFFF;
                    cursor: pointer;

                    &.selected, &:hover{
                        background-color: #B0413E;
                        color: #FFFFFF;
                    }
                }
            }
        }
    }
</style>