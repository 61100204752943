var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "files" },
    [
      _c("div", { staticClass: "buttons" }, [
        _c(
          "div",
          { staticStyle: { float: "right" } },
          [
            !_vm.isAdviser
              ? _c("button", {
                  staticClass: "btn btn-upload",
                  attrs: { type: "button" },
                  on: { click: _vm.addFile }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.type == "invoice" || _vm.type == "invoiceout"
              ? _c(
                  "button-dropdown",
                  { attrs: { text: "", classes: "btn btn-download" } },
                  [
                    _c("ul", { attrs: { slot: "menu" }, slot: "menu" }, [
                      _c("li", [
                        _c(
                          "span",
                          {
                            on: {
                              click: function($event) {
                                return _vm.downloadFiles("all")
                              }
                            }
                          },
                          [_vm._v("Todas")]
                        )
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c(
                          "span",
                          {
                            on: {
                              click: function($event) {
                                return _vm.downloadFiles("pending")
                              }
                            }
                          },
                          [_vm._v("Nuevas")]
                        )
                      ])
                    ])
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.type == "payroll" || _vm.type == "pending"
              ? _c("button", {
                  staticClass: "btn btn-download",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.downloadFiles("all")
                    }
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.isAdviser
              ? _c("button", {
                  staticClass: "btn btn-claim",
                  attrs: { type: "button" },
                  on: { click: _vm.showModalClaim }
                })
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticStyle: { float: "left" } },
          [_c("file-search", { on: { search: _vm.search } })],
          1
        )
      ]),
      _vm._v(" "),
      _c("input", {
        ref: "input",
        staticStyle: { display: "none" },
        attrs: { type: "file", multiple: "multiple" },
        on: { change: _vm.change }
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "ajax-content",
          on: {
            dragover: function($event) {
              $event.preventDefault()
            },
            drop: _vm.drop
          }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.loading,
                  expression: "!loading"
                }
              ],
              ref: "grid",
              staticClass: "flex-native"
            },
            _vm._l(_vm.filteredFiles, function(file) {
              return _c(
                "div",
                { key: file.id, staticClass: "span12-sm span6-md span4-lg" },
                [
                  _c("file", {
                    key: file.id,
                    attrs: { file: file },
                    on: {
                      delete: _vm.showModalDelete,
                      edit: _vm.showModalEdit,
                      download: _vm.downloadFile
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.showViewFile(file)
                      }
                    }
                  })
                ],
                1
              )
            }),
            0
          ),
          _vm._v(" "),
          _c("loading", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading,
                expression: "loading"
              }
            ]
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showAlert,
                  expression: "showAlert"
                }
              ],
              staticClass: "alert alert-warning"
            },
            [
              _vm._v(
                "\n            No hay ninguna factura con las condiciones indicadas.\n        "
              )
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.modals.delete
        ? _c("delete-file-modal", {
            attrs: { file: _vm.fileSelected },
            on: {
              close: function($event) {
                return _vm.closeModal("delete", $event)
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.modals.claim
        ? _c("claim-file-modal", {
            attrs: { client: _vm.clientSelected },
            on: {
              close: function($event) {
                return _vm.closeModal("claim", $event)
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.modals.edit
        ? _c("edit-file-modal", {
            attrs: { file: _vm.fileSelected },
            on: {
              close: function($event) {
                return _vm.closeModal("edit", $event)
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.previewVisible
        ? _c("div", { staticClass: "preview-container" }, [
            _c("div", { staticClass: "preview-window" }, [
              _c("div", { staticClass: "preview-header" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.fileSelected.name) +
                    "\n                "
                ),
                _c("div", {
                  staticClass: "close",
                  on: { click: _vm.hideViewFile }
                })
              ]),
              _vm._v(" "),
              _c("iframe", { attrs: { src: _vm.viewUrl } })
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }