<template>
    <div class="container">
		<h1 class="title">Tus clientes</h1>
		<div class="content">
			<loading v-show="loading"></loading>
            <div class="box-client-documents" v-for="client in clients" :key="client.id" v-show="!loading">
		        <button type="button" v-if="client.documents > 0" v-on:click="download(client)" class="btn btn-primary btn-download">Descargar nuevas</button>
		        <router-link :to="{name: 'client_view', params: {clientId: client.id}}" class="title">{{ client.name }}</router-link>
		        <div v-if="client.documents > 0">{{ client.documents }} facturas nuevas</div>
	        </div>
            <div class="alert alert-warning" v-if="clients.length == 0 && !loading">
	            <div>No tienes aún ningún cliente creado.</div>
	            <div><router-link :to="{name: 'client_new'}" class="btn btn-primary">Crear cliente</router-link></div>
            </div>
		</div>
    </div>
</template>

<script>
export default{
    data(){
        return {
            loading: true,
            timer: null,
            clients: []
        }
    },
    created(){
        this.refresh()
    },
    beforeDestroy(){
        clearTimeout(this.timer)
    },
    methods: {
        refresh(){
            Api.getAdviserHome().then((response) => {
                if(response.data.success == true){
                    this.clients = response.data.clients
                    this.timer = setTimeout(this.refresh, 3000)
                    this.loading = false
                }
            }).catch((error) => {

            })
        },
        download(client){
            var url = '/api/document/download/batch?status=pending&clientId=' + client.id
			location.href = url
        }
    }
}
</script>