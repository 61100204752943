<template>
    <div class="container">
		<div class="flex-native">
			<div class="span12-xs span3-md">
				<div class="box box-sidemenu">
					<div class="header">Opciones</div>
					<ul>
						<li>
							<a href="#" v-on:click="change('profile')" :class="{'selected': section == 'profile'}">Editar mi perfil</a>
						</li>
						<li>
							<a href="#" v-on:click="change('password')" :class="{'selected': section == 'password'}">Cambiar contraseña</a>
						</li>
                        <li v-if="isAllowed('users')">
                            <a href="#" v-on:click="change('users')" :class="{'selected': section == 'users'}">Usuarios</a>
                        </li>
                        <li>
                            <a href="#" v-on:click="change('taxes')" :class="{'selected': section == 'taxes'}">Facturas</a>
                        </li>
					</ul>
				</div>
			</div>
			<div class="span12-xs span9-md">
				<div class="box box-buttons" v-show="section == 'profile'">
                    <div class="header">
                        <h1>Editar mi perfil</h1>
                    </div>
                    <form v-on:submit.prevent="submitProfile">
                        <div class="content">
                             <div class="alert alert-danger" v-if="errorsProfile.length > 0">
                                <div v-for="error in errorsProfile">{{ error }}</div>
                            </div>
                            <div class="flex-native">
                                <div class="span12-xs span4-sm">
                                    <image-input v-model="formProfile.avatar" :placeholder="currentAvatar" prefix="avatar"></image-input>
                                </div>
                                <div class="span12-xs span8-sm">
                                    <text-input label="Nombre" v-model="formProfile.name" :error="errorsProfile.name"></text-input>
                                    <text-input label="Email" v-model="formProfile.email" :error="errorsProfile.email"></text-input>
                                </div>
                            </div>
                        </div>
                        <div class="footer">
                            <button type="submit" class="btn btn-primary">Guardar</button>
                        </div>
                    </form>
                </div>
                <div class="box box-buttons" v-show="section == 'password'">
                    <div class="header">
                        <h1>Cambiar contraseña</h1>
                    </div>
		            <form v-on:submit.prevent="submitPassword">
                        <div class="content">
                            <div class="alert alert-danger" v-if="errorsPassword.first != null">
                                {{ errorsPassword.first }}
                            </div>
                            <password-input label="Contraseña" v-model="formPassword.first"></password-input>
                            <password-input label="Repetir contraseña" v-model="formPassword.second"></password-input>
                        </div>
                        <div class="footer">
                            <button type="submit" class="btn btn-primary">Guardar</button>
                        </div>
		            </form>
	            </div>
                <div class="box box-buttons" v-show="section == 'users'">
                    <div class="header">
                        <h1>Usuarios</h1>
                        <div class="buttons-container">
                            <button type="button" class="btn btn-primary" v-on:click="showModal('user', null)">Añadir usuario</button>
                        </div>
                    </div>
                    <div class="content">
                        <template v-for="user in users" v-if="users.length > 0 && !loading">
                            <div class="client-user" :key="user.id">
                                <div class="name">{{ user.name }}</div>
                                <div class="email">{{ user.email }}</div>
                                <div class="buttons">
                                    <div class="minibtn minibtn-edit" v-on:click="showModal('user', user)"></div>
                                    <div class="minibtn minibtn-delete" v-on:click="showModal('deleteUser', user)"></div>
                                </div>
                            </div>
                        </template>
                        <div class="alert alert-warning" v-if="users.length == 0 && !loading">
                            Este cliente no tiene usuarios creados por lo que no puede acceder al sistema. Crea un usuario utilizando el botón de añadir usuario.
                        </div>
                        <loading v-if="loading"></loading>
                    </div>
                </div>
                <div class="box box-buttons" v-show="section == 'taxes'">
                    <form v-on:submit="submitInvoices">
                        <div class="header">
                            <h1>Facturas</h1>
                        </div>
                        <div class="content">
                            <div class="flex-native">
                                <div class="span12-xs span4-sm">
                                    <h2 class="title">Logo</h2>
                                    <image-input v-model="formInvoices.logo" :placeholder="currentLogo" prefix="logo"></image-input>
                                </div>
                                <div class="span12-xs span8-sm">
                                    <h2 class="title">Nota al pie de la factura</h2>
                                    <textarea-input v-model="formInvoices.invoiceNote"></textarea-input>
                                </div>
                                <div class="span12-xs" style="margin-top: 20px;">
                                    <h2 class="title">Impuestos</h2>
                                    <div class="buttons-container">
                                        <button type="button" class="btn btn-primary" v-on:click="showModal('tax', null)">Añadir impuesto</button>
                                    </div>
                                    <div class="flex-native" v-if="taxes.length > 0 && !loading">
                                        <div class="span12-xs span6-sm" v-for="tax of taxes">
                                            <div class="tax-row">
                                                {{ tax.name }} {{ tax.percentage }}%
                                                <div class="buttons">
                                                    <div class="minibtn minibtn-edit" v-on:click="showModal('tax', tax)"></div>
                                                    <div class="minibtn minibtn-delete" v-on:click="showModal('deleteTax', tax)"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="alert alert-warning" v-if="taxes.length == 0 && !loading">
                                        No tienes los impuestos configurados para tus facturas.
                                    </div>
                                    <loading v-if="loading"></loading>
                                </div>

                            </div>
                        </div>
                        <div class="footer">
                            <button type="submit" class="btn btn-primary">Guardar</button>
                        </div>
                    </form>
                </div>
            </div>
		</div>
        <edit-user-modal v-if="modals.user" :user="this.userSelected" :client="client" v-on:close="closeModal('user', $event)"></edit-user-modal>
        <edit-tax-modal v-if="modals.tax" :tax="this.taxSelected" v-on:close="closeModal('tax', $event)"></edit-tax-modal>
        <confirmation-modal v-if="modals.deleteUser" v-on:submit="deleteUser" v-on:close="closeModal('deleteUser')" title="Eliminar usuario" text="¿Estás seguro de que quieres eliminar el usuario?"></confirmation-modal>
        <confirmation-modal v-if="modals.deleteTax" v-on:submit="deleteTax" v-on:close="closeModal('deleteTax')" title="Eliminar impuesto" text="¿Estás seguro de que quieres eliminar este impuesto?"></confirmation-modal>
    </div>
</template>

<script>

    import EditUserModal from '@/components/EditUserModal'
    import ConfirmationModal from '@/components/ConfirmationModal'
    import EditTaxModal from '@/components/EditTaxModal'
    import ImageInput from '@/components/Forms/ImageInput'

    export default {
        components: {EditTaxModal, ConfirmationModal, EditUserModal, ImageInput},
        data() {
            return {
                section: 'profile',
                formProfile: {
                    name: '',
                    email: '',
                    avatar: ''
                },
                formPassword: {
                    first: '',
                    second: ''
                },
                formInvoices: {
                    logo: '',
                    invoiceNote: ''
                },
                taxSelected: null,
                userSelected: null,
                errorsProfile: {
                    name: null,
                    email: null
                },
                errorsPassword: {
                    first: null,
                    second: null
                },
                users: [],
                taxes: [],
                loading: false,
                modals: {
                    tax: false,
                    user: false,
                    deleteTax: false,
                    deleteUser: false,
                }
            }
        },
        created(){
            this.formProfile.name = this.$store.state.user.name
            this.formProfile.email = this.$store.state.user.email
            this.formInvoices.invoiceNote = this.$store.state.user.invoiceNote
        },
        computed: {
            // This is a trick in order to EditUserModal knows which client has to create users
            client(){
                return {id: this.$store.state.user.clientId}
            },
            role(){
                return this.$store.state.user.role
            },
            currentLogo(){
                const logoUrl = this.$store.state.user.logoUrl
                if(logoUrl == null || logoUrl == ''){
                    return '/images/logo-placeholder.png'
                }
                return logoUrl
            },
            currentAvatar(){
                const avatarUrl = this.$store.state.user.avatarUrl
                if(avatarUrl == null || avatarUrl == ''){
                    return '/images/user.jpg'
                }
                return avatarUrl
            }
        },
        methods: {
            isAllowed(permission){
                return this.$store.getters.isAllowed(permission)
            },
            submitProfile(){
                Api.updateMyProfile(this.formProfile).then((response) => {
                    if(response.data.success){
                        Api.me().then((response) => {
                            this.$store.commit('setUser', response.data)
                            this.$snotify.success('Datos guardados correctamente')
                            this.errorsProfile = []
                        }).catch((error) => {
                            console.error(error)
                        })
                    }else{
                        this.errorsProfile = response.data.errors
                    }
                }).catch((error) => {
                    console.error(error)
                })
            },
            submitPassword(){
                Api.updateMyPassword(this.formPassword).then((response) => {
                     if(response.data.success){
                        this.$snotify.success('Datos guardados correctamente')
                        this.errorsPassword = null
                    }else{
                        this.errorsPassword = response.data.errors.password
                    }
                }).catch((error) => {
                    console.error(error)
                })
            },
            change(section){
                this.section = section
                if(section == 'users'){
                    this.loadUsers()
                }

                if(section == 'taxes'){
                    this.loadTaxes()
                }
            },
            closeModal(modal, refresh = false){
                this.modals[modal] = false

                if(refresh && modal == 'tax'){
                    this.loadTaxes()
                }

                if(refresh && modal == 'user'){
                    this.loadUsers()
                }
            },
            showModal(modal, object){
                if(modal == 'user' || modal == 'deleteUser'){
                    this.userSelected = object
                }

                if(modal == 'tax' || modal == 'deleteTax'){
                    this.taxSelected = object
                }
                this.modals[modal] = true
            },
            loadUsers(){
                this.loading = true
                const clientId = this.$store.state.user.clientId
                Api.listClientUser(clientId).then((response) => {
                    if(response.data.success){
                        this.users = response.data.users
                        this.loading = false
                    }
                })
            },
            loadTaxes(){
                this.loading = true

                Api.listTax().then((response) => {
                    if(response.data.success){
                        this.taxes = response.data.taxes
                        this.loading = false
                    }
                }).catch((error) => {
                    console.error(error)
                })
            },
            deleteUser(){
                Api.deleteClientUser(this.userSelected.id).then((response) => {
                    if(response.data.success){
                        this.modals.deleteUser = false
                        this.loadUsers()
                    }
                })
            },
            deleteTax(){
                Api.deleteTax(this.taxSelected.id).then((response) => {
                    if(response.data.success){
                        this.modals.deleteTax = false
                        this.loadTaxes()
                    }
                })
            },
            submitInvoices(e){
                e.preventDefault()
                Api.setClientInvoices(this.formInvoices).then((response) => {
                    if(response.data.success) {
                        Api.me().then((response) => {
                            this.$snotify.success('Hemos guardado tus datos correctamente')
                        })
                    }
                }).catch((error) => {
                    this.$snotify.error(error)
                })
            }
        },
    }
</script>
