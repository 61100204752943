var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "client" }, [
    _c("div", { staticClass: "header" }, [
      _c("div", { staticClass: "name", on: { click: _vm.clickView } }, [
        _vm._v(_vm._s(_vm.client.name))
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "buttons" }, [
        _c("div", {
          staticClass: "minibtn minibtn-edit",
          on: { click: _vm.clickEdit }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "info" }, [
      _c("div", { staticClass: "line" }, [
        _c("span", [_vm._v("Dirección")]),
        _vm._v(" " + _vm._s(_vm.client.address)),
        _c("br"),
        _vm._v(
          _vm._s(_vm.client.zip) +
            " " +
            _vm._s(_vm.client.city) +
            " (" +
            _vm._s(_vm.client.state) +
            ")"
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "line" }, [
        _c("span", [_vm._v("Teléfono")]),
        _vm._v(" " + _vm._s(_vm.client.phone))
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "line" }, [
        _c("span", [_vm._v("CIF")]),
        _vm._v(" " + _vm._s(_vm.client.vat))
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }