<template>
    <div class="container">
        <file-browser :client="clientSelected" v-if="clientSelected != null"></file-browser>
    </div>
</template>

<script>
import FileBrowser from '@/components/FileBrowser'

export default{
    components: {FileBrowser},
    created(){
        this.loadClient()
    },
    computed: {
        clientSelected(){
            return this.$store.state.clientSelected
        }
    },
    methods: {
        loadClient(){
            const clientId = this.$route.params.clientId
            Api.getClient(clientId).then((response) => {
                if(response.data.success){
                    this.$store.commit('setClientSelected', response.data.client)
                }else{
                    console.log(response.data.message)
                }
            })
        }
    },
    watch: {
        '$route.params.clientId': function(){
            this.loadClient()
        }
    }
};
</script>