var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("modal", [
    _c("form", { on: { submit: _vm.submit } }, [
      _c("div", { staticClass: "modal-header" }, [
        _c("h4", { staticClass: "modal-title" }, [
          _vm._v(_vm._s(_vm.modalTitle))
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "modal-body" }, [
        _c("div", { staticClass: "flex-native" }, [
          _c(
            "div",
            { staticClass: "span12-xs span8-md" },
            [
              _c("text-input", {
                attrs: { label: "Nombre", error: _vm.errors.name },
                model: {
                  value: _vm.form.name,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "span12-xs span4-md" },
            [
              _c("text-input", {
                attrs: { label: "NIF/CIF", error: _vm.errors.vat },
                model: {
                  value: _vm.form.vat,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "vat", $$v)
                  },
                  expression: "form.vat"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "span12-xs" },
            [
              _c("text-input", {
                attrs: { label: "Dirección", error: _vm.errors.address },
                model: {
                  value: _vm.form.address,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "address", $$v)
                  },
                  expression: "form.address"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "span12-xs span4-md" },
            [
              _c("text-input", {
                attrs: { label: "Código postal", error: _vm.errors.zip },
                model: {
                  value: _vm.form.zip,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "zip", $$v)
                  },
                  expression: "form.zip"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "span12-xs span4-md" },
            [
              _c("text-input", {
                attrs: { label: "Ciudad", error: _vm.errors.city },
                model: {
                  value: _vm.form.city,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "city", $$v)
                  },
                  expression: "form.city"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "span12-xs span4-md" },
            [
              _c("text-input", {
                attrs: { label: "Provincia", error: _vm.errors.state },
                model: {
                  value: _vm.form.state,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "state", $$v)
                  },
                  expression: "form.state"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "span12-xs span8-md" },
            [
              _c("text-input", {
                attrs: {
                  label: "Email para facturas",
                  error: _vm.errors.email
                },
                model: {
                  value: _vm.form.email,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "email", $$v)
                  },
                  expression: "form.email"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "span12-xs span4-md" },
            [
              _c("text-input", {
                attrs: { label: "Teléfono", error: _vm.errors.phone },
                model: {
                  value: _vm.form.phone,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "phone", $$v)
                  },
                  expression: "form.phone"
                }
              })
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "modal-footer" }, [
        _c(
          "button",
          {
            staticClass: "btn",
            attrs: { type: "button" },
            on: {
              click: function($event) {
                return _vm.closeModal("data", false)
              }
            }
          },
          [_vm._v("Cancelar")]
        ),
        _vm._v(" "),
        _c(
          "button",
          { staticClass: "btn btn-primary", attrs: { type: "submit" } },
          [_vm._v("Guardar")]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }