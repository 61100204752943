var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container" }, [
      _c("h1", { staticClass: "title" }, [_vm._v("Términos legales")]),
      _vm._v(" "),
      _c("div", { staticClass: "box" }, [
        _vm._v("\n        Aquí va el texto de los términos legales\n    ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }