<template>
    <div class="container">
        <file-browser></file-browser>
    </div>
</template>

<script>
import FileBrowser from '@/components/FileBrowser'

export default{
    components: {FileBrowser}
}
</script>