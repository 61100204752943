var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("h1", { staticClass: "title" }, [_vm._v("Buzón de faturas")]),
    _vm._v(" "),
    _c("div", { staticClass: "box box-inbox" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "address" }, [
        _vm._v("\n\t\t\t" + _vm._s(_vm.address) + "\n\t\t\t")
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "alert alert-warning" }, [
      _vm._v(
        "\n\t\t\t\tCualquier email que envíes a esta dirección con facturas como archivos adjuntos, se importarán automáticamente en tu cuenta y estarán disponibles para tu asesor.\n\t\t\t\t"
      ),
      _c("br"),
      _vm._v(
        "Recuerda que también puedes crear un alias de correo con el dominio de tu empresa y redirigirlo a esta dirección y te será más fácil de recordar.\n\t\t\t"
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }