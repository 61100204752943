var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("footer", [
    _c(
      "div",
      { staticClass: "links" },
      [
        _c("router-link", { attrs: { to: { name: "whoweare" } } }, [
          _vm._v("Quiénes somos")
        ]),
        _vm._v(" "),
        _c("router-link", { attrs: { to: { name: "legal" } } }, [
          _vm._v("Términos legales")
        ]),
        _vm._v(" "),
        _c("router-link", { attrs: { to: { name: "privacy" } } }, [
          _vm._v("Política de privacidad")
        ])
      ],
      1
    ),
    _vm._v(" "),
    _vm._m(0)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "love" }, [
      _vm._v("\n        Made with "),
      _c("img", { attrs: { src: "/images/love.png" } }),
      _vm._v(" in Madrid\n    ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }