<template>
    <div class="container container-md">
        <h1 class="title">Crea tu cuenta <span>¡gratis!</span></h1>
        <div class="alert alert-success" v-if="result == 'success'">
            Te hemos enviado un email para confirmar tu cuenta. Haz click en el botón que aparece en el email y entrarás directamente.
        </div>
        <div class="alert alert-danger" v-if="result == 'error'">
            Se ha producido un error en el registro
        </div>
        <form v-on:submit="submit" v-if="result == null">
            <div class="box box-buttons">
                <div class="content">
                    <text-input label="Nombre de la asesoría" v-model="form.client.name"></text-input>
                    <!--<switch-input label="¿Es una asesoría?" v-model="form.client.isAdviser"></switch-input>-->
                    <text-input label="Tu nombre" v-model="form.name"></text-input>
                    <text-input label="Tu email" v-model="form.email"></text-input>
                    <text-input label="Contraseña" type="password" v-model="form.password.first"></text-input>
                    <text-input label="Repetir contraseña" type="password" v-model="form.password.second"></text-input>
                </div>
                <div class="footer">
                    <button type="submit" class="btn btn-primary btn-block">Aceptar</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
export default{
    data(){
        return {
            result: null,
            form: {
                client: {name: '', isAdviser: true},
                email: '',
                name: '',
                password: {first: '', second: ''}
            }
        }
    },
    methods: {
        submit(e){
            e.preventDefault()

            Api.businessRegistration(this.form).then((response) => {
                if(response.data.success){
                    this.result = 'success'
                }else{
                    this.result = 'error'
                }
            }).catch((error) => {
                this.result = 'error'
                console.log(error)
            })
        }
    }
}
</script>