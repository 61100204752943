<template>
<div class="container container-lg">
	<form v-on:submit.prevent="submit">
		<div class="box box-buttons">
			<div class="header">
				<h1>Añadir cliente</h1>
			</div>
			<div class="content">
				<div class="flex-native">
					<div class="span12-xs span8-md">
						<text-input label="Nombre" v-model="form.client.name" :error="errors.client.name"></text-input>
					</div>
					<div class="span12-xs span4-md">
						<text-input label="NIF/CIF" v-model="form.client.vat" :error="errors.client.vat"></text-input>
					</div>
					<div class="span12-xs">
						<text-input label="Dirección" v-model="form.client.address" :error="errors.client.address"></text-input>
					</div>
					<div class="span12-xs span4-md">
						<text-input label="Código postal" v-model="form.client.zip" :error="errors.client.zip"></text-input>
					</div>
					<div class="span12-xs span4-md">
						<text-input label="Ciudad" v-model="form.client.city" :error="errors.client.city"></text-input>
					</div>
					<div class="span12-xs span4-md">
						<text-input label="Provincia" v-model="form.client.state" :error="errors.client.state"></text-input>
					</div>
					<div class="span12-xs span8-md">
						<text-input label="Email para facturas" v-model="form.client.email" :error="errors.client.email"></text-input>
					</div>
					<div class="span12-xs span4-md">
						<text-input label="Teléfono" v-model="form.client.phone" :error="errors.client.phone"></text-input>
					</div>
				</div>

				<h2 class="title">Datos de acceso</h2>

				<div class="flex-native">
					<div class="span12-xs span6-md">
                        <text-input label="Nombre" v-model="form.user.name" :error="errors.user.name"></text-input>
					</div>
					<div class="span12-xs span6-md">
                        <text-input label="E-mail" v-model="form.user.email" :error="errors.user.email"></text-input>
					</div>
				</div>
			</div>
			<div class="footer">
				<button type="submit" class="btn btn-primary">Guardar</button>
			</div>
		</div>
	</form>
	</div>
</template>

<script>

export default{
    data(){
        return {
            form: {
                client: {
                    name: '',
                    address: '',
                    zip: '',
                    city: '',
                    state: '',
                    phone: '',
                    vat: '',
                },
                user: {
                    name: '',
                    email: ''
                }
            },
			errors: {
                client: {
                    name: null,
					address: null,
					zip: null,
					city: null,
					state: null,
					phone: null,
					vat: null
				},
				user: {
                    name: null,
					email: null
				}
			}
        }
    },
    methods: {
        submit(){
            Api.createClientWithUser(this.form).then((response) => {
                if(response.data.success){
                    this.$router.push({name: 'client_manage'})
                }else{
                    this.errors = response.data.errors
				}
            }).catch((error) => {
                
            })
        }
    }
}
</script>