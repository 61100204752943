var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("h1", { staticClass: "title" }, [_vm._v("Gestión de clientes")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "buttons-container" },
        [
          _c(
            "router-link",
            {
              staticClass: "btn btn-primary",
              attrs: { to: { name: "client_new" } }
            },
            [_vm._v("Añadir cliente")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("loading", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loading,
            expression: "loading"
          }
        ]
      }),
      _vm._v(" "),
      _vm.clients.length > 0 && !_vm.loading
        ? _c(
            "div",
            { staticClass: "flex-native" },
            _vm._l(_vm.clients, function(client) {
              return _c(
                "div",
                { key: client.id, staticClass: "span12-xs span6-md" },
                [
                  _c("client", {
                    attrs: { client: client },
                    on: {
                      clickEdit: _vm.clickEdit,
                      clickView: _vm.clickView,
                      clickDelete: function($event) {
                        return _vm.showDeleteClientModal(client)
                      }
                    }
                  })
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _c("pagination", {
        attrs: { show: 7, total: _vm.pages, current: _vm.currentPage },
        on: { change: _vm.goToPage }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }