var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container container-lg" }, [
    _c(
      "form",
      {
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.submit($event)
          }
        }
      },
      [
        _c("div", { staticClass: "box box-buttons" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "flex-native" }, [
              _c(
                "div",
                { staticClass: "span12-xs span8-md" },
                [
                  _c("text-input", {
                    attrs: { label: "Nombre", error: _vm.errors.client.name },
                    model: {
                      value: _vm.form.client.name,
                      callback: function($$v) {
                        _vm.$set(_vm.form.client, "name", $$v)
                      },
                      expression: "form.client.name"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "span12-xs span4-md" },
                [
                  _c("text-input", {
                    attrs: { label: "NIF/CIF", error: _vm.errors.client.vat },
                    model: {
                      value: _vm.form.client.vat,
                      callback: function($$v) {
                        _vm.$set(_vm.form.client, "vat", $$v)
                      },
                      expression: "form.client.vat"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "span12-xs" },
                [
                  _c("text-input", {
                    attrs: {
                      label: "Dirección",
                      error: _vm.errors.client.address
                    },
                    model: {
                      value: _vm.form.client.address,
                      callback: function($$v) {
                        _vm.$set(_vm.form.client, "address", $$v)
                      },
                      expression: "form.client.address"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "span12-xs span4-md" },
                [
                  _c("text-input", {
                    attrs: {
                      label: "Código postal",
                      error: _vm.errors.client.zip
                    },
                    model: {
                      value: _vm.form.client.zip,
                      callback: function($$v) {
                        _vm.$set(_vm.form.client, "zip", $$v)
                      },
                      expression: "form.client.zip"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "span12-xs span4-md" },
                [
                  _c("text-input", {
                    attrs: { label: "Ciudad", error: _vm.errors.client.city },
                    model: {
                      value: _vm.form.client.city,
                      callback: function($$v) {
                        _vm.$set(_vm.form.client, "city", $$v)
                      },
                      expression: "form.client.city"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "span12-xs span4-md" },
                [
                  _c("text-input", {
                    attrs: {
                      label: "Provincia",
                      error: _vm.errors.client.state
                    },
                    model: {
                      value: _vm.form.client.state,
                      callback: function($$v) {
                        _vm.$set(_vm.form.client, "state", $$v)
                      },
                      expression: "form.client.state"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "span12-xs span8-md" },
                [
                  _c("text-input", {
                    attrs: {
                      label: "Email para facturas",
                      error: _vm.errors.client.email
                    },
                    model: {
                      value: _vm.form.client.email,
                      callback: function($$v) {
                        _vm.$set(_vm.form.client, "email", $$v)
                      },
                      expression: "form.client.email"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "span12-xs span4-md" },
                [
                  _c("text-input", {
                    attrs: {
                      label: "Teléfono",
                      error: _vm.errors.client.phone
                    },
                    model: {
                      value: _vm.form.client.phone,
                      callback: function($$v) {
                        _vm.$set(_vm.form.client, "phone", $$v)
                      },
                      expression: "form.client.phone"
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("h2", { staticClass: "title" }, [_vm._v("Datos de acceso")]),
            _vm._v(" "),
            _c("div", { staticClass: "flex-native" }, [
              _c(
                "div",
                { staticClass: "span12-xs span6-md" },
                [
                  _c("text-input", {
                    attrs: { label: "Nombre", error: _vm.errors.user.name },
                    model: {
                      value: _vm.form.user.name,
                      callback: function($$v) {
                        _vm.$set(_vm.form.user, "name", $$v)
                      },
                      expression: "form.user.name"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "span12-xs span6-md" },
                [
                  _c("text-input", {
                    attrs: { label: "E-mail", error: _vm.errors.user.email },
                    model: {
                      value: _vm.form.user.email,
                      callback: function($$v) {
                        _vm.$set(_vm.form.user, "email", $$v)
                      },
                      expression: "form.user.email"
                    }
                  })
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _vm._m(1)
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header" }, [
      _c("h1", [_vm._v("Añadir cliente")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "footer" }, [
      _c(
        "button",
        { staticClass: "btn btn-primary", attrs: { type: "submit" } },
        [_vm._v("Guardar")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }