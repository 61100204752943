var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "client-selector" },
    [
      _c(
        "div",
        {
          staticClass: "current current-clickable",
          on: { click: _vm.showSearchModal }
        },
        [
          _c("div", { staticClass: "title" }, [_vm._v("Cliente")]),
          _vm._v(" "),
          _c("div", { staticClass: "name" }, [_vm._v(_vm._s(_vm.clientName))])
        ]
      ),
      _vm._v(" "),
      _vm.modalSearchVisible
        ? _c("modal", { staticClass: "modal-selector" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c("h4", { staticClass: "modal-title" }, [
                _vm._v("Seleccionar cliente")
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body modal-search" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.query,
                    expression: "query"
                  }
                ],
                attrs: { type: "text", placeholder: "Buscar..." },
                domProps: { value: _vm.query },
                on: {
                  keyup: _vm.keypress,
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.query = $event.target.value
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "modal-body client-list" },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.loading && _vm.clients.length == 0,
                        expression: "!loading && clients.length == 0"
                      }
                    ],
                    staticClass: "alert alert-warning"
                  },
                  [
                    _vm._v(
                      "No hay ningún cliente con las condiciones indicadas"
                    )
                  ]
                ),
                _vm._v(" "),
                _vm._l(_vm.clients, function(client) {
                  return _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.loading && _vm.clients.length > 0,
                          expression: "!loading && clients.length > 0"
                        }
                      ],
                      key: client.id,
                      staticClass: "client-row",
                      on: {
                        click: function($event) {
                          return _vm.select(client)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(client.name) +
                          "\n                "
                      ),
                      _c("div", { staticClass: "client-row-buttons" }, [
                        _c("div", {
                          staticClass: "minibtn minibtn-edit",
                          on: {
                            click: function($event) {
                              return _vm.editClient(client, $event)
                            }
                          }
                        })
                      ])
                    ]
                  )
                }),
                _vm._v(" "),
                _c("loading", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.loading,
                      expression: "loading"
                    }
                  ]
                })
              ],
              2
            ),
            _vm._v(" "),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn",
                  attrs: { type: "button" },
                  on: { click: _vm.hideSearchModal }
                },
                [_vm._v("Cerrar")]
              )
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }