<template>
    <div class="form-group" :class="{'has-error': error != null}">
        <label v-if="label != ''">{{ label }}</label>
        <input :type="type" :placeholder="placeholder" :value="value" v-on="listeners" :autocapitalize="autocapitalizeValue">
        <div class="error-desc" v-show="error != null">{{ error }}</div>
    </div>
</template>

<script>
    export default{
        props: {
            label: {type: String, default: '', required: false},
            type: {type: String, default: 'text', required: false},
            placeholder: {type: String, default: '', required: false},
            value: {type: [String, Number], default: null, required: false},
            error: {type: String, default: null, required: false},
            autocapitalize: {type: Boolean, default: true, required: false}
        },
        computed: {
            listeners(){
                return {
                    ...this.$listeners,
                    input: event => this.$emit('input', event.target.value)
                }
            },
            autocapitalizeValue(){
                if(this.autocapitalize){
                    return 'on'
                }else{
                    return 'off'
                }
            }
        }
    };
</script>