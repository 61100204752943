<template>
    <div class="form-group">
        <label v-if="label != ''">{{ label }}</label>
        <input type="password" :value="value" v-on:input="update">
        <div class="error-desc"></div>
    </div>
</template>

<script>
export default{
    props: {
        label: {type: String, default: '', required: false},
        value: {type: String, default: '', required: false}
    },
    methods: {
        update(e) {
            this.$emit('input', e.target.value);
        }
    }
};
</script>