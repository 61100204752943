<template>
    <div class="form-group" :class="{'has-error': error != null}">
        <label v-if="label != null">{{ label }}</label>
        <select v-on:input="update">
            <option value="" v-if="placeholder != null">{{ placeholder }}</option>
            <option v-for="(option, index) in options" :key="index" :value="index" :selected="isSelected(index)">{{ getLabel(option) }}</option>
        </select>
        <div class="error-desc" v-show="error != null">{{ error }}</div>
    </div>
</template>

<script>
export default{
    props: {
        label: {type: String, default: null, required: false},
        value: {type: [String, Object, Number], default: '', required: false},
        placeholder: {type: String, default: null, required: false},
        options: {type: Array, default: [], required: true},
        optionLabel: {type: String, default: 'label', required: false},
        optionValue: {type: String, default: null, required: false},
        error: {type: String, default: null, required: false}
    },
    methods: {
        update(e) {
            const index = parseInt(e.target.value)
            this.$emit('input', this.getValue(index))
        },
        getValue(index){
            let option = this.options[index]
            return this.optionValue == null ? option : option[this.optionValue]
        },
        getLabel(option){
            return option[this.optionLabel]
        },
        isSelected(index){
            if(this.optionValue == null){
                return this.value == this.options[index]
            }else{
                return this.value == this.options[index][this.optionValue]
            }
        }
    }
}
</script>