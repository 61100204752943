<template>
    <div class="invoice-line">
        <div class="delete" v-show="showDelete" v-on:click="onDelete"></div>
        <div class="description">
            <textarea placeholder="Descripción" v-model="value.description"></textarea>
        </div>
        <div class="quantity">
            <input type="text" v-model="value.quantity" placeholder="Cantidad" v-on:input="forceFloat('quantity')">
        </div>
        <div class="price">
            <input type="text" placeholder="Precio" v-model="value.price" v-on:input="forceFloat('price')" v-on:blur="fixDecimal">
        </div>
        <div class="total">
            {{ value.total|amount(2) }} €
        </div>
    </div>
</template>

<script>
    export default{
        props: {
            value: {type: Object, default: null, required: false},
            showDelete: {type: Boolean, default: false, required: false}
        },
        methods: {
            forceFloat(field){
                this.value[field] = this.value[field].replace(/[^\-0-9.]/, '')
                this.$emit('recalculate')
            },
            fixDecimal(){
                this.value.price = Number.parseFloat(this.value.price).toFixed(2)
            },
            onDelete(){
                this.$emit('delete')
            }
        }
    }
</script>