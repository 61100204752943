<template lang="pug">
    div(class="container")
        h1(class="title") Facturas emitidas
        div(class="buttons-container")
            router-link(class="btn btn-primary" :to="{name: 'invoice_new'}") Crear factura
        loading(v-show="loading")
        div(class="grid-responsive")
          div(class="grid-invoices" v-show="!loading && invoices.length > 0")
              .number.header #
              .name.header Nombre
              .date.header Fecha
              .subtotal.header Subtotal
              .total.header Total
              .pending.header Pendiente
              .buttons.header Opciones
              template(v-for="invoice of invoices")
                  .number {{ invoice.series }}{{ invoice.number }}
                  .name {{ invoice.name }}
                  .date {{ invoice.date|format('DD/MM/YYYY') }}
                  .subtotal {{ invoice.subtotal|amount(2) }} €
                  .total {{ invoice.total|amount(2) }} €
                  .pending {{ invoice.pending|amount(2) }} €
                  .buttons
                      span(class="link" v-on:click="preview(invoice)") Previsualizar
                      span(class="link" v-on:click="edit(invoice)") Editar
                      span(class="link" v-on:click="openModal('issue', invoice)") Emitir
                      span(class="link" v-on:click="openModal('send', invoice)") Enviar
        Pagination(:show="7" :total="pages" :current="currentPage" v-on:change="goToPage")

        .alert.alert-warning(v-show="!loading && invoices.length == 0")
            div No tienes ninguna factura creada todavía.
                router-link(:to="{name: 'invoice_new'}") Crea una ahora
        ConfirmationModal(title="Emitir factura" v-if="modals.issue" v-on:submit="issueInvoice" v-on:close="closeModal('issue')")
            template(v-slot:body)
                p ¿Estás seguro de que quieres emitir esta factura?
                .alert.alert-warning Una vez que la emitas, se generará el documento pdf y no podrá ser modificada
        SendModal(v-if="modals.send" :invoice="invoiceSelected" v-on:submit="sendInvoice" v-on:close="closeModal('send')")
</template>

<script>
    import moment from 'moment'
    import ConfirmationModal from '@/components/ConfirmationModal'
    import SendModal from '@/components/Invoice/SendModal'
    import Pagination from '@/components/Pagination'

    export default{
        components: {ConfirmationModal, SendModal, Pagination},
        data(){
            return {
                invoices: [],
                invoiceSelected: null,
                pages: 0,
                loading: false,
                modals: {
                    issue: false,
                    send: false
                }
            }
        },
        computed: {
            currentPage(){
                if(this.$route.query.page == null){
                    return 1
                }

                return parseInt(this.$route.query.page)
            }
        },
        created(){
            this.load()
        },
        methods: {
            load(){
                this.loading = true
                const params = {page: this.currentPage}
                Api.listInvoices(params).then((response) => {
                    if(response.data.success){
                        this.invoices = response.data.invoices
                        this.pages = response.data.pages
                        this.loading = false
                    }
                }).catch((error) => {
                    console.log(error)
                })
            },
            goToPage(page){
                this.$router.push({name: 'invoice_manage', query: {page: page}})
            },
            openModal(modal, invoice){
                this.invoiceSelected = invoice
                this.modals[modal] = true
            },
            closeModal(modal){
                this.modals[modal] = false
            },
            preview(invoice){
                location.href = '/api/invoice/preview/' + invoice.id
            },
            sendInvoice(data){
                Api.sendInvoice(this.invoiceSelected.id, data).then((response) => {
                  if(response.data.success){
                    this.load()
                    this.modals.send = false
                    this.$snotify.success('Se ha enviado la factura correctamente')
                  }
                }).catch((error) => {
                    this.$snotify.danger(error)
                })
            },
            issueInvoice(){
                Api.issueInvoice(this.invoiceSelected.id).then((response) => {
                    if(response.data.success){
                        this.load()
                        this.modals.issue = false
                        this.$snotify.success('Se ha emitido la factura correctamente')
                    }
                }).catch((error) => {
                    this.$snotify.danger(error)
                })
            },
            edit(invoice){
                this.$router.push({name: 'invoice_edit', params: {id: invoice.id}})
            }
        },
        watch: {
            '$route': function(){
                this.load()
            }
        }
    }
</script>

<style lang="scss">
  .grid-responsive{
    overflow-x: scroll;
  }
  
  .grid-invoices{
    display: grid;
    grid-template-columns: 60px auto repeat(5, 1fr);
    background-color: #FFFFFF;
    border-radius: 5px;
    overflow: hidden;
    padding-bottom: 20px;
    margin-bottom: 20px;
    box-shadow: 0 0 30px #e1e7eb;

    .header{
      background-color: #344c67;
      color: #FFFFFF;
    }

    .buttons{
      text-align: right;
      span{
        margin-left: 10px;
      }
    }

    .number, .date, .total, .subtotal, .pending{
      text-align: right;
    }

    & > div {
      border-bottom: 1px solid #DDDDDD;
      line-height: 40px;
      padding: 0px 20px;
    }
  }
</style>