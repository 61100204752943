<template>
    <modal>
        <form v-on:submit="submit">
            <div class="modal-header">
                <h4 class="modal-title">Eliminar</h4>
            </div>
            <div class="modal-body">
                ¿Estás seguro de que quieres eliminarlo?
            </div>
            <div class="modal-footer">
                <button type="button" class="btn" v-on:click="close">Cancelar</button>
                <button type="submit" class="btn btn-primary">Eliminar</button>
            </div>
        </form>
    </modal>
</template>

<script>
    export default{
        props: {
            file: {type: Object, default: null, required: true}
        },
        methods: {
            close(){
                this.$emit('close')
            },
            submit(e){
                e.preventDefault()
                Api.deleteDocument(this.file.id).then((response) => {
                    if(response.data.success){
                        this.$emit('close', true)
                    }
                });
            }
        }
    }
</script>